/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import Moment from "moment";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { format } from "date-fns";
import {
  stripslashes,
  showAlert,
  addressFormat,
} from "../Helpers/SettingHelper";
import {
  appId,
  deliveryId,
  dineinId,
  apiUrl,
  headerconfig,
  cookieConfig,
  apiUrlNotify,
} from "../Helpers/Config";
import tick_img from "../../common/images/like-tnk.png";

import arrow from "../../common/images/name-arrow.svg";
import butterfly from "../../common/images/butterfly.svg";
import Confirmation from "../../common/images/Confirmation.svg";
import scootyImg from "../../common/images/delivery-bike-black.svg";
import cartMpImg from "../../common/images/cart-map.png";

import productImg from "../../common/images/no-img-product.png";

import { GET_ORDER_DETAIL } from "../../actions";
import { object } from "underscore";

var Parser = require("html-react-parser");
class Thankyou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCart: true,
      orderdetail: [],
    };
  }

  componentWillMount() {
    let orderId =
      typeof this.props.match.params.orderId !== "undefined"
        ? this.props.match.params.orderId
        : "";
    if (orderId !== "") {
      this.props.getOrderDetail(orderId);
      this.sendInfrasys();
      //this.sendOrderSMSNotification(orderId);
      this.sendNotification();
    } else {
      showAlert("Error", "Invalid order detail.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.orderdetail !== nextProps.orderdetail) {
      this.setState({ orderdetail: nextProps.orderdetail }, function () {
        var result = nextProps.orderdetail;
      });
    }
  }
  sendNotification() {
    axios.get(apiUrlNotify + "order_notify").then((res) => {});
  }

  sendInfrasys() {
    axios
      .get(
        apiUrl +
          "ordersv1/push_infrasys?app_id=" +
          appId +
          "&order_local_no=" +
          this.props.match.params.orderId
      )
      .then((res) => {});
  }

  /* sendOrderSMSNotification(orderId) {
    axios
      .get(
        apiUrl +
          "ordersv1/send_order_sms?app_id=" +
          appId +
          "&order_number=" +
          orderId,
        headerconfig
      )
      .then((res) => {});
  } */
  loadItems(orderDetail) {
    if (Object.keys(orderDetail).length > 0) {
      var cartItems = orderDetail["items"];
      return Object.keys(cartItems).map((index) => {
        return (
          <div className="tnkyou-product-inner" key={index}>
            {Object.keys(cartItems[index].items).map((index1) => {
              return this.loadItemsList(cartItems[index].items[index1]);
            })}
          </div>
        );
      });
    }
  }

  loadItemsList(orderDetail) {
    if (orderDetail.length > 0) {
      var cartItems = orderDetail;
      return cartItems.map((item, index) => (
        <div className="tnkyou-product-parent" key={index}>
          <div className="tnkyou_row">
            <div className="tnkyou_row-inner">
              <div className="tnkyou_left">
                <div class="cart_img">
                  <img src={productImg} />
                </div>
                <div className="cart_info">
                  <h4>
                    {item.item_qty} x {stripslashes(item.item_name)}
                  </h4>

                  {this.loadModifierItems(
                    "Component",
                    item.modifiers,
                    item.set_menu_component
                  )}

                  {item.item_specification !== "" && (
                    <p className="help-block">
                      {stripslashes(item.item_specification)}
                    </p>
                  )}
                </div>
              </div>

              <div className="tnkyou_right text-right">
                {/* <span className="order-qty"></span> */}
                <div className="cart_price">
                  <p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboVal = this.showComboInCombo(combos[r]["set_menu_component"]);
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
        var comboQtyChk = comboQty !== "" ? parseInt(comboQty) : 0;
        if (comboQtyChk > 0) {
          html +=
            "<p>" +
            comboQty +
            " X " +
            comboPro +
            newPrice +
            " " +
            comboVal +
            " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  showComboInCombo(combo) {
    var html = "";
    var comboLen = combo.length;
    for (var i = 0, length = comboLen; i < length; i++) {
      var comboName = combo[i]["menu_component_name"];
      var comboVal = this.showComboInComboProducts(combo[i]["product_details"]);
      html +=
        "<ul class='cart-combo-in-combo'><li>" +
        comboName +
        ": </li>" +
        comboVal +
        " " +
        "</ul> ";
    }
    return html;
  }

  showComboInComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["menu_inner_product_qty"]) > 0) {
          var comboPro = combos[r]["menu_inner_product_name"];
          var comboQty = combos[r]["menu_inner_product_qty"];
          var comboPrice = combos[r]["menu_inner_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html += "<li>" + comboQty + " X " + comboPro + newPrice + " </li> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b></p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }
  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  openCart() {
    var openCart = !this.state.openCart;
    if (openCart === true) {
      $(".orderitem_body_div .order_item_mobile").slideDown();
    } else {
      $(".orderitem_body_div .order_item_mobile").slideUp();
    }

    this.setState({ openCart: openCart });
  }
  gotorestaurant() {
    if (
      cookie.load("orderOutletSlug") !== "" &&
      typeof cookie.load("orderOutletSlug") !== undefined &&
      typeof cookie.load("orderOutletSlug") !== "undefined"
    ) {
      this.props.history.push("/food/" + cookie.load("orderOutletSlug"));
    } else {
      this.props.history.push("/restaurants");
    }
  }

  render() {
    let orderArr = this.props.orderdetail;
    var orderDetail = [];
    if (Object.keys(orderArr).length > 0) {
      $("#dvLoading").hide();
      if (orderArr[0].status === "ok") {
        orderDetail = orderArr[0].result_set[0];
      }
    }
    var availability_label = "Pickup";

    if (orderDetail.order_availability_id === deliveryId) {
      availability_label = "Delivery";
    } else if (orderDetail.order_availability_id === dineinId) {
      availability_label = "Dine in";
    }

    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}

        {/* container - start */}
        <div className="container">
          {/* innersection_wrap - start */}
          {Object.keys(orderDetail).length > 0 && (
            <div className="innersection_wrap tnkyou-maindiv">
              <div className="tnkyou-header">
                <img src={tick_img} alt="Thank You" />
                <h2>Thank You</h2>
                <p>Your order has been placed successfully</p>
              </div>
              {/* order-detail-maindiv - start */}
              <div className="tnkyou-innerdiv">
                <div className="thank-order-detaildiv">
                  <div className="tnk-detail text-center">
                    <h2>YOUR ORDER DETAILS</h2>
                    <div className="tnk-order">
                      <h3>Order No - {orderDetail.order_local_no}</h3>
                      <p>
                        Order placed at :{" "}
                        {Moment(orderDetail.order_created_on).format(
                          "DD/MM/YYYY hh:mm A"
                        )}
                      </p>
                      <p>Paid via Counter</p>
                    </div>
                  </div>
                  <div className="tnk-delivery">
                    {orderDetail.order_availability_id === deliveryId ? (
                      <div className="delivery-cart-div">
                        <div className="cart-direction">
                          <img
                            className="cart-direction-left"
                            src={scootyImg}
                            alt="Outlet Location"
                          />
                          <img
                            className="cart-direction-right"
                            src={cartMpImg}
                            alt="Delivery Location"
                          />
                        </div>
                        <div className="cart_row tnkorder-first">
                          <div className="order-hlhs text-left">
                            <h5>Order Handling By</h5>
                            <p>{stripslashes(orderDetail.outlet_name)}</p>
                            {orderDetail.order_outlet_category_id !== "2" && (
                              <p>
                                {addressFormat(
                                  orderDetail.outlet_unit_number1,
                                  orderDetail.outlet_unit_number2,
                                  orderDetail.outlet_address_line1,
                                  orderDetail.outlet_address_line2,
                                  orderDetail.outlet_postal_code
                                )}
                              </p>
                            )}
                          </div>
                          <div className="order-hrhs text-right">
                            <h5> {availability_label} Location</h5>
                            <p>
                              {addressFormat(
                                orderDetail.order_customer_unit_no1,
                                orderDetail.order_customer_unit_no2,
                                orderDetail.order_customer_address_line1,
                                orderDetail.order_customer_address_line2,
                                orderDetail.order_customer_postal_code
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="pickup-cart-div">
                        <div className="cart_row cart-header-first">
                          <div className="pickup-thankfull text-center">
                            <h4>{availability_label} Details</h4>
                            {/* <p>{stripslashes(orderDetail.outlet_name)}</p> */}
                            {/* <p>
                              {addressFormat(
                                orderDetail.outlet_unit_number1,
                                orderDetail.outlet_unit_number2,
                                orderDetail.outlet_address_line1,
                                orderDetail.outlet_address_line2,
                                orderDetail.outlet_postal_code
                              )}
                            </p> */}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="delivery_total delivery_datetime_div">
                      <div className="delivery_total_left">
                        <h2>{stripslashes(orderDetail.outlet_name)}</h2>
                        <h4 className="checkoutDate">
                          Singapore Swimming Club
                        </h4>
                      </div>
                      <div className="delivery_total_left delivery_total_right">
                        <h2>Table No</h2>
                        <h4 className="checkoutTime">
                          {orderDetail.order_table_number}
                        </h4>
                      </div>
                    </div>
                    <div className="pickup-date-maindiv">
                      <div className="pickup_total_left">
                        <h2>{availability_label} Date</h2>
                        <h4 className="checkoutDate">
                          {Moment(orderDetail.order_date).format("DD/MM/YYYY")}
                        </h4>
                      </div>
                      <div className="pickup_total_right">
                        <h2>{availability_label} time</h2>
                        <h4 className="checkoutTime">
                          {this.showOrdTimeSlot(orderDetail)}
                        </h4>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                  {orderDetail.order_availability_id === deliveryId &&
                    orderDetail.padd_api_driver_name !== null &&
                    orderDetail.padd_api_driver_phone !== null && (
                      <div className="order-rider-details">
                        <div className="delivery_total_left">
                          <h2>Rider Name</h2>
                          <h4 className="checkoutDate">
                            {orderDetail.padd_api_driver_name}
                          </h4>
                        </div>
                        <div className="delivery_total_left delivery_total_right">
                          <h2>Rider Mobile</h2>
                          <h4 className="checkoutTime">
                            {orderDetail.padd_api_driver_phone}
                          </h4>
                        </div>
                      </div>
                    )}
                </div>
                {/* order-detail-maindiv - end */}
                <div className="tnkyou_item_bodydiv">
                  <div className="overall-parent">
                    <div className="order-details-with-clear tnkyou-bd-heading">
                      <h5>Your Items</h5>
                    </div>
                    {this.loadItems(orderDetail)}
                    {orderDetail.order_remarks !== "" && (
                      <div className="remark_notesec text-left">
                        <h4>Remarks</h4>
                        <p>{orderDetail.order_remarks}</p>
                      </div>
                    )}

                    {orderDetail.order_customer_send_gift === "Yes" && (
                      <>
                        <div className="remark_notesec text-left">
                          <h4>Recipient Name</h4>
                          <p>{orderDetail.order_recipient_name}</p>
                        </div>
                        <div className="remark_notesec text-left">
                          <h4>Recipient Contact Number</h4>
                          <p>{orderDetail.order_recipient_contact_no}</p>
                        </div>
                        {orderDetail.order_gift_message !== "" &&
                          orderDetail.order_gift_message !== null && (
                            <div className="remark_notesec text-left">
                              <h4>Gift Message</h4>
                              <p>{orderDetail.order_gift_message}</p>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </div>
                <div className="cart_footer tnk_cart_footer">
                  <div className="order-divider">
                    <div className="cart_row-full">
                      <div className="cart_row">
                        <div className="row-replace">
                          <div className="col-xs-cls">
                            <p>Subtotal</p>
                          </div>
                          <div className="col-xs-cls text-right">
                            <span>${orderDetail.order_sub_total}</span>
                          </div>
                        </div>
                      </div>

                      {parseFloat(orderDetail.order_delivery_charge) > 0 && (
                        <div className="cart_row">
                          <div className="row-replace">
                            <div className="col-xs-cls">
                              <p>Delivery Charge</p>
                            </div>
                            <div className="col-xs-cls text-right">
                              <span>
                                $
                                {parseFloat(
                                  orderDetail.order_delivery_charge
                                ).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      {parseFloat(orderDetail.order_additional_delivery) >
                        0 && (
                        <div className="cart_row">
                          <div className="row-replace">
                            <div className="col-xs-cls">
                              <p>Additional Delivery Charge</p>
                            </div>
                            <div className="col-xs-cls text-right">
                              <span>
                                $
                                {parseFloat(
                                  orderDetail.order_additional_delivery
                                ).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {(parseFloat(orderDetail.order_discount_amount) > 0 ||
                    (orderDetail.order_promocode_name !== "" &&
                      orderDetail.order_promocode_name !== null)) && (
                    <div className="sidebar-discount">
                      <div className="sidebar-discount-inner">
                        <div className="sidebar-discount-lhs">
                          <span>
                            {" "}
                            {orderDetail.order_discount_type === "redeem"
                              ? "Discount (-)"
                              : orderDetail.order_promocode_name !== ""
                              ? "Promocode (" +
                                orderDetail.order_promocode_name +
                                ")"
                              : "Promocode (-)"}
                          </span>
                        </div>
                        <div className="sidebar-discount-rhs">
                          {parseFloat(orderDetail.order_discount_amount) >
                            0 && (
                            <span>
                              $
                              {parseFloat(
                                orderDetail.order_discount_amount
                              ).toFixed(2)}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {parseFloat(orderDetail.order_tax_calculate_amount) > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-xs-cls">
                          <p>
                            GST({parseFloat(orderDetail.order_tax_charge)}%)
                          </p>
                        </div>
                        <div className="col-xs-cls text-right">
                          <span>
                            $
                            {parseFloat(
                              orderDetail.order_tax_calculate_amount
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="cart_row grant-total-cls">
                    <p className="text-uppercase">Total</p>
                    <span>
                      <sup>$</sup>
                      {parseFloat(orderDetail.order_total_amount).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
              {/* {cookie.load("loginTableNo") !== "Yes" && (
                <div className="tnk-chk-order">
                  <Link
                    to={"/myorders/" + orderDetail.order_local_no}
                    className="button"
                  >
                    Check Your Order Status
                  </Link>
                </div>
              )} */}
              <div className="tnk-chk-order">
                <a
                  href={void 0}
                  className="button"
                  onClick={this.gotorestaurant.bind(this)}
                >
                  Back to Menu
                </a>
              </div>
            </div>
          )}

          {/* innersection_wrap - End */}
        </div>

        {/* container - end */}
        <div id="dvLoading" className="dvLoadrCls"></div>
        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  return {
    settingsArr: globalSettings,
    orderdetail: state.orderdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderDetail: (orderId) => {
      dispatch({ type: GET_ORDER_DETAIL, orderId });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Thankyou);
