/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Axios from "axios";
import {
  stripslashes,
  getCalculatedAmount,
  showLoader,
  hideLoader,
  getPromoCkValue,
  removePromoCkValue,
  resetCrtStyle,
  showCustomAlert,
  showCustomCenterAlert,
  showToastrAlert,
  getReferenceID,
} from "../Helpers/SettingHelper";
import {
  apiUrl,
  restaurantLink,
  proFolderUrl,
  deliveryId,
  pickupId,
  dineinId,
  apiUrlV2,
  appId,
  shopType,
  headerconfig,
  cookieConfig,
} from "../Helpers/Config";
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot";

/* import images */
import cartwhite from "../../common/images/shopping-bag.svg";
import arrow from "../../common/images/name-arrow.svg";
import butterfly from "../../common/images/butterfly.svg";
import deliveryprogress from "../../common/images/delivery-progress.svg";
import deliveryunlocked from "../../common/images/delivery-unlocked.svg";
import redwarning from "../../common/images/red-warning.svg";
import noimage from "../../common/images/no-img-product.png";
import deliverinfo from "../../common/images/delivery-info.svg";
import returncart from "../../common/images/return-cart.svg";
import cartpurple from "../../common/images/cart-purple.svg";

import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
} from "../../actions";
var qs = require("qs");
var Parser = require("html-react-parser");
class CartSideBar extends Component {
  constructor(props) {
    super(props);
    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    var promotionType =
      cookie.load("promotionType") !== "" &&
      typeof cookie.load("promotionType") !== undefined
        ? cookie.load("promotionType")
        : "";
    var orderZoneId =
      cookie.load("orderZoneId") !== "" &&
      typeof cookie.load("orderZoneId") !== undefined &&
      typeof cookie.load("orderZoneId") !== "undefined"
        ? cookie.load("orderZoneId")
        : "";

    var unitNoOne =
      typeof cookie.load("unitNoOne") === "undefined"
        ? ""
        : cookie.load("unitNoOne");
    var unitNoTwo =
      typeof cookie.load("unitNoTwo") === "undefined"
        ? ""
        : cookie.load("unitNoTwo");

    /* For Advanced Slot */
    var isAdvanced =
      typeof cookie.load("isAdvanced") === "undefined"
        ? ""
        : cookie.load("isAdvanced");
    var slotType =
      typeof cookie.load("slotType") === "undefined"
        ? ""
        : cookie.load("slotType");

    var TodayDate = new Date();
    var deliveryDate =
      cookie.load("deliveryDate") !== "" &&
      typeof cookie.load("deliveryDate") !== undefined &&
      typeof cookie.load("deliveryDate") !== "undefined" &&
      cookie.load("deliveryDate") !== undefined
        ? cookie.load("deliveryDate")
        : "";
    var order_date_exist = "";
    if (deliveryDate !== "") {
      var delivery_Date = deliveryDate.split("/");
      TodayDate = new Date(new Date(TodayDate).setDate(delivery_Date[0]));
      TodayDate = new Date(new Date(TodayDate).setMonth(delivery_Date[1] - 1));
      TodayDate = new Date(new Date(TodayDate).setFullYear(delivery_Date[2]));

      order_date_exist = TodayDate;
    }
    var deliveryTime =
      cookie.load("deliveryTime") !== "" &&
      typeof cookie.load("deliveryTime") !== undefined &&
      typeof cookie.load("deliveryTime") !== "undefined" &&
      cookie.load("deliveryTime") !== undefined
        ? cookie.load("deliveryTime")
        : "";
    var order_time_exist = "";
    if (deliveryTime !== "") {
      var delivery_Time = deliveryTime.split(":");
      TodayDate = new Date(new Date(TodayDate).setHours(delivery_Time[0]));
      TodayDate = new Date(new Date(TodayDate).setMinutes(delivery_Time[1]));
      TodayDate = new Date(new Date(TodayDate).setSeconds(delivery_Time[2]));
      order_time_exist = TodayDate;
    }
    var orderTAT =
      typeof cookie.load("orderTAT") === "undefined"
        ? ""
        : cookie.load("orderTAT");

    this.state = {
      defaultAvilTy: avilablityIdTxt,
      seletedAvilablityId: avilablityIdTxt,
      promotionType: promotionType,
      orderZoneId: orderZoneId,
      cartlistdetail: [],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      settings: [],
      cartTotalItmCount: 0,
      cartTotalAmount: 0,
      cartOutletList: [],
      selectitem: false,
      selectItemList: [],
      edititemID: null,
      staticblacks: [],
      deliveryinfo: "",
      addPromoCode: false,
      addtocartLoast: false,
      unitnumber1: unitNoOne,
      unitnumber2: unitNoTwo,

      /* For Advanced Slot */
      getDateTimeFlg: "yes",
      seleted_ord_date: order_date_exist,
      seleted_ord_time: order_time_exist,
      seleted_ord_slot:
        cookie.load("orderSlotVal") !== "" &&
        typeof cookie.load("orderSlotVal") !== undefined &&
        typeof cookie.load("orderSlotVal") !== "undefined" &&
        cookie.load("orderSlotVal") !== undefined
          ? cookie.load("orderSlotVal")
          : "",
      seleted_ord_slotTxt:
        cookie.load("orderSlotTxt") !== "" &&
        typeof cookie.load("orderSlotTxt") !== undefined &&
        typeof cookie.load("orderSlotTxt") !== "undefined" &&
        cookie.load("orderSlotTxt") !== undefined
          ? cookie.load("orderSlotTxt")
          : "",
      seleted_ord_slot_str:
        cookie.load("orderSlotStrTime") !== "" &&
        typeof cookie.load("orderSlotStrTime") !== undefined &&
        typeof cookie.load("orderSlotStrTime") !== "undefined" &&
        cookie.load("orderSlotStrTime") !== undefined
          ? cookie.load("orderSlotStrTime")
          : "",
      seleted_ord_slot_end:
        cookie.load("orderSlotEndTime") !== "" &&
        typeof cookie.load("orderSlotEndTime") !== undefined &&
        typeof cookie.load("orderSlotEndTime") !== "undefined" &&
        cookie.load("orderSlotEndTime") !== undefined
          ? cookie.load("orderSlotEndTime")
          : "",
      isAdvanced: isAdvanced,
      slotType: slotType,
      order_tat_time: orderTAT,
    };
    this.handleChangeItem = this.handleChangeItem.bind(this);
  }
  componentDidMount() {
    /* $(document).on("click", function (e) {
      var container = $(".hcart_dropdown");
      var htico_cart = $(".htico_cart");
      var warningminorder = $("#warning-min-order");
      if (
        !$(e.target).closest(container).length &&
        !$(e.target).closest(htico_cart).length &&
        !$(e.target).closest(warningminorder).length
      ) {
        $("body").removeClass("cart-items-open");
        $("body").css("overflow", "initial");
        $(".hcartdd_trigger").removeClass("active");
        $(".hcart_dropdown").removeClass("open");
      }
    }); */
    this.props.getCartDetail();
  }

  componentWillReceiveProps(headerProps) {
    if (headerProps.staticblack !== this.state.staticblacks) {
      var deliveryinfo = "";

      if (Object.keys(headerProps.staticblack).length > 0) {
        headerProps.staticblack.map((data) => {
          if (data.staticblocks_slug === "delivery-info") {
            deliveryinfo =
              data.staticblocks_description !== ""
                ? Parser(data.staticblocks_description)
                : "";
            return "";
          }
        });
      }

      this.setState({
        deliveryinfo: deliveryinfo,
        staticblacks: headerProps.staticblack,
      });
    }

    if (headerProps.cartItems !== this.state.cartItems) {
      this.setState({ cartItems: headerProps.cartItems });
    }
    if (headerProps.cartTriggerFlg === "yes") {
      if (headerProps.headerState.defaultAvilTy !== this.state.defaultAvilTy) {
        this.setState(
          { defaultAvilTy: headerProps.headerState.defaultAvilTy },
          function () {
            this.setState(
              {
                defaultAvilTy: headerProps.headerState.defaultAvilTy,
              },
              function () {
                this.setState({
                  defaultAvilTy: headerProps.headerState.defaultAvilTy,
                });
              }
            );
          }
        );
      }
      headerProps.prpSateValChange("cartflg", "no");
      this.props.getCartDetail();
      resetCrtStyle();
    }
    if (headerProps.cartClearTriggerFlg === "yes") {
      headerProps.prpSateValChange("cartclearflg", "no");
      this.clearCartItm();
    }
    hideLoader("product-details", "class");
    hideLoader("cart_body", "class");
    hideLoader("clear_selected_item", "class");
    $(".remove_cart_item").prop("checked", false);
    hideLoader("cartaction_checkout", "class");
    hideLoader("cancel_order", "class");
    /*  this.setState({ selectitem: false }); */
    if (this.state.cartOutletList !== headerProps.cartOutletList) {
      this.setState(
        { cartOutletList: headerProps.cartOutletList },
        function () {
          /*    this.props.cart_Outlet_List(
            "cartOutletList
            headerProps.cartOutletList
          ); */
        }
      );
    }
    if (this.state.cartTotalItmCount !== headerProps.cartTotalItmCount) {
      this.setState(
        { cartTotalItmCount: headerProps.cartTotalItmCount },
        function () {
          /*  this.props.cart_Outlet_List(
            "totalCartCount",
            headerProps.cartTotalItmCount
          ); */
        }
      );
    }
    if (this.state.cartDetails !== headerProps.cartDetails) {
      this.setState({ cartDetails: headerProps.cartDetails }, function () {
        /*   this.props.cart_Outlet_List("cartDetails", headerProps.cartDetails); */
      });
    }
    if (this.state.cartStatus !== headerProps.cartStatus) {
      this.setState({ cartStatus: headerProps.cartStatus });
    }
  }

  viewProDetail(productSlug) {
    var outlet_id = this.props.cartDetails?.outlet_id;
    if (
      cookie.load("defaultAvilablityId") === "" ||
      typeof cookie.load("defaultAvilablityId") === undefined ||
      typeof cookie.load("defaultAvilablityId") === "undefined"
    ) {
      $.magnificPopup.open({
        items: {
          src: "#dinein-popup",
        },
        type: "inline",
      });

      return false;
    } else if (cookie.load("defaultAvilablityId") === dineinId) {
      if (
        cookie.load("orderOutletId") !== "" &&
        typeof cookie.load("orderOutletId") !== undefined &&
        typeof cookie.load("orderOutletId") !== "undefined"
      ) {
        if (cookie.load("orderOutletId") !== outlet_id) {
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        }
      }
    }

    if (productSlug !== "") {
      showLoader("comboPro-" + productSlug);

      this.props.prpSateValChange("view_pro_data", productSlug);
      this.props.prpSateValChange("showProDetails", "yes");
    }
    return false;
  }

  removePromoFun(event) {
    event.preventDefault();
    removePromoCkValue();
    this.props.getCartDetail();
  }

  closeCartlist(e) {
    $("body").removeClass("cart-items-open");
    $("body").css("overflow", "initial");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");
  }
  handleAddrChange(event) {
    if (event.target.name === "promo_code") {
      this.setState({ promo_code_val: event.target.value });
    } else if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, cookieConfig);
      this.setState({ unitnumber1: event.target.value });
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, cookieConfig);
      this.setState({ unitnumber2: event.target.value });
    }
  }
  addNewPromo(e) {
    e.preventDefault();
    this.setState({ addPromoCode: !this.state.addPromoCode });
  }

  applyPointsAndPromo() {
    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;
    var reedemPointVal = this.state.reward_point_val;
    var promoCodeVal = this.state.promo_code_val;
    var usedPoints = this.state.used_reward_point;
    var promoOutlet = this.state.promoOutlet;
    var promoType = this.state.promoType;
    var promoPercentage = this.state.promoPercentage;
    var cartDetailsArr = this.state.cartDetails;
    var cartItemsSateArr = this.state.cartItems;
    var promoTitle = "";
    var promooutletproducts = "";

    if (promoCodeVal === "") {
      showCustomCenterAlert("error", "Please enter Promo Code");
      return false;
    }

    var subTotal =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_sub_total
        : 0;
    var totalItems =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_total_items
        : 0;
    showLoader("applypromo_cls", "class");

    var categoryIdsDet = this.getProductIdsDet(cartItemsSateArr);
    var avilablityId = this.state.defaultAvilTy;

    var postObject = {
      app_id: appId,
      reference_id: cookie.load("UserId"),
      promo_code: promoCodeVal,
      cart_amount: subTotal,
      cart_quantity: totalItems,
      category_id: categoryIdsDet,
      availability_id: avilablityId,
      outlet_id: cookie.load("orderOutletId"),
    };

    Axios.post(
      apiUrl + "promotion_api_v2/apply_promotion",
      qs.stringify(postObject),
      headerconfig
    ).then((res) => {
      var promotionID = "";
      if (res.data.status === "success") {
        var pointDet = res.data.result_set;
        var IsDelivery =
          pointDet.promotion_delivery_charge_applied == "Yes" ? "Yes" : "No";
        promotionApplied = "Yes";
        promotionType = "promoCode";
        promotionAmount = pointDet.promotion_amount;
        promotionSource = promoCodeVal;
        promoIsDelivery = IsDelivery;
        reedemPointVal = "";
        promoOutlet = pointDet.promotion_outlet_id;
        promotionID = pointDet.promotion_id;
        promoType = pointDet.promotion_type;
        promoPercentage = pointDet.promotion_percentage;
        promoTitle = pointDet.promotion_title;
        promooutletproducts = JSON.stringify(pointDet.outlet_promo_products);
        usedPoints = 0;
        $.magnificPopup.close();
        showToastrAlert("Promo applied successfully");
      } else {
        var msgTxt =
          res.data.message !== ""
            ? res.data.message
            : "Sorry! Did not applied promo code";
        if (promotionType === "promoCode") {
          promotionApplied = "";
          promotionType = "";
          promotionAmount = "";
          promotionSource = "";
          promoIsDelivery = "";
          promoOutlet = "";
          promoType = "";
          promoPercentage = "";
          promoTitle = "";
        }
        showCustomCenterAlert("error", msgTxt);
      }

      hideLoader("applypromo_cls", "class");

      cookie.save("promotionID", promotionID, cookieConfig);
      cookie.save("reedemPointVal", reedemPointVal, { path: "/" });
      cookie.save("promoCodeVal", promoCodeVal, { path: "/" });
      cookie.save("promoOutlet", promoOutlet, { path: "/" });
      cookie.save("promotionApplied", promotionApplied, { path: "/" });
      cookie.save("promotionType", promotionType, { path: "/" });
      cookie.save("promotionAmount", promotionAmount, { path: "/" });
      cookie.save("promotionSource", promotionSource, { path: "/" });
      cookie.save("promoIsDelivery", promoIsDelivery, { path: "/" });
      cookie.save("usedPoints", usedPoints, { path: "/" });
      cookie.save("promoType", promoType, { path: "/" });
      cookie.save("promoTitle", promoTitle, { path: "/" });
      cookie.save("promoPercentage", promoPercentage, { path: "/" });
      cookie.save("promooutletproducts", promooutletproducts, cookieConfig);

      this.setState({
        reward_point_val: reedemPointVal,
        promo_code_val: promoCodeVal,
        promotion_applied: promotionApplied,
        promotion_type: promotionType,
        promotion_amount: promotionAmount,
        promotion_source: promotionSource,
        promoIs_delivery: promoIsDelivery,
        used_reward_point: usedPoints,
        promoOutlet: promoOutlet,
        promoType: promoType,
        promoPercentage: promoPercentage,
      });
    });
  }

  removePointsAndPromo(flag, event) {
    if (flag === "fromclk") {
      event.preventDefault();
    }
    var paymentmode = this.state.paymentmodevalue;
    if (paymentmode === "promotion") {
      var settingsArr = this.state.globalSettings;
      var paymentmode = "Stripe";
      if (Object.keys(settingsArr).length > 0) {
        if (
          settingsArr.client_cod_enable == 1 &&
          settingsArr.client_cod_availability == 1
        ) {
          paymentmode = "Cash";
        }

        if (
          settingsArr.client_omise_enable == 1 &&
          settingsArr.client_omise_availability == 1 &&
          paymentmode !== "Cash"
        ) {
          paymentmode = "Omise";
        }
      }
    }

    removePromoCkValue();

    this.setState({
      reward_point_val: "",
      promo_code_val: "",
      promotion_applied: "",
      promotion_type: "",
      promotion_amount: "",
      promotion_source: "",
      promoIs_delivery: "",
      used_reward_point: 0,
      paymentmodevalue: paymentmode,
      promoOutlet: "",
      promoType: "",
      promoPercentage: "",
    });
  }

  getProductIdsDet(cartItems) {
    var product_cartid = "";
    if (Object.keys(cartItems).length > 0) {
      for (var key in cartItems) {
        if (
          product_cartid !== "" &&
          cartItems[key].cart_item_product_id !== ""
        ) {
          product_cartid += ";";
        }
        product_cartid +=
          cartItems[key].cart_item_product_id +
          "|" +
          cartItems[key].cart_item_total_price +
          "|" +
          cartItems[key].cart_item_qty;
      }
    }

    return product_cartid;
  }
  checkFreeAdded(freePro) {
    var cartItemsArr = this.props.cartItems;
    var existsPro = 0;
    if (Object.keys(cartItemsArr).length > 0) {
      Object.keys(cartItemsArr).map((index) => {
        Object.keys(cartItemsArr[index]).map((index1) => {
          Object.keys(cartItemsArr[index][index1]).map((index2) => {
            var cartpro = cartItemsArr[index][index1][index2];
            if (cartpro.length > 0) {
              cartpro.map((item) => {
                if (freePro === item.cart_item_product_id) {
                  existsPro++;
                }
              });
            }
          });
        });
      });
    }
    return existsPro;
  }
  cartDetailsList() {
    var cartItemsArr = this.props.cartItems;
    var outlet = (this.props.outletslist || []).find(
      (o) => o.outlet_id === cookie.load("orderOutletId")
    );

    if (Object.keys(cartItemsArr).length > 0 || this.props.showAlways) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        this.props.maxCart
      );
      var cartActive = "hcart_dropdown";
      return (
        <div className={cartActive}>
          <div className="hcart_tt">
            <div
              id="cart-close-span"
              onClick={this.closeCartlist.bind(this)}
            ></div>
            <h3>Order Details</h3>
          </div>
          <div className="hcart_scrollarea">
            <div className="cart_table">
              <div className="cart_body">
                {cookie.load("defaultAvilablityId") === deliveryId && (
                  <div className="delivery-cart-div">
                    <div className="col-sm-cls text-left">
                      <h4>Order Handled By</h4>
                      <p>{cookie.load("orderOutletName")}</p>
                      <p>{cookie.load("orderHandledByText")}</p>
                    </div>

                    <div className="col-sm-cls text-right">
                      <h4>Delivery Location</h4>
                      <p>{cookie.load("orderDeliveryAddress")}</p>

                      <p>Singapore {cookie.load("orderPostalCode")}</p>

                      <div className="hcart_add_innr_loca">
                        <p>#</p>
                        <div className="input_field">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.unitnumber1}
                            name="unit_no1"
                            onChange={this.handleAddrChange.bind(this)}
                          />
                        </div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.unitnumber2}
                            name="unit_no2"
                            onChange={this.handleAddrChange.bind(this)}
                          />
                        </div>
                      </div>
                      <p>
                        <span>
                          <a
                            className="cart-loc-change"
                            href="/"
                            /*    onClick={this.changeAddrrFun.bind(this)} */
                          >
                            Change Delivery Location
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                )}

                {cookie.load("defaultAvilablityId") === pickupId && (
                  <div className="pickup-cart-div">
                    <div className="cart_row cart-header-first">
                      <div className="row-replace">
                        <div className="col-xs-12 cart_left text-center">
                          <h4>Pickup Location</h4>
                          <p>{cookie.load("orderOutletName")}</p>
                          <p>{cookie.load("orderHandledByText")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {cookie.load("defaultAvilablityId") === dineinId && (
                  <div className="pickup-cart-div">
                    <div className="cart_row cart-header-first">
                      <div className="row-replace">
                        <div className="col-xs-12 cart_left text-center">
                          {/* <h4>Dine In Location</h4> */}
                          <p>
                            {cookie.load("orderOutletName")}
                            <span>{outlet ? outlet.outlet_open_time : ""}</span>
                          </p>
                          {/* <p>{cookie.load("orderHandledByText")}</p> */}
                          <h4 className="">
                            Table
                            <span>{cookie.load("orderTableNo")}</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {cookie.load("defaultAvilablityId") !== dineinId && (
                  <div className="cart_row cart-header-second">
                    {/* <OrderAdvancedDatetimeSlot
                      {...this.props}
                      hdrState={this.state}
                      setdateTimeFlg={this.setdateTimeFlg}
                      indutualText={true}
                      labelDateName={
                        cookie.load("defaultAvilablityId") === deliveryId
                          ? "Delivery Date"
                          : cookie.load("defaultAvilablityId") === pickupId
                          ? "Takeaway Date"
                          : "Dine In Date"
                      }
                      labelTimeName={
                        cookie.load("defaultAvilablityId") === deliveryId
                          ? "Delivery Time"
                          : cookie.load("defaultAvilablityId") === pickupId
                          ? "Takeaway Time"
                          : "Dine In Time"
                      }
                    /> */}

                    {/*  <div className="col-sm-cls text-left">
                    <h4>Delivery Date</h4>
                    <div className="rel cart-date-left">
                      <input type="text" className="form-control" value="" />
                    </div>
                  </div>
                  <div className="col-sm-cls text-right">
                    <h4>Delivery Time</h4>
                    <div className="rel cart-date-right">
                      <input type="text" className="form-control" value="" />
                    </div>
                  </div> */}
                  </div>
                )}
                <div className="product_orders_top">
                  {/* <h4>Your Items</h4> */}
                  <div className="product_modify_cart add_item">
                    <a
                      href={`/food/${
                        this.state.seletedOutletId && this.state.overAllcart
                          ? this.state.overAllcart.outletslug?.[
                              this.state.seletedOutletId
                            ]
                          : "the-palms"
                      }`}
                      className="hclear_cart"
                      title="Add Items"
                    >
                      Add Items
                    </a>
                  </div>
                  {Object.keys(cartItemsArr).length > 0 ? (
                    <div className="product_modify_cart">
                      <a
                        href="/"
                        onClick={this.clearCartItm.bind(this)}
                        className="hclear_cart"
                        title="CLEAR CART"
                      >
                        Clear Cart
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {this.cartItemList()}
              </div>
            </div>

            <div className="cart_footer">
              {/* <div className="cart_row-header">
                <h6 className="uppercase letter-space-6">Order Summary</h6>
              </div> */}
              <div className="cart_row-full">
                <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p>Subtotal</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>${calculatedAmount["cartSubTotalAmount"]}</span>
                    </div>
                  </div>
                </div>
                {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p>
                          Delivery fee{" "}
                          {calculatedAmount["zoneName"] !== ""
                            ? "(" + calculatedAmount["zoneName"] + ")"
                            : ""}{" "}
                          {this.state.deliveryinfo !== "" && (
                            <a
                              href={void 0}
                              onClick={this.opentDeliveryInfo.bind(this)}
                            >
                              <img src={deliverinfo} alt="Info" />
                            </a>
                          )}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          $
                          {parseFloat(
                            calculatedAmount["deliveryCharge"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p>Additional Delivery</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          $
                          {parseFloat(
                            calculatedAmount["additionalDelivery"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {parseFloat(calculatedAmount["orderGstAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p>GST ({calculatedAmount["orderDisplayGst"]} %)</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          $
                          {parseFloat(
                            calculatedAmount["orderGstAmount"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* cookie.load("userLoginFrom") === "ocid" && (
                  <div className="sidebar-discount">
                    {parseFloat(calculatedAmount["promotionAmount"]) > 0 &&
                      calculatedAmount["promotionApplied"] === "Yes" && (
                        <div className="sidebar-discount-inner">
                          <div className="sidebar-discount-lhs">
                            <span>{cookie.load("promotionSource")}</span>
                            <a
                              href={void 0}
                              onClick={this.removePromoFun.bind(this)}
                            >
                              Remove
                            </a>
                            {cookie.load("promoTitle") !== "" &&
                              typeof cookie.load("promoTitle") !== undefined &&
                              typeof cookie.load("promoTitle") !==
                                "undefined" && (
                                <p>{cookie.load("promoTitle")}</p>
                              )}
                          </div>
                          <div className="sidebar-discount-rhs">
                            -$
                            {parseFloat(
                              calculatedAmount["promotionAmount"]
                            ).toFixed(2)}
                          </div>
                        </div>
                      )}
                    {calculatedAmount["promotionApplied"] !== "Yes" && (
                      <div className="sidebar-promo-add">
                        {this.state.addPromoCode === true ? (
                          <div className="form-group">
                            <div className="focus-out">
                              <input
                                type="text"
                                className="form-control input-focus"
                                placeholder="Promo Code"
                                onChange={this.handleAddrChange.bind(this)}
                                value={this.state.promo_code_val}
                                id="promocodeValue"
                                name="promo_code"
                              />
                            </div>
                            <div className="promoadd-buttons">
                              <a
                                href="#"
                                className="button"
                                onClick={this.applyPointsAndPromo.bind(this)}
                              >
                                Apply
                              </a>
                              <a
                                href="#"
                                className="button ghost-button"
                                onClick={this.addNewPromo.bind(this)}
                              >
                                Cancel
                              </a>
                            </div>
                          </div>
                        ) : (
                          <a href="#" onClick={this.addNewPromo.bind(this)}>
                            Add promo code
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                ) */}

              <div className="reward-total">
                <div className="cart_row grant-total-cls">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p>Total</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        <sup>$</sup>
                        {calculatedAmount["grandTotalAmount"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
                <div className="member-discount-total">
                  * {cartDetailsArr.cart_special_discount_type} $
                  {cartDetailsArr.cart_special_discount} Applied
                </div>
              )}

              {cookie.load("defaultAvilablityId") === deliveryId &&
                this.loadMinOrderPercentage()}

              {cookie.load("defaultAvilablityId") === deliveryId &&
                this.loadDeliveryPercentage()}

              <div
                className={
                  this.state.selectitem === false
                    ? "cart_action"
                    : "cart_action unselect-cart-action"
                }
              >
                {this.state.selectitem === false ? (
                  <div className="cartaction_bottom">
                    <div className="sticky-total-button">
                      <Link
                        to={"/checkout"}
                        className={`button cartaction_checkout ${
                          Object.keys(this.state.cartItems).length > 0
                            ? ""
                            : "disabled"
                        }`}
                        title="NEXT"
                        onClick={this.closeCartlist.bind(this)}
                      >
                        NEXT
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="cartaction_bottom">
                    <a href={void 0} onClick={this.unSelectAll.bind(this)}>
                      Unselect all
                    </a>
                    <a
                      href={void 0}
                      onClick={this.clearCartItm.bind(this)}
                      className="button clear_selected_item"
                    >
                      Remove selected from cart
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      $("body").removeClass("cart-items-open");
      $("body").css("overflow", "auto");
      $(".hcartdd_trigger").removeClass("active");
      $(".hcart_dropdown").removeClass("open");
      return "";
    }
  }

  /* For Advanced Slot */
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
    } else if (field == "updatedTat") {
      cookie.save("orderTAT", value, cookieConfig);
    } /* else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    } */
  };

  addToCartSimple(productDetail, event) {
    event.preventDefault();
    var IndexFlg = productDetail.product_primary_id;
    showLoader("free-" + IndexFlg, "Idtext");
    if (this.state.addtocartLoast === false) {
      this.setState({ addtocartLoast: true });
      var availabilityId = cookie.load("defaultAvilablityId");
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var specialprice_applicable =
        productDetail.product_specialprice_applicable;

      var postObject = {};
      /*  */
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: 1,
        product_free_qty: productDetail.quantity,
        outletLocationId: productDetail.outlet_location_id,
        outlet_id: productDetail.pao_outlet_id,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        specialprice_applicable: specialprice_applicable,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        shopType: shopType,
        outletzone_id: this.state.orderZoneId,
        login_type:
          typeof cookie.load("userAccountType") === "undefined"
            ? ""
            : cookie.load("userAccountType"),
        reference_new_id: getReferenceID(),
      };

      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject),
        headerconfig
      ).then((res) => {
        if (res.data.status === "ok") {
          var productName =
            productDetail.product_alias !== "" &&
            productDetail.product_alias !== null
              ? productDetail.product_alias
              : productDetail.product_name;
          var proPrice = productDetail.product_price;
          if (specialprice_applicable === "yes") {
            proPrice = productDetail.product_special_price;
          }

          this.props.sateValChange("cartflg", "yes");
          this.setState({ addtocartLoast: false });
          removePromoCkValue();
          showCustomAlert(
            "success",
            "Great! Your item has been added to the cart",
            "cart-success-msg"
          );
        } else if (res.data.status === "error") {
          hideLoader("free-" + IndexFlg, "Idtext");
          this.setState({ addtocartLoast: false });
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          showCustomAlert("error", errMsgtxt);
        }

        return false;
      });
    }
  }

  loadnolongerItem() {
    var cartItemsArr = this.props.cartItems;
    var outOfSKU = "";
    if (Object.keys(cartItemsArr).length > 0) {
      Object.keys(cartItemsArr).map((index) => {
        Object.keys(cartItemsArr[index]).map((index1) => {
          Object.keys(cartItemsArr[index][index1]).map((index2) => {
            var cartpro = cartItemsArr[index][index1][index2];
            if (cartpro.length > 0) {
              cartpro.map((item) => {
                if (
                  item.product_stock === null ||
                  parseInt(item.product_stock) === 0
                ) {
                  outOfSKU += item.cart_item_product_name + ", ";
                }
              });
            }
          });
        });
      });
    }
    if (outOfSKU !== "") {
      outOfSKU = outOfSKU.substring(0, outOfSKU.length - 2);
      return (
        <div className="no-longer-cart-item">
          <img src={redwarning} alt="" />
          Some items in your cart are no longer available due to your updated{" "}
          {outOfSKU} options.{" "}
          <a href={void 0} onClick={this.removeNoLongerItem.bind(this)}>
            Remove all unavailable items
          </a>
        </div>
      );
    }
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentage() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar progress_bar_low"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">${remainAmnt} more to FREE delivery!</p>
          </div>
        </div>
      );
    }
  }

  /* this  function used to load delivery percentage */
  loadMinOrderPercentage() {
    var minimumAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr
      );
      minimumAmnt = parseFloat(calculatedAmount["minimumAmnt"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / minimumAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(minimumAmnt - subTotal).toFixed(2);
    }

    if (minimumAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div progress_bar_one">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">${remainAmnt} more to Min Order Amount</p>
          </div>
        </div>
      );
    }
  }
  applyFreeDelivery() {
    cookie.save("applyfreedelivery", "Yes", cookieConfig);
    this.setState({ applyfreedelivery: "Yes" });
  }

  loadMinCartPercentage() {
    var delPercentage = 0;
    var min_amount = 0;
    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      var cartDetailsArr = this.props.cartDetails;
      var subTotal = parseFloat(cartDetailsArr.cart_sub_total);
      min_amount = parseFloat(globalSettings.pos_min_amount);
      var percentage = (subTotal * 100) / min_amount;
      percentage = Math.round(percentage);
      var balance = parseFloat(min_amount - subTotal);
      if (
        balance > 0 &&
        min_amount > 0 &&
        min_amount > subTotal &&
        percentage > 0
      ) {
        balance = balance.toFixed(2);
        return (
          <div className="cart_row progress_bar_div minimum-order-amount">
            <div className="indication">
              <div className="indication_progress">
                <span
                  className="progress_bar"
                  style={{ width: percentage + "%" }}
                />
              </div>
              <p className="help-block">${balance} more to min order amount</p>
            </div>
          </div>
        );
      }
    }
  }

  cartItemList() {
    var cartItemsArr = this.props.cartItems;
    var cartOutletArr = this.props.cartOutlet;
    var cartOutletSlugArr = this.props.outletslug;
    if (Object.keys(cartItemsArr).length > 0) {
      return Object.keys(cartItemsArr).map((index) => {
        return (
          <div className="cart_outlet_row" key={index}>
            {Object.keys(cartItemsArr[index]).map((index2) => {
              let letOutleName =
                cartOutletArr[index2] !== "" &&
                typeof cartOutletArr[index2] !== undefined &&
                typeof cartOutletArr[index2] !== "undefined"
                  ? cartOutletArr[index2]
                  : "";
              let letOutleslug =
                cartOutletSlugArr[index2] !== "" &&
                typeof cartOutletSlugArr[index2] !== undefined &&
                typeof cartOutletSlugArr[index2] !== "undefined"
                  ? cartOutletSlugArr[index2]
                  : "";
              return (
                <div key={index2}>
                  {/* <div className="cart-outlet-name">
                    <Link
                      to={
                        index2 === "132"
                          ? void 0
                          : "/" + restaurantLink + "/" + letOutleslug
                      }
                      onClick={
                        index2 === "132" ? "" : this.closeCartlist.bind(this)
                      }
                    >
                      {letOutleName} <img src={arrow} />
                    </Link>
                  </div> */}
                  {Object.keys(cartItemsArr[index][index2]).map((index1) => {
                    let product = cartItemsArr[index][index2][index1];
                    return (
                      <div key={index1}>
                        {this.checkAvailability(index2)}
                        {this.cartOutletItemList(product, index2)}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      });
    }
  }

  checkAvailability(outletID) {
    let cartoutletavailability = this.props.cartoutletavailability;
    if (
      cartoutletavailability[outletID] !== "" &&
      typeof cartoutletavailability[outletID] !== undefined &&
      cartoutletavailability[outletID] !== "undefined"
    ) {
      if (
        cartoutletavailability[outletID].indexOf(this.state.defaultAvilTy) < 0
      ) {
        return (
          <span className="outlet-avilable">
            Currently unavailable. Let's try other restaurants!
          </span>
        );
      }
    }
  }

  cartOutletItemList(cartItemsArr, outletID) {
    if (Object.keys(cartItemsArr).length > 0 && cartItemsArr !== null) {
      return cartItemsArr.map((product, index) => (
        <div
          className="cart_row product-details"
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div className="row-replace">
            <div className="col-sm-cls cart_left">
              <div className="cart_info text-left cart-prdlist">
                {/* <input
                  type="checkbox"
                  className="tag_filter remove_cart_item"
                  value={product.cart_item_id}
                  onChange={this.handleChangeItem}
                /> */}
                <h4>
                  <a
                    href={
                      parseInt(product.product_free) === 1 || outletID === "53"
                        ? void 0
                        : product.outlet_slug !== ""
                        ? "/" +
                          restaurantLink +
                          "/" +
                          product.outlet_slug +
                          "/" +
                          product.pro_cate_slug +
                          "/" +
                          product.pro_subcate_slug +
                          "/" +
                          product.product_slug
                        : "/"
                    }
                    onClick={
                      parseInt(product.product_free) === 1 || outletID === "132"
                        ? ""
                        : this.closeCartlist.bind(this)
                    }
                  >
                    {stripslashes(product.cart_item_product_name).toLowerCase()}
                  </a>
                </h4>
                {this.loadModifierItems(
                  product.cart_item_type,
                  product.modifiers,
                  product.set_menu_component
                )}

                {product.cart_item_special_notes !== "" && (
                  <p className="help-block">
                    {stripslashes(product.cart_item_special_notes)}
                  </p>
                )}
                {parseInt(product.product_free) !== 1 && (
                  <a
                    className="edit-pro-left"
                    href={void 0}
                    onClick={this.editCartItem.bind(this, product)}
                  >
                    Edit
                  </a>
                )}

                {parseFloat(product.cart_item_promotion_discount) > 0 && (
                  <span className="member-discount-desc">
                    $ {product.cart_item_promotion_discount}{" "}
                    {product.cart_item_promotion_type} discount Applied
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="qty_bx">
                <span
                  className="qty_minus"
                  onClick={this.updateCartQty.bind(this, product, "decr")}
                >
                  -
                </span>
                <input
                  type="text"
                  className={
                    product.product_stock === null ||
                    parseInt(product.product_stock) === 0
                      ? "cart-no-stock-qty"
                      : ""
                  }
                  value={product.cart_item_qty}
                  readOnly
                />

                <span
                  className={
                    product.product_stock === null ||
                    parseInt(product.product_stock) === 0 ||
                    (parseInt(product.product_free) === 1 &&
                      parseInt(product.cart_item_free_prodcut_qty) <=
                        product.cart_item_qty)
                      ? "qty_plus disabled"
                      : "qty_plus"
                  }
                  onClick={this.updateCartQty.bind(this, product, "incr")}
                >
                  +
                </span>
              </div>

              <div className="cart_price">
                <p>${product.cart_item_total_price}</p>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  editCartItem(itemID) {
    this.setState({ edititemID: itemID }, () => {
      $.magnificPopup.open({
        items: {
          src: "#edit-warning-orderpopup",
        },
        type: "inline",
      });
    });
  }

  removeSelectedItem() {
    var edititemID = this.state.edititemID;
    if (edititemID) {
      this.props.deleteCartDetail(edititemID.cart_item_id);

      $.magnificPopup.close();
      $("body").css("overflow", "auto");
      if (this.props.prpSateValChange) {
        this.viewProDetail(edititemID.product_slug);
      }
      this.props.history.push(
        "/food/" +
          edititemID.outlet_slug +
          "/" +
          edititemID.pro_cate_slug +
          "/" +
          edititemID.pro_subcate_slug +
          "/" +
          edititemID.product_slug +
          "?edit=true"
      );
    }
  }
  goItem() {
    $.magnificPopup.close();
  }

  handleChangeItem = (event) => {
    var selectItemList = this.state.selectItemList;
    if (event.target.checked === true) {
      selectItemList.push(event.target.value);
    } else {
      const index = selectItemList.indexOf(event.target.value);
      selectItemList.splice(index, 1);
    }
    this.setState({ selectItemList: selectItemList }, function () {
      if (selectItemList.length > 0) {
        this.setState({ selectitem: true });
      } else {
        this.setState({ selectitem: false });
      }
    });
  };

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (+" + modVlPrice + ")" : "";
        temp_html +=
          "<p>" +
          modName +
          ":</p> <p>" +
          modval.toLowerCase() +
          newModVlPrice.toLowerCase() +
          "</p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p>" +
            comboName +
            ": </p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p>" +
            comboName +
            ":</p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboVal = this.showComboInCombo(combos[r]["set_menu_component"]);
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html +=
            "<p>" +
            comboQty +
            " X " +
            comboPro.toLowerCase() +
            newPrice +
            " " +
            comboVal.toLowerCase() +
            " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  showComboInCombo(combo) {
    var html = "";
    var comboLen = combo.length;
    for (var i = 0, length = comboLen; i < length; i++) {
      var comboName = combo[i]["menu_component_name"];
      var comboVal = this.showComboInComboProducts(combo[i]["product_details"]);

      html +=
        "<ul class='cart-combo-in-combo'><li>" +
        comboName +
        ": </li>" +
        comboVal +
        " " +
        "</ul> ";
    }
    return html;
  }

  showComboInComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_inner_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_inner_menu_component_product_name"];
          var comboQty = combos[r]["cart_inner_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_inner_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html += "<li>" + comboQty + " X " + comboPro + newPrice + " </li> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b> </p><p> " +
          modval.toLowerCase() +
          newModValPrice.toLowerCase() +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var totalItmCount = this.props.cartTotalItmCount;
    if (type === "incr") {
      if (
        itemArr.product_stock === null ||
        parseInt(itemArr.product_stock) === 0 ||
        (parseInt(itemArr.product_free) === 1 &&
          itemArr.cart_item_free_prodcut_qty < parseInt(cartQty) + 1)
      ) {
        return false;
      }
    }
    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(productId, cartItemId, cartQty);
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(productId, cartItemId, cartQty);
    }
    removePromoCkValue();
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
    removePromoCkValue();
  }

  clearCartItm(event) {
    if (typeof event !== undefined && typeof event !== "undefined") {
      event.preventDefault();
    }
    //if (this.state.selectItemList.length > 0) {
    showLoader("cart_table", "class");
    /* this.state.selectItemList.join(",") */
    this.props.destroyCartDetail();
    removePromoCkValue();
    //}
  }
  unSelectAll() {
    $(".tag_filter").prop("checked", false);
    this.setState({ selectitem: false });
  }
  openCart(openCartRistict) {
    if (openCartRistict === "No") {
      if ($("#totalitmcount").val() > 0) {
        var cartItemsArr = this.props.cartItems;
        var totaloutOfSKU = 0;
        if (Object.keys(cartItemsArr).length > 0) {
          Object.keys(cartItemsArr).map((index) => {
            Object.keys(cartItemsArr[index]).map((index1) => {
              Object.keys(cartItemsArr[index][index1]).map((index2) => {
                var cartpro = cartItemsArr[index][index1][index2];
                if (cartpro.length > 0) {
                  cartpro.map((product) => {
                    if (
                      product.product_stock === null ||
                      parseInt(product.product_stock) === 0
                    ) {
                      totaloutOfSKU++;
                    }
                  });
                }
              });
            });
          });
        }
        if (totaloutOfSKU > 0) {
          $.magnificPopup.open({
            items: {
              src: "#warning-not-avail-sku-popup",
            },
            type: "inline",
          });
        } else {
          $("body").addClass("cart-items-open");
          $(this).toggleClass("active");
          $(".hcart_dropdown").toggleClass("open");
        }
      }
    }
  }

  unavailableItems() {
    var cartItemsArr = this.props.cartItems;
    var outOfSKU = "";
    var totaloutOfSKU = 0;
    if (Object.keys(cartItemsArr).length > 0) {
      outOfSKU = Object.keys(cartItemsArr).map((index) => {
        return Object.keys(cartItemsArr[index]).map((index1) => {
          return Object.keys(cartItemsArr[index][index1]).map((index2) => {
            var cartpro = cartItemsArr[index][index1][index2];
            if (cartpro.length > 0) {
              return cartpro.map((product, proindex) => {
                if (
                  product.product_stock === null ||
                  parseInt(product.product_stock) === 0
                ) {
                  totaloutOfSKU++;
                  return (
                    <div className="cart_row product-details" key={proindex}>
                      <div className="row-replace">
                        <div className="col-sm-cls cart_left">
                          <div className="cart_info text-left">
                            <h4>
                              {stripslashes(product.cart_item_product_name)}
                            </h4>
                            {/* this.loadModifierItems(
                              product.cart_item_type,
                              product.modifiers,
                              product.set_menu_component
                            ) */}
                          </div>
                        </div>

                        <div className="col-sm-cls cart_right text-right">
                          <div className="qty_bx item_quantity">
                            {product.cart_item_qty}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              });
            }
          });
        });
      });
    }
    if (outOfSKU !== "" && totaloutOfSKU > 0) {
      return (
        <div className="alert_body unavailable-alert-body">
          <div className="unavailable-alert-inner">
            <div className="purple-round"> </div>
            <h4>#{totaloutOfSKU} items are unavailable</h4>
            <p>
              Some items in your cart are unavailable for your updated SPU date
              and selection.
            </p>
            <div className="button-full">
              <a
                href={void 0}
                onClick={this.openCartActive.bind(this)}
                className="button ghost-button"
              >
                Go back to cart
              </a>
            </div>
          </div>
          <div className="unavailable-list">
            <h5>Unavailable items ({totaloutOfSKU})</h5>
            <div className="unavailable-overflow">{outOfSKU}</div>
          </div>
        </div>
      );
    }
  }
  openCartActive() {
    $.magnificPopup.close();
    $("body").addClass("cart-items-open");
    $(this).toggleClass("active");
    $(".hcart_dropdown").toggleClass("open");
  }
  removeNoLongerItem() {
    var cartItemsArr = this.props.cartItems;
    var outOfSKUList = [];
    if (Object.keys(cartItemsArr).length > 0) {
      Object.keys(cartItemsArr).map((index) => {
        Object.keys(cartItemsArr[index]).map((index1) => {
          Object.keys(cartItemsArr[index][index1]).map((index2) => {
            var cartpro = cartItemsArr[index][index1][index2];
            if (cartpro.length > 0) {
              cartpro.map((product) => {
                if (
                  product.product_stock === null ||
                  parseInt(product.product_stock) === 0
                ) {
                  outOfSKUList.push(product.cart_item_id);
                }
              });
            }
          });
        });
      });
    }
    if (outOfSKUList.length > 0) {
      showLoader("cartaction_checkout", "class");
      this.props.deleteCartDetail(outOfSKUList.join(","));
      removePromoCkValue();
    }
  }
  opentDeliveryInfo() {
    $.magnificPopup.open({
      items: {
        src: "#warning-delivery-info-popup",
      },
      type: "inline",
    });
  }
  closeDeliveryWarning() {
    $.magnificPopup.close();
  }
  render() {
    var currenturl = window.location.href;
    var substring1 = "checkout";
    var openCartRistict =
      currenturl.includes(substring1) !== true ? "No" : "Yes";
    openCartRistict = "No";
    var currentSlug = this.props.match.path;

    return (
      <>
        <a
          href={void 0}
          className={
            "hcartdd_trigger"
            /* currenturl.includes(substring1) !== true
                ? "hcartdd_trigger"
                : "hcartdd_trigger_dis" */
          }
          title="Cart"
          id="cart_count_details"
          onClick={this.openCart.bind(this, openCartRistict)}
          data-totalitmcount={Object.keys(this.props.cartItems).length}
        >
          <input
            type="hidden"
            value={Object.keys(this.props.cartItems).length}
            id="totalitmcount"
          />

          <div className="cart-outer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mobile-cart-icon"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>

            <img className="cartw" src={cartwhite} />
            <span className="hcart_round">{this.props.cartTotalItmCount}</span>
          </div>
        </a>
        {this.cartDetailsList()}
        {/* currenturl.includes(substring1) !== true
            ? this.cartDetailsList()
            : "" */}
        <div
          className="custom_alertcls alert-success alert-dismissible1 success_hide"
          id="cart-success-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            type="button"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-success-msg">
            Great! Your item has been added to the cart
          </p>{" "}
        </div>
        {/* Warning Maximum Order Popup - start */}
        {!!this.state.edititemID && (
          <div
            id="edit-warning-orderpopup"
            className="white-popup mfp-hide popup_sec warning_popup edit-warning-popup"
          >
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="edit-alert-body">
                  <h4>Edit item</h4>
                  <p>
                    Your current item selection and customisation will be
                    cleared.
                  </p>
                  <div className="button-full">
                    <a
                      href={void 0}
                      onClick={this.removeSelectedItem.bind(this)}
                      className="button"
                    >
                      Continue
                    </a>
                  </div>
                  <a href={void 0} onClick={this.goItem.bind(this)}>
                    Go back to cart
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Warning Maximum Order Popup - end */}

        {/* Warning Not Available SKU Popup - start */}
        <div
          id="warning-not-avail-sku-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">{this.unavailableItems()}</div>
            </div>
          </div>
        </div>
        {/* Warning Available SKU Popup - end */}

        {/* Warning Not Available SKU Popup - start */}
        <div
          id="warning-delivery-info-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="edit-alert-body">
                {this.state.deliveryinfo}
                <div className="button-full">
                  <a
                    href={void 0}
                    onClick={this.closeDeliveryWarning.bind(this)}
                    className="button"
                  >
                    Ok, got it!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Available SKU Popup - end */}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var cartOutletList = 0;
  var cartOutlet = Array();
  var cartTotalItmCount = 0;
  var cartTotalIAmount = 0;
  var cartoutletavailability = Array();
  var siteLocID = "";
  var maxCart = 0;
  var cartStatus = "";
  var outletslug = Array();
  var outletFreeProduct = Array();

  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }

  var alloutletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0 && !outletsArr.length) {
    if (state.alloutlets[0].status === "ok") {
      outletsArr = state.alloutlets[0].result_set;
    }
  }

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartOutletList = resultSetArr.cartoutlet;
      cartOutlet = resultSetArr.outlets;
      outletslug = resultSetArr.outletslug;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      cartTotalIAmount = resultSetArr.cart_details.cart_grand_total;
      cartoutletavailability = resultSetArr.cartoutletavailability;
      siteLocID = resultSetArr.siteLocID;
      maxCart = resultSetArr.maxCart;
      outletFreeProduct = resultSetArr.outletFreeProduct;

      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  return {
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartOutletList: cartOutletList,
    cartOutlet: cartOutlet,
    siteLocID: siteLocID,
    outletslist: outletsArr,
    maxCart: maxCart,
    outletslug: outletslug,
    cartoutletavailability: cartoutletavailability,
    cartTotalItmCount: cartTotalItmCount,
    cartTotalIAmount: cartTotalIAmount,
    cartStatus: cartStatus,
    outletFreeProduct: outletFreeProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty) => {
      dispatch({ type: UPDATE_CART_DETAIL, productId, cartItemId, cartQty });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(CartSideBar));
