/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { connect } from "react-redux";
import StripeCheckout from "react-stripe-checkout";
import moment from "moment";
import axios from "axios";
var qs = require("qs");

import { format } from "date-fns";
var dateFormat = require("dateformat");

import arrow from "../../common/images/name-arrow.svg";
import round from "../../common/images/purple-round.svg";

import productImg from "../../common/images/no-img-product.png";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import OrderAdvancedDatetimeSlot from "../Layout/OrderAdvancedDatetimeSlot";
import ProductDetail from "../Products/ProductDetail";
import Promocode from "../Products/Promocode";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  pickupId,
  dineinId,
  CountryTxt,
  stripeReference,
  restaurantLink,
  shopType,
  headerconfig,
  cookieConfig,
  stripeCurrency,
  stripeImage,
  stripeCompany,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  getCalculatedAmount,
  showAlert,
  showCustomAlert,
  showCustomCenterAlert,
  getPromoCkValue,
  removePromoCkValue,
  smoothScroll,
  addressFormat,
  showToastrAlert,
} from "../Helpers/SettingHelper";
import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
  GET_ACTIVITYCOUNT,
  GET_ADDONPRODUCT,
  GET_LOGINDATA,
} from "../../actions";
var Parser = require("html-react-parser");

import noimage from "../../common/images/no-img-product.png";
import loadingImage from "../../common/images/loading_popup.gif";
import tickImage from "../../common/images/tick_popup.png";
import butterfly from "../../common/images/butterfly.svg";
import deliverinfo from "../../common/images/delivery-info.svg";
import paybycod from "../../common/images/member-pay.png";
import paybystripe from "../../common/images/pay-by-stripe.png";
import payby2c2p from "../../common/images/pay-by-2c2p.png";
import { type } from "jquery";

class Checkout extends Component {
  constructor(props) {
    super(props);

    /* Rounding to the nearest 15 minute interval */
    const start = moment();
    const remainder = 15 - (start.minute() % 15);
    const currentTime = moment(start).add(remainder, "minutes");
    /* To change the date after 2 PM */
    /*var CurrentDate = this.calcTime('Singapore', '+8');*/

    var CurrentDate = new Date();

    var avilablityId =
      cookie.load("defaultAvilablityId") != "" &&
      cookie.load("defaultAvilablityId") != undefined
        ? cookie.load("defaultAvilablityId")
        : "";
    var orderOutletId =
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
        ? cookie.load("orderOutletId")
        : "";
    var orderPostalCode =
      typeof cookie.load("orderPostalCode") === "undefined"
        ? ""
        : cookie.load("orderPostalCode");
    var orderDeliveryAddress =
      typeof cookie.load("orderDeliveryAddress") === "undefined"
        ? ""
        : cookie.load("orderDeliveryAddress");
    var unitNoOne =
      typeof cookie.load("unitNoOne") === "undefined"
        ? ""
        : cookie.load("unitNoOne");
    var unitNoTwo =
      typeof cookie.load("unitNoTwo") === "undefined"
        ? ""
        : cookie.load("unitNoTwo");

    var billingPostalCode =
      typeof cookie.load("billingPostalCode") === "undefined"
        ? ""
        : cookie.load("billingPostalCode");
    var billingDeliveryAddress =
      typeof cookie.load("billingDeliveryAddress") === "undefined"
        ? ""
        : cookie.load("billingDeliveryAddress");
    var billunitNoOne =
      typeof cookie.load("billunitNoOne") === "undefined"
        ? ""
        : cookie.load("billunitNoOne");
    var billunitNoTwo =
      typeof cookie.load("billunitNoTwo") === "undefined"
        ? ""
        : cookie.load("billunitNoTwo");

    var orderDateTime =
      typeof cookie.load("orderDateTime") === "undefined"
        ? ""
        : cookie.load("orderDateTime");
    var orderTAT =
      typeof cookie.load("orderTAT") === "undefined"
        ? ""
        : cookie.load("orderTAT");

    /* For Advanced Slot */
    var isAdvanced =
      typeof cookie.load("isAdvanced") === "undefined"
        ? ""
        : cookie.load("isAdvanced");
    var slotType =
      typeof cookie.load("slotType") === "undefined"
        ? ""
        : cookie.load("slotType");

    var getPromoCkVal = getPromoCkValue();
    var TodayDate = new Date();
    var deliveryDate =
      cookie.load("deliveryDate") !== "" &&
      typeof cookie.load("deliveryDate") !== undefined &&
      typeof cookie.load("deliveryDate") !== "undefined" &&
      cookie.load("deliveryDate") !== undefined
        ? cookie.load("deliveryDate")
        : "";
    var order_date_exist = "";
    if (deliveryDate !== "") {
      var delivery_Date = deliveryDate.split("/");
      TodayDate = new Date(new Date(TodayDate).setDate(delivery_Date[0]));
      TodayDate = new Date(new Date(TodayDate).setMonth(delivery_Date[1] - 1));
      TodayDate = new Date(new Date(TodayDate).setFullYear(delivery_Date[2]));

      order_date_exist = TodayDate;
    }
    var deliveryTime =
      cookie.load("deliveryTime") !== "" &&
      typeof cookie.load("deliveryTime") !== undefined &&
      typeof cookie.load("deliveryTime") !== "undefined" &&
      cookie.load("deliveryTime") !== undefined
        ? cookie.load("deliveryTime")
        : "";
    var order_time_exist = "";
    if (deliveryTime !== "") {
      var delivery_Time = deliveryTime.split(":");
      TodayDate = new Date(new Date(TodayDate).setHours(delivery_Time[0]));
      TodayDate = new Date(new Date(TodayDate).setMinutes(delivery_Time[1]));
      TodayDate = new Date(new Date(TodayDate).setSeconds(delivery_Time[2]));
      order_time_exist = TodayDate;
    }

    this.state = {
      UserId:
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== undefined &&
        typeof cookie.load("UserId") !== "undefined"
          ? cookie.load("UserId")
          : "",
      defaultAvilTy: avilablityId,
      globalSettings: [],
      staticblacks: [],
      overAllcart: [],
      cartItems: [],
      cartOutlet: [],
      cartDetails: [],
      productLeadtime: 0,
      outletTax: [],
      cartStatus: "",
      cartTotalItmCount: 0,
      order_postcode: orderPostalCode,
      order_delivery_address: orderDeliveryAddress,
      unitnumber1: unitNoOne,
      unitnumber2: unitNoTwo,
      billing_postcode: billingPostalCode,
      billing_delivery_address: billingDeliveryAddress,
      billunitnumber1: billunitNoOne,
      billunitnumber2: billunitNoTwo,
      billing_addrs_sameas: "yes",
      order_specil_note: "",
      showDateOnly: "yes",

      /* For Advanced Slot */
      getDateTimeFlg: "yes",
      seleted_ord_date: order_date_exist,
      seleted_ord_time: order_time_exist,
      seleted_ord_slot:
        cookie.load("orderSlotVal") !== "" &&
        typeof cookie.load("orderSlotVal") !== undefined &&
        typeof cookie.load("orderSlotVal") !== "undefined" &&
        cookie.load("orderSlotVal") !== undefined
          ? cookie.load("orderSlotVal")
          : "",
      seleted_ord_slotTxt:
        cookie.load("orderSlotTxt") !== "" &&
        typeof cookie.load("orderSlotTxt") !== undefined &&
        typeof cookie.load("orderSlotTxt") !== "undefined" &&
        cookie.load("orderSlotTxt") !== undefined
          ? cookie.load("orderSlotTxt")
          : "",
      seleted_ord_slot_str:
        cookie.load("orderSlotStrTime") !== "" &&
        typeof cookie.load("orderSlotStrTime") !== undefined &&
        typeof cookie.load("orderSlotStrTime") !== "undefined" &&
        cookie.load("orderSlotStrTime") !== undefined
          ? cookie.load("orderSlotStrTime")
          : "",
      seleted_ord_slot_end:
        cookie.load("orderSlotEndTime") !== "" &&
        typeof cookie.load("orderSlotEndTime") !== undefined &&
        typeof cookie.load("orderSlotEndTime") !== "undefined" &&
        cookie.load("orderSlotEndTime") !== undefined
          ? cookie.load("orderSlotEndTime")
          : "",
      isAdvanced: isAdvanced,
      slotType: slotType,

      activitycount_arr: [],
      promotion_count: 0,
      reward_point_count: 0,
      used_reward_point: getPromoCkVal["usedPoints"],
      promoOutlet: getPromoCkVal["promoOutlet"],
      promoType: getPromoCkVal["promoType"],
      promoPercentage: getPromoCkVal["promoPercentage"],
      reward_point_val: getPromoCkVal["reedemPointVal"],
      promo_code_val: getPromoCkVal["promoCodeVal"],
      promotion_applied: getPromoCkVal["promotionApplied"],
      promotion_type: getPromoCkVal["promotionType"],
      promotion_amount: getPromoCkVal["promotionAmount"],
      promotion_source: getPromoCkVal["promotionSource"],
      promoIs_delivery: getPromoCkVal["promoIsDelivery"],
      paymentmodevalue: "Stripe",
      validateimage: loadingImage,
      placingorderimage: loadingImage,
      completingpaymentimage: loadingImage,
      orderValidFail: 0,
      cod_payment_enable: 0,
      stripe_payment_enable: 0,
      postorder_triggered: "no",
      chk_tarms_contn: "No",
      termsAndConditions: "",
      seletedAvilablityId: avilablityId,
      defaultAvilTy: avilablityId,
      seletedOutletId: orderOutletId,
      order_tat_time: orderTAT,
      viewProductSlug: "",
      cardImage: "",
      payment_ref_id: "",
      two_c_two_p_payment_enable: 0,
      two_c_two_p_merchant_id: "",
      two_c_two_p_secret_key: "",
      two_c_two_p_order_id: "",
      cutlery: "Yes",
      outletTat: 15,
      placeOrderEnable: 0,
      twoCtwoPPayment_loading: false,
      pendingGroupList: [],
      removeGroupID: "",
      order_customer_send_gift: "No",
      recipient_name: "",
      recipient_contact_no: "",
      order_recipient_email: "",
      gift_message: "",
      openCart: false,
      trgavliblpopupFlg: "no",
      changeAddresspopupFlg: "no",
      customer_first_name: cookie.load("UserFname"),
      customer_last_name:
        cookie.load("UserLname") !== "" &&
        typeof cookie.load("UserLname") !== undefined &&
        typeof cookie.load("UserLname") !== "undefined"
          ? cookie.load("UserLname")
          : "",
      customer_phone: cookie.load("UserMobile"),
      customer_email: cookie.load("UserEmail"),
      TotalPointsBAL: 0,
      cartNewItem: [],
    };

    this.getActivityCounts();
    this.loadAscentisPoints();
    this.props.getAddonProList(orderOutletId);
  }

  /* Convert Timezone */
  calcTime(city, offset) {
    var d = new Date();
    var utc = d.getTime() + d.getTimezoneOffset() * 60000;
    var nd = new Date(utc + 3600000 * offset);
    return nd;
  }

  changeBillingAddrChk() {
    var isSame = this.state.billing_addrs_sameas;
    if (isSame === "yes") {
      var tempvlu = "";
      this.setState({
        billing_postcode: tempvlu,
        billing_delivery_address: tempvlu,
        billunitnumber1: tempvlu,
        billunitnumber2: tempvlu,
        billing_addrs_sameas: "no",
      });
    } else {
      this.setState(
        { billing_addrs_sameas: "yes" },
        function () {
          this.setBillingAddrs();
        }.bind(this)
      );
    }
  }
  changeGiftChk() {
    var enableGift = this.state.order_customer_send_gift;
    if (enableGift === "Yes") {
      this.setState({ order_customer_send_gift: "No" });
    } else {
      this.setState({ order_customer_send_gift: "Yes" });
    }
  }
  changeCutlery(e) {
    this.setState({ cutlery: e.target.value });
  }

  setBillingAddrs() {
    var isSame = this.state.billing_addrs_sameas;
    if (isSame === "yes") {
      var postalCode = this.state.order_postcode;
      var custAddress = this.state.order_delivery_address;
      var floorNo = this.state.unitnumber1;
      var unitNo = this.state.unitnumber2;
      this.setState({
        billing_postcode: postalCode,
        billing_delivery_address: custAddress,
        billunitnumber1: floorNo,
        billunitnumber2: unitNo,
      });
    }
  }

  CheckBillingAddrChk(type) {
    var isSame = this.state.billing_addrs_sameas;
    var chkBox = false;
    var diplayTxt = "block";
    if (isSame === "yes") {
      chkBox = true;
      diplayTxt = "none";
    }

    return type === "checkbox" ? chkBox : diplayTxt;
  }

  handleAddrChange(event) {
    if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, cookieConfig);
      this.setState(
        { unitnumber1: event.target.value },
        function () {
          this.setBillingAddrs();
        }.bind(this)
      );
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, cookieConfig);
      this.setState(
        { unitnumber2: event.target.value },
        function () {
          this.setBillingAddrs();
        }.bind(this)
      );
    } else if (event.target.name === "specil_note") {
      this.setState({ order_specil_note: event.target.value });
    } else if (event.target.name === "reward_point") {
      this.setState({ reward_point_val: event.target.value });
    } else if (event.target.name === "promo_code") {
      this.setState({ promo_code_val: event.target.value });
    } else if (event.target.name === "bill_unit_no1") {
      this.setState({ billunitnumber1: event.target.value });
    } else if (event.target.name === "bill_unit_no2") {
      this.setState({ billunitnumber2: event.target.value });
    } else if (event.target.name === "customer_first_name") {
      this.setState({ customer_first_name: event.target.value });
    } else if (event.target.name === "customer_phone") {
      this.setState({ customer_phone: event.target.value });
    } else if (event.target.name === "customer_email") {
      this.setState({ customer_email: event.target.value });
    } else if (event.target.name === "gift_message") {
      this.setState({ gift_message: event.target.value });
    }
  }

  /* Validate Float Value */
  validateFloatval(e) {
    const re = /[0-9.]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* Validate Int Value */
  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* post code */
  changePostalCode(isFrm, event) {
    var postalCode = event.target.value;
    var postCdLth = postalCode !== "" ? postalCode.length : 0;

    if (postalCode !== "" && parseInt(postCdLth) === 6) {
      var urlShringTxt =
        apiUrl +
        "outlets/find_outlet?app_id=" +
        appId +
        "&availability_id=" +
        deliveryId +
        "&postal_code=" +
        postalCode;

      axios.get(urlShringTxt, headerconfig).then((res) => {
        var custAddress = "";
        if (res.data.status === "ok") {
          var outltResulSet = res.data.result_set;
          if (Object.keys(outltResulSet.postal_code_information).length > 0) {
            var outletAddress =
              outltResulSet.postal_code_information.zip_buno != ""
                ? outltResulSet.postal_code_information.zip_buno +
                  ", " +
                  outltResulSet.postal_code_information.zip_sname
                : outltResulSet.postal_code_information.zip_sname;

            custAddress = outletAddress + " " + CountryTxt;
          }
        }

        this.setState({
          billing_postcode: postalCode,
          billing_delivery_address: custAddress,
        });
      });
    } else {
      var temparrs = "";
      this.setState({
        billing_postcode: postalCode,
        billing_delivery_address: temparrs,
      });
    }
  }

  loadAscentisPoints() {
    if (
      cookie.load("ascentis_memberid") !== "" &&
      typeof cookie.load("ascentis_memberid") !== undefined &&
      typeof cookie.load("ascentis_memberid") !== "undefined"
    ) {
      var postObject = {
        app_id: appId,
        member_id: cookie.load("ascentis_memberid"),
        customer_id: this.state.UserId,
      };

      axios
        .post(
          apiUrl + "ascentiscrm/memberEnquiry",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          if (res.data.status === "ok") {
            var result = res.data.result_set;
            if (
              result.CardLists !== "" &&
              typeof result.CardLists !== undefined &&
              typeof result.CardLists !== "undefined"
            ) {
              var CardLists = result.CardLists;
              if (CardLists.length > 0) {
                if (
                  CardLists[0].TotalPointsBAL !== "" &&
                  parseFloat(CardLists[0].TotalPointsBAL) > 0 &&
                  typeof CardLists[0].TotalPointsBAL !== undefined &&
                  typeof CardLists[0].TotalPointsBAL !== "undefined"
                ) {
                  var TotalPointsBAL = CardLists[0].TotalPointsBAL;

                  this.setState({
                    TotalPointsBAL: TotalPointsBAL,
                  });
                }
              }
            }
          }
        });
    }
  }

  /* Get Redeem Points Count History Details */
  getActivityCounts() {
    const inputKeys = ["promotionwithoutuqc", "reward", "testtxt"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  /* componentWillMount() {
    this.props.getCartDetail();
  } */

  componentWillReceiveProps(nextProps) {
    $("body").removeClass("cart-items-open");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");

    if (this.state.globalSettings !== nextProps.settingsArr) {
      var tampStArr = nextProps.settingsArr;
      var paymentmode = "Stripe";
      var stripePayment = 0;
      var codPayment = 0;
      var twoCtwoPPayment = 0;
      if (Object.keys(tampStArr).length > 0) {
        if (
          tampStArr.client_cod_enable == 1 &&
          tampStArr.client_cod_availability == 1
        ) {
          codPayment = 1;
          if (cookie.load("userLoginFrom") !== "guest") {
            paymentmode = "Cash";
          }
        }
        if (
          tampStArr.client_stripe_enable == 1 &&
          tampStArr.client_stripe_availability == 1
        ) {
          stripePayment = 1;
        }

        if (
          tampStArr.client_two_c_two_p_enable == 1 &&
          tampStArr.client_two_c_two_p_availability == 1
        ) {
          twoCtwoPPayment = 1;
          if (cookie.load("userLoginFrom") === "guest") {
            paymentmode = "2C2P";
          }
        }
      }

      this.setState({
        globalSettings: nextProps.settingsArr,
        cod_payment_enable: codPayment,
        paymentmodevalue: paymentmode,
        stripe_payment_enable: stripePayment,
        two_c_two_p_payment_enable: twoCtwoPPayment,
      });
    }

    if (this.state.activitycount_arr !== nextProps.activitycountArr) {
      this.setState({
        activitycount_arr: nextProps.activitycountArr,
        promotion_count: nextProps.activitycountArr.promotionwithoutuqc,
        reward_point_count: nextProps.activitycountArr.reward_ponits,
      });
    }

    if (
      this.state.cartNewItem !== nextProps.cartItems &&
      Object.keys(nextProps.cartItems).length > 0
    ) {
      this.setState({ cartNewItem: nextProps.cartItems }, function () {
        var productList = [];

        if (Object.keys(nextProps.cartItems).length > 0) {
          Object.keys(nextProps.cartItems).map((index2) => {
            Object.keys(nextProps.cartItems[index2]).map((index1) => {
              Object.keys(nextProps.cartItems[index2][index1]).map((index) => {
                let cartproductList =
                  nextProps.cartItems[index2][index1][index];
                var row = cartproductList.map(
                  function (item, i) {
                    productList.push({
                      name: item.cart_item_product_name,
                      id: item.cart_item_product_id,
                      price: item.cart_item_total_price,
                      brand: item.outlet_name,
                      category: item.pro_cate_name,
                      variant: "",
                      quantity: item.cart_item_qty,
                    });
                  }.bind(this)
                );
              });
            });
          });
        }
      });
    }

    if (this.state.overAllcart !== nextProps.overAllcart) {
      this.setState(
        {
          overAllcart: nextProps.overAllcart,
          cartItems: nextProps.cartItems,
          cartOutlet: nextProps.cartOutlet,
          cartDetails: nextProps.cartDetails,
          outletTax: nextProps.cartTax,
          cartStatus: nextProps.cartStatus,
          cartTotalItmCount: nextProps.cartTotalItmCount,
          productLeadtime: nextProps.productLeadtime,
        },
        function () {}
      );
    }

    if (nextProps.staticblack !== this.state.staticblacks) {
      var termsConditions = "";
      if (Object.keys(nextProps.staticblack).length > 0) {
        nextProps.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "terms-and-conditions") {
            termsConditions = data.staticblocks_description;
            return "";
          }
        });
      }
      termsConditions =
        termsConditions !== "" ? Parser(termsConditions) : termsConditions;
      this.setState({
        staticblacks: nextProps.staticblack,
        termsAndConditions: termsConditions,
      });

      setTimeout(function () {
        $(".checkout-terms-and-condition").mCustomScrollbar();
      }, 800);
    }
    if (nextProps.outletTat !== this.state.outletTat) {
      this.setState({
        outletTat: nextProps.outletTat,
        order_tat_time: nextProps.outletTat,
        getDateTimeFlg: "yes",
      });
    }
  }

  componentDidMount() {
    /* showAlert("Alert", "Please check the order before submitting", 'Yes', $.magnificPopup);

    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    }); */

    smoothScroll(100, 70);

    if (cookie.load("triggerTimeSlotPopup") === "yes") {
      cookie.remove("triggerTimeSlotPopup", cookieConfig);
      this.selectTimeSlotPopup();
    }
  }

  componentDidUpdate() {
    this.checkOutAuthentication();
    var modevalue = this.state.paymentmodevalue;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      this.props.maxCart,
      this.state.cartItems,
      this.state.outletTax
    );
    var subTotalAmount = parseFloat(calculatedAmount["cartSubTotalAmount"]);
    var grandTotalAmount = parseFloat(calculatedAmount["grandTotalAmount"]);
    if (subTotalAmount > 0 && grandTotalAmount === 0) {
      modevalue = "promotion";
    }
    if (this.state.paymentmodevalue !== modevalue) {
      this.setState({ paymentmodevalue: modevalue });
    }

    var cartDetailsArr = this.state.cartDetails;
    var settingsArr = this.state.globalSettings;
    if (
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined &&
      this.state.customer_phone != "" &&
      this.state.customer_phone != undefined &&
      Object.keys(cartDetailsArr).length > 0 &&
      Object.keys(settingsArr).length > 0 &&
      this.state.postorder_triggered === "no"
    ) {
      this.setState(
        { postorder_triggered: "yes" /*  getDateTimeFlg: "yes" */ },
        function () {
          /*2C2P success check*/

          var urlParams = new URLSearchParams(this.props.location.search);
          if (
            urlParams.get("two_c_two_p_status") == "success" &&
            urlParams.get("two_c_two_p_order_id") != ""
          ) {
            /*this.showSuccessPage(urlParams.get('local_order_no'));*/
            this.setState({
              two_c_two_p_order_id: urlParams.get("two_c_two_p_order_id"),
            });

            //this.postOrder(3, "No", "", "2C2P");
          } else {
            //this.postOrder("initial", "Yes");
          }
        }.bind(this)
      );
    }

    var sltdOrdDate = this.state.seleted_ord_date;
    var sltdOrdTime = this.state.seleted_ord_time;
    if (sltdOrdDate !== "" && sltdOrdTime !== "") {
      this.updateOrderDateTimeCookie();
    }
  }

  /* For Advanced Slot */
  updateOrderDateTimeCookie() {
    var sltdOrdDate = this.state.seleted_ord_date;
    var sltdOrdTime = this.state.seleted_ord_time;
    var OrdDate = format(sltdOrdDate, "yyyy-MM-dd");
    var OrdHours = sltdOrdTime.getHours();
    var OrdMunts = sltdOrdTime.getMinutes();
    var OrdSecnd = sltdOrdTime.getSeconds();
    var ordDateTime = new Date(OrdDate);
    ordDateTime.setHours(OrdHours);
    ordDateTime.setMinutes(OrdMunts);
    ordDateTime.setSeconds(OrdSecnd);

    var deliveryDate = format(sltdOrdDate, "dd/MM/yyyy");
    var deliveryTime =
      this.pad(OrdHours) + ":" + this.pad(OrdMunts) + ":" + this.pad(OrdSecnd);

    cookie.save("orderDateTime", ordDateTime, cookieConfig);
    cookie.save("deliveryDate", deliveryDate, cookieConfig);
    cookie.save("deliveryTime", deliveryTime, cookieConfig);

    var isAdvanced = this.state.isAdvanced;
    var slotType = this.state.slotType;
    var orderSlotVal = "",
      orderSlotTxt = "",
      orderSlotStrTime = "",
      orderSlotEndTime = "";
    if (slotType === "2") {
      orderSlotVal = this.state.seleted_ord_slot;
      orderSlotTxt = this.state.seleted_ord_slotTxt;
      orderSlotStrTime = this.state.seleted_ord_slot_str;
      orderSlotEndTime = this.state.seleted_ord_slot_end;
    }

    cookie.save("orderSlotVal", orderSlotVal, cookieConfig);
    cookie.save("orderSlotTxt", orderSlotTxt, cookieConfig);
    cookie.save("orderSlotStrTime", orderSlotStrTime, cookieConfig);
    cookie.save("orderSlotEndTime", orderSlotEndTime, cookieConfig);
  }

  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  checkOutAuthentication() {
    var orderOutletId =
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
        ? cookie.load("orderOutletId")
        : "";
    var avilablityId = this.state.defaultAvilTy;
    /* orderOutletId === "" ||  */
    if (avilablityId === "") {
      //showAlert("Error", "Your cart is now empty");
      cookie.save("ordertypepopupTrigger", "yes", cookieConfig);
      /* $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      }); */
      this.props.history.push("/");
      return false;
    }

    if (avilablityId !== "") {
      if (avilablityId === deliveryId) {
        if (this.state.order_postcode === "") {
          cookie.save("changeaddress", "yes", cookieConfig);
          cookie.save("checkoutredirect", "yes", cookieConfig);

          this.props.history.push("/");
          return false;
        } else if (
          cookie.load("orderZoneId") === "" ||
          cookie.load("orderZoneId") === null
        ) {
          cookie.save("changeaddress", "yes", cookieConfig);
          cookie.save("checkoutredirect", "yes", cookieConfig);
          this.props.history.push("/");
          return false;
        }
      }
    }

    var cartDetailsArr = this.state.cartDetails;
    var settingsArr = this.state.globalSettings;
    var zonedetailsArr = this.props.zonedetails;

    if (this.state.cartStatus === "failure") {
      /*   showAlert("Error", "Your cart is now empty");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      }); */
      this.props.history.push("/restaurants");
      return false;
    }

    if (
      this.state.cartStatus === "success" &&
      Object.keys(cartDetailsArr).length > 0 &&
      Object.keys(zonedetailsArr).length > 0
    ) {
      var cartMinAmount =
        zonedetailsArr[0].zone_min_amount !== ""
          ? parseFloat(zonedetailsArr[0].zone_min_amount)
          : 0;

      var cartSubTotal = parseFloat(cartDetailsArr.cart_sub_total);
      var balanceAmt =
        parseFloat(cartMinAmount) - parseFloat(cartDetailsArr.cart_sub_total);

      var errorMsg =
        "Your cart has not yet met the minimum order of $" +
        cartMinAmount +
        ". You are currently $" +
        balanceAmt.toFixed(2) +
        " away.";

      if (
        this.state.defaultAvilTy === deliveryId &&
        cartSubTotal < cartMinAmount
      ) {
        $("#minimum_amt_txt").html(errorMsg);
        //  showAlert("Minimum order not met!", errorMsg);
        if ($("#warning-min-order").length > 0) {
          $.magnificPopup.open({
            items: {
              src: "#warning-min-order",
            },
            type: "inline",
          });
        }

        this.props.history.push("/restaurants");
        return false;
      }
    }
    if (this.state.UserId === "") {
      cookie.save("checkoutredirect", "yes", cookieConfig);
      const tableNo = cookie.load("orderTableNo");
      if (tableNo) {
        var qs = require("qs");
        var postObject = {
          app_id: appId,
          type: "web",
          logintype: "member",
          login_username: tableNo,
          table_number: tableNo,
        };
        this.props.getLoginData(qs.stringify(postObject));
        this.props.history.push("/");
        return false;
      }

      cookie.save("loginpopupTrigger", "login", cookieConfig);
      this.props.history.push("/");
      return false;
    }

    var UserMobile =
      this.state.customer_phone != "" && this.state.customer_phone != undefined
        ? this.state.customer_phone
        : "";
    if (this.state.UserId !== "" && UserMobile === "") {
      showAlert("Error", "Please update your mobile no.");
      if ($(".alert_popup").length > 0) {
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }

      this.props.history.push("/refpage/home");
      return false;
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "change_avlpoup") {
      this.setState({ trgavliblpopupFlg: value });
    }
    if (field === "change_addresspoup") {
      this.setState({ changeAddresspopupFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  openCart() {
    var openCart = !this.state.openCart;
    if (openCart === true) {
      $(".checkout-right-body-section .cart_body").slideDown();
    } else {
      $(".checkout-right-body-section .cart_body").slideUp();
    }

    this.setState({ openCart: openCart });
  }

  addNewPromo(e) {
    e.preventDefault();
    if ($("#open-promo-popup").length > 0) {
      $.magnificPopup.open({
        items: {
          src: "#open-promo-popup",
        },
        type: "inline",
      });
    }
  }

  loadCartOverallData() {
    var cartDetailsArr = this.state.cartDetails;

    if (Object.keys(cartDetailsArr).length > 0) {
      $("#dvLoading").fadeOut(1000);

      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        cartDetailsArr,
        promoTionArr,
        this.props.maxCart,
        this.state.cartItems,
        this.state.outletTax
      );
      var promotionType = this.state.promotion_type;
      var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(
        2
      );
      return (
        <div className="checkout-right-body-main">
          <div className="checkout-right-body-section">
            <div className="cart_table">
              <div className="product_orders_top">
                <h5>Your Items</h5>
                <div className="product_modify_cart add_item">
                  <a
                    href={`/food/${
                      this.state.seletedOutletId && this.state.overAllcart
                        ? this.state.overAllcart.outletslug?.[
                            this.state.seletedOutletId
                          ]
                        : "the-palms"
                    }`}
                    className="hclear_cart"
                    title="Add Items"
                  >
                    Add Items
                  </a>
                </div>
                <div className="product_modify_cart">
                  <a
                    href="/"
                    onClick={this.clearCartItm.bind(this)}
                    className="hclear_cart"
                    title="CLEAR CART"
                  >
                    Clear Cart
                  </a>
                </div>
              </div>

              <div className="cart_body ckout-lhs-bdy">
                {this.loadCartList()}
              </div>
            </div>
          </div>

          {/* redeem div - start */}
          <div className="redeem">
            <div className="redeem-row">
              {
                <div className="redeem-col redeem-col-right">
                  <div className="redeem-item-top">
                    <h4>Promo Code</h4>
                    {parseFloat(this.state.promotion_count) > 0 ? (
                      <Link to={"/mypromotions"} className="points">
                        You have <b>{this.state.promotion_count} Promotions</b>{" "}
                        available
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="redeem-item">
                    <div className="redeem-item-hea">
                      <div className="redeem-item-hea-inner">
                        {/* <span>Apply your promo code here</span> */}
                      </div>

                      <div
                        className={
                          this.state.promo_code_val != ""
                            ? "focus-out focused"
                            : "focus-out"
                        }
                      >
                        <input
                          placeholder="Enter Your Promo Code Here"
                          name="promo_code"
                          value={this.state.promo_code_val}
                          id="promocodeValue"
                          type="text"
                          className="form-control input-focus"
                          onChange={this.handleAddrChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="redeem_apply focus-out applypromo_cls">
                      {this.state.promotion_applied === "Yes" &&
                      this.state.promotion_type === "promoCode" ? (
                        <button
                          className="btn btn_minwid promobtn applyPromo"
                          onClick={this.removePointsAndPromo.bind(
                            this,
                            "fromclk"
                          )}
                        >
                          Remove
                        </button>
                      ) : (
                        <button
                          className="btn btn_minwid promobtn applyPromo"
                          onClick={this.applyPointsAndPromo.bind(
                            this,
                            "promoCode"
                          )}
                        >
                          Apply Now
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          {/* redeem div - end */}
          <div className="cart_footer">
            <div className="cart_row-full">
              <div className="cart_row">
                <div className="row-replace">
                  <div className="col-sm-cls text-left">
                    <p>Subtotal</p>
                  </div>
                  <div className="col-sm-cls text-right">
                    <span>${calculatedAmount["cartSubTotalAmount"]}</span>
                  </div>
                </div>
              </div>
              {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p>
                        Delivery fee{" "}
                        {calculatedAmount["zoneName"] !== ""
                          ? "(" + calculatedAmount["zoneName"] + ")"
                          : ""}{" "}
                      </p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        $
                        {parseFloat(calculatedAmount["deliveryCharge"]).toFixed(
                          2
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p>Additional Delivery</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        $
                        {parseFloat(
                          calculatedAmount["additionalDelivery"]
                        ).toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {parseFloat(calculatedAmount["orderGstAmount"]) > 0 && (
                <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p>GST ({calculatedAmount["orderDisplayGst"]} %)</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        $
                        {parseFloat(calculatedAmount["orderGstAmount"]).toFixed(
                          2
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="sidebar-discount">
              {(parseFloat(calculatedAmount["promotionAmount"]) > 0 ||
                cookie.load("promoIsDelivery") === "Yes") && (
                <div className="sidebar-discount-inner">
                  <div className="sidebar-discount-lhs">
                    <span>{cookie.load("promotionSource")}</span>
                    <a
                      href={void 0}
                      onClick={this.removePointsAndPromo.bind(this, "fromclk")}
                    >
                      Remove
                    </a>
                    {cookie.load("promoTitle") !== "" &&
                      typeof cookie.load("promoTitle") !== undefined &&
                      typeof cookie.load("promoTitle") !== "undefined" && (
                        <p>{cookie.load("promoTitle")}</p>
                      )}
                  </div>
                  {cookie.load("promoIsDelivery") !== "Yes" && (
                    <div className="sidebar-discount-rhs">
                      -$
                      {parseFloat(calculatedAmount["promotionAmount"]).toFixed(
                        2
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="reward-total">
              <div className="cart_row grant-total-cls">
                <div className="row-replace">
                  <div className="col-sm-cls text-left">
                    <p>Total</p>
                  </div>
                  <div className="col-sm-cls text-right">
                    <span>
                      <sup>$</sup>
                      {calculatedAmount["grandTotalAmount"]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
              <div className="member-discount-total">
                * {cartDetailsArr.cart_special_discount_type} $
                {cartDetailsArr.cart_special_discount} Applied
              </div>
            )}
          </div>
          <div className="progress_bar_div">
            {cookie.load("defaultAvilablityId") === deliveryId &&
              this.loadDeliveryPercentageBar()}
          </div>

          {/* chk-payment-btn div - end */}
          {/* chk-payment div - end */}
          <div className="chk-payment">
            <div className="chk-payment-title">
              {appId == "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2" &&
              cookie.load("defaultAvilablityId") === dineinId ? (
                <h3>Pay at the counter | We are cashless</h3>
              ) : (
                <h3>Payment Details</h3>
              )}
            </div>
            <div className="chk-payment-row">
              <div className="chk-payment-col">
                <form action="#">
                  {this.state.paymentmodevalue !== "promotion" &&
                    parseFloat(payAmount) > 0 && (
                      <ul className="chk-payment-col-radio">
                        {this.state.cod_payment_enable === 1 &&
                          cookie.load("userLoginFrom") !== "guest" && (
                            <li className="custom_checkbox">
                              <input
                                type="radio"
                                defaultChecked={
                                  this.state.paymentmodevalue == "Cash"
                                    ? "checked"
                                    : ""
                                }
                                id="cash"
                                value="Cash"
                                onClick={this.choosePayment.bind(this, "Cash")}
                                name="radio-group"
                              />
                              <div className="radio_con">
                                <span>Pay later</span>

                                <img
                                  src={paybycod}
                                  alt="Pay Via Membership"
                                  style={{ opacity: "0" }}
                                />
                              </div>
                            </li>
                          )}

                        {this.state.stripe_payment_enable === 1 && (
                          <li className="custom_checkbox">
                            <input
                              type="radio"
                              defaultChecked={
                                this.state.paymentmodevalue == "Stripe"
                                  ? "checked"
                                  : ""
                              }
                              id="card"
                              value="Stripe"
                              onClick={this.choosePayment.bind(this, "Stripe")}
                              name="radio-group"
                            />

                            <div className="radio_con">
                              <span>Credit / Debit Card</span>
                              <img src={paybystripe} alt="Pay By Stripe" />
                            </div>
                          </li>
                        )}
                        {this.state.two_c_two_p_payment_enable === 1 &&
                          cookie.load("userLoginFrom") === "guest" && (
                            <li>
                              <input
                                type="radio"
                                defaultChecked={
                                  this.state.paymentmodevalue == "2C2P"
                                    ? "checked"
                                    : ""
                                }
                                id="two_c_two_pcard"
                                onClick={this.choosePayment.bind(this, "2C2P")}
                                value="2C2P"
                                name="radio-group"
                              />
                              <div className="radio_con">
                                <span>Credit / Debit Card (2C2P)</span>
                                <img src={payby2c2p} alt="Pay By 2C2P" />
                              </div>
                            </li>
                          )}
                      </ul>
                    )}

                  {this.state.paymentmodevalue === "promotion" &&
                    parseFloat(payAmount) === 0 &&
                    subTotalAmount > 0 && (
                      <ul className="chk-payment-col-radio promotion-ul-topcls">
                        <li className="custom_checkbox">
                          <input
                            type="radio"
                            defaultChecked={
                              this.state.paymentmodevalue == "promotion"
                                ? "checked"
                                : ""
                            }
                            value="promotion"
                            id="promotion"
                            name="radio-group"
                          />
                          <label htmlFor="promotion"></label>
                          <div className="radio_con">Promotion</div>
                        </li>
                      </ul>
                    )}
                </form>

                <div
                  id="otherpayment-popup"
                  className="white-popup mfp-hide popup_sec login-popup"
                >
                  <div className="pop-whole">
                    <div className="inside-dine-popup-rhs">
                      <div className="pop-whole-lhs-inner home-popup-head">
                        {this.state.qrCode}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* chk-payment-btn div - end */}
              <div className="chk-payment-btn">
                {this.state.paymentmodevalue !== "promotion" &&
                  parseFloat(payAmount) > 0 && (
                    <div className="chk-payment-btn-row">
                      {this.state.paymentmodevalue === "Cash" &&
                        this.state.cod_payment_enable === 1 && (
                          <a
                            className="button cash_checkout_btn"
                            title="Checkout Now"
                            onClick={this.payCash.bind(this)}
                            disabled={this.state.isCashButtonDisabled}
                          >
                            Place Order
                          </a>
                        )}
                      {/* Offline Payment */}
                      {this.state.globalSettings.stripe_public_key &&
                        cookie.load("UserMobile") != "" &&
                        this.state.paymentmodevalue === "Stripe" &&
                        this.state.stripe_payment_enable === 1 && (
                          <a
                            href="/"
                            onClick={this.orderPayValidaion.bind(
                              this,
                              "Stripe"
                            )}
                            className="button"
                            title="Continue"
                          >
                            Checkout Now
                          </a>
                        )}
                      {this.state.globalSettings
                        .client_two_c_two_p_merchant_id != "" &&
                        this.state.customer_phone != "" &&
                        this.state.paymentmodevalue == "2C2P" &&
                        this.state.client_two_c_two_p_secret_key != "" &&
                        this.state.two_c_two_p_payment_enable === 1 && (
                          <a
                            href="/"
                            onClick={this.orderPayValidaion.bind(this, "2C2P")}
                            className="button"
                            title="Continue"
                          >
                            Proceed to pay via credit card
                          </a>
                        )}
                    </div>
                  )}

                {this.state.paymentmodevalue === "promotion" &&
                  parseFloat(payAmount) === 0 &&
                  subTotalAmount > 0 && (
                    <div className="chk-payment-btn-row">
                      <a
                        href="/"
                        className="button"
                        title="Continue"
                        onClick={this.postPromotionOrder.bind(this)}
                      >
                        Place Order
                      </a>
                    </div>
                  )}
                {/* <div className="continue-btn-row">
                      <a
                    href={`/food/${this.state.seletedOutletId && this.state.overAllcart ? this.state.overAllcart.outletslug?.[this.state.seletedOutletId] : 'the-palms'}`}
                        title="Continue"
                      >
                        Continue Shopping
                      </a>
                  </div> */}
              </div>
            </div>

            {/* chk-payment div - end */}
          </div>
          {/* chk-payment-btn div - end */}
        </div>
      );
    } else {
      return "";
    }
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentageBar() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartDetailsArr = this.state.cartDetails;

    if (Object.keys(cartDetailsArr).length > 0) {
      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;

      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        cartDetailsArr,
        promoTionArr,
        this.props.maxCart,
        this.state.cartItems,
        this.state.outletTax
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div free-delivery">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">${remainAmnt} more to FREE delivery!</p>
          </div>
        </div>
      );
    }
  }

  /* this  function used to load minimum order amount percentage */
  loadMinCartPercentage() {
    var min_amount,
      subTotal,
      balance,
      percentage = 0;
    var cartDetailsArr = this.state.cartDetails;

    if (Object.keys(cartDetailsArr).length > 0) {
      var globalSettings = this.state.globalSettings;
      min_amount = parseFloat(globalSettings.pos_min_amount);
      subTotal = parseFloat(cartDetailsArr.cart_sub_total);
      percentage = (subTotal * 100) / min_amount;
      balance = parseFloat(min_amount - subTotal);
      percentage = Math.round(percentage);
    }
    if (
      balance > 0 &&
      min_amount > 0 &&
      min_amount > subTotal &&
      percentage > 0
    ) {
      balance = balance.toFixed(2);

      return (
        <div className="cart_row progress_bar_div minimum-order-amount">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: percentage + "%" }}
              />
            </div>
            <p className="help-block">${balance} more to min order amount</p>
          </div>
        </div>
      );
    }
  }

  loadCartList() {
    var cartItemsArr = this.props.cartItems;
    var cartOutletArr = this.props.cartOutlet;
    var cartOutletslug = this.props.cartOutletslug;
    var cartGroups = this.props.cartGroups;
    if (Object.keys(cartItemsArr).length > 0) {
      return Object.keys(cartItemsArr).map((index) => {
        return (
          <div className="cart_outlet_row" key={index}>
            {Object.keys(cartItemsArr[index]).map((index1) => {
              let letOutleName =
                cartOutletArr[index1] !== "" &&
                typeof cartOutletArr[index1] !== undefined &&
                typeof cartOutletArr[index1] !== "undefined"
                  ? cartOutletArr[index1]
                  : "";
              let letOutleslug =
                cartOutletslug[index1] !== "" &&
                typeof cartOutletslug[index1] !== undefined &&
                typeof cartOutletslug[index1] !== "undefined"
                  ? cartOutletslug[index1]
                  : "";
              return (
                <div key={index1}>
                  <div className="cart-outlet-name">
                    <Link to={"/" + restaurantLink + "/" + letOutleslug}>
                      {letOutleName} <img src={arrow} />
                    </Link>
                  </div>
                  {Object.keys(cartItemsArr[index][index1]).map((index2) => {
                    let product = cartItemsArr[index][index1][index2];
                    return (
                      <div key={index2}>
                        {this.checkAvailability(index1, letOutleName)}
                        {this.cartOutletItemList(product, index1)}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      });
    }
  }

  checkAvailability(outletID, letOutleName) {
    let cartoutletavailability = this.props.cartoutletavailability;

    if (
      cartoutletavailability[outletID] !== "" &&
      typeof cartoutletavailability[outletID] !== undefined &&
      cartoutletavailability[outletID] !== "undefined"
    ) {
      if (
        cartoutletavailability[outletID].indexOf(this.state.defaultAvilTy) < 0
      ) {
        return (
          <span className="outlet-avilable">
            Currently unavailable. Let's try other restaurants!
            <input
              type="hidden"
              className="notavailabeOutlet"
              value={letOutleName}
            />
          </span>
        );
      }
    }
  }

  cartOutletItemList(cartItemsArr, outletID) {
    if (Object.keys(cartItemsArr).length > 0 && cartItemsArr !== null) {
      return cartItemsArr.map((product, index) => (
        <div
          className="cart_row product-details ckout-product-details"
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div className="row-replace">
            <div className="col-sm-cls cart_left">
              <div class="cart_img">
                <img src={productImg} />
              </div>
              <div className="cart_info text-left">
                <h4>
                  <Link
                    to={
                      parseInt(product.product_free) === 1 || outletID === "53"
                        ? void 0
                        : product.outlet_slug !== ""
                        ? "/" +
                          restaurantLink +
                          "/" +
                          product.outlet_slug +
                          "/" +
                          product.pro_cate_slug +
                          "/" +
                          product.pro_subcate_slug +
                          "/" +
                          product.product_slug
                        : "/"
                    }
                  >
                    {stripslashes(product.cart_item_product_name).toLowerCase()}
                  </Link>
                </h4>
                {this.loadModifierItems(
                  product.cart_item_type,
                  product.modifiers,
                  product.set_menu_component
                )}

                {product.cart_item_special_notes !== "" && (
                  <p className="help-block">
                    {stripslashes(product.cart_item_special_notes)}
                  </p>
                )}
                {parseFloat(product.cart_item_promotion_discount) > 0 && (
                  <span className="member-discount-desc">
                    $ {product.cart_item_promotion_discount}{" "}
                    {product.cart_item_promotion_type} discount Applied
                  </span>
                )}
              </div>
            </div>

            <div className="col-sm-cls cart_right text-right">
              <div className="cart_price">
                <p>${product.cart_item_total_price}</p>
              </div>
              <div className="qty_bx">
                <span
                  className="qty_minus"
                  onClick={this.updateCartQty.bind(this, product, "decr")}
                >
                  -
                </span>
                <input
                  type="text"
                  className={
                    product.product_stock === null ||
                    parseInt(product.product_stock) === 0
                      ? "cart-no-stock-qty"
                      : ""
                  }
                  value={product.cart_item_qty}
                  readOnly
                />
                <span
                  className={
                    product.product_stock === null ||
                    parseInt(product.product_stock) === 0 ||
                    (parseInt(product.product_free) === 1 &&
                      parseInt(product.cart_item_free_prodcut_qty) <=
                        product.cart_item_qty)
                      ? "qty_plus disabled"
                      : "qty_plus"
                  }
                  onClick={this.updateCartQty.bind(this, product, "incr")}
                >
                  +
                </span>
              </div>
              <a
                href={void 0}
                onClick={this.deleteCartItm.bind(this, product)}
                class="cart_remove"
              ></a>
            </div>
          </div>
        </div>
      ));
    }
  }

  editCartItem(itemID) {
    this.setState({ edititemID: itemID });
    if ($("#edit-warning-orderpopup").length > 0) {
      $.magnificPopup.open({
        items: {
          src: "#edit-warning-orderpopup",
        },
        type: "inline",
      });
    }
  }

  /* cartOutletItemList(cartItemsArr) {
    if (Object.keys(cartItemsArr).length > 0) {
      return cartItemsArr.map((product, index) => (
        <div
          className="cart_row product-details"
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div className="row-replace">
            <div className="col-sm-cls cart_left">
              <div className="cart_info text-left">
                <h4>{stripslashes(product.cart_item_product_name)}</h4>

                {this.loadModifierItems(
                  product.cart_item_type,
                  product.modifiers,
                  product.set_menu_component
                )}

                {product.cart_item_special_notes !== "" && (
                  <p className="help-block">
                    {stripslashes(product.cart_item_special_notes)}
                  </p>
                )}

                {parseFloat(product.cart_item_promotion_discount) > 0 && (
                  <span className="member-discount-desc">
                    $ {product.cart_item_promotion_discount}{" "}
                    {product.cart_item_promotion_type} discount Applied
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="qty_bx">
                <span
                  className="qty_minus"
                  onClick={this.updateCartQty.bind(this, product, "decr")}
                >
                  -
                </span>
                <input type="text" value={product.cart_item_qty} readOnly />
                <span
                  className="qty_plus"
                  onClick={this.updateCartQty.bind(this, product, "incr")}
                >
                  +
                </span>
              </div>
              <div className="cart_price">
                <p>${product.cart_item_total_price}</p>

                <a
                  href="/"
                  onClick={this.deleteCartItm.bind(this, product)}
                  className="cart_remove"
                ></a>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  } */

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var totalItmCount = this.props.cartTotalItmCount;
    if (type === "incr") {
      if (
        itemArr.product_stock === null ||
        parseInt(itemArr.product_stock) === 0 ||
        (parseInt(itemArr.product_free) === 1 &&
          itemArr.cart_item_free_prodcut_qty < parseInt(cartQty) + 1)
      ) {
        return false;
      }
    }

    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(productId, cartItemId, cartQty);
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(productId, cartItemId, cartQty);
    }
    this.removePointsAndPromo("frmFunct");
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    console.log("rowcartid-" + cartItemId, "cartItemId");
    showLoader("rowcartid-" + cartItemId);
    this.props.deleteCartDetail(cartItemId);
    this.removePointsAndPromo("frmFunct");
  }

  clearCartItm(event) {
    event.preventDefault();
    showLoader("cart_body", "class");
    this.props.destroyCartDetail();
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (+" + modVlPrice + ")" : "";
        temp_html +=
          "<p><b>" +
          modName +
          ":</b></p> <p>" +
          modval +
          newModVlPrice +
          "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboVal = this.showComboInCombo(combos[r]["set_menu_component"]);
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html +=
            "<p>" +
            comboQty +
            " X " +
            comboPro +
            newPrice +
            " " +
            comboVal +
            " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* show inner combo products  list */
  showComboInCombo(combo) {
    var html = "";
    var comboLen = combo.length;
    for (var i = 0, length = comboLen; i < length; i++) {
      var comboName = combo[i]["menu_component_name"];
      var comboVal = this.showComboInComboProducts(combo[i]["product_details"]);
      html +=
        "<ul class='cart-combo-in-combo'><li>" +
        comboName +
        ": </li>" +
        comboVal +
        " " +
        "</ul> ";
    }
    return html;
  }

  showComboInComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_inner_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_inner_menu_component_product_name"];
          var comboQty = combos[r]["cart_inner_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_inner_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html += "<li>" + comboQty + " X " + comboPro + newPrice + " </li> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b> </p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  applyPointsAndPromo(type) {
    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;
    var reedemPointVal = this.state.reward_point_val;
    var promoCodeVal = this.state.promo_code_val;
    var usedPoints = this.state.used_reward_point;
    var promoOutlet = this.state.promoOutlet;
    var promoType = this.state.promoType;
    var promoPercentage = this.state.promoPercentage;
    var cartDetailsArr = this.state.cartDetails;
    var cartItemsSateArr = this.state.cartItems;
    var promooutletproducts = "";
    if (type == "points") {
      if (reedemPointVal === "") {
        showCustomCenterAlert("error", "Please enter Rewards Points");
        return false;
      }
    } else if (type == "promoCode") {
      if (promoCodeVal === "") {
        showCustomCenterAlert("error", "Please enter Promo Code");
        return false;
      }
    }
    var subTotal =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_sub_total
        : 0;
    var totalItems =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_total_items
        : 0;

    if (type === "points") {
      showLoader("applypoints_cls", "class");

      var postObject = {
        app_id: appId,
        reference_id: this.state.UserId,
        redeem_point: reedemPointVal,
        cart_amount: subTotal,
      };

      axios
        .post(
          apiUrl + "loyalty/apply_loyalityv1",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            promotionApplied = "Yes";
            promotionType = "points";
            promotionAmount = pointDet.points_amount;
            promotionSource = reedemPointVal;
            promoIsDelivery = "";
            promoCodeVal = "";
            promoType = "";
            promoPercentage = "";
            usedPoints = reedemPointVal;
            promooutletproducts = JSON.stringify(
              pointDet.outlet_promo_products
            );
            showCustomCenterAlert(
              "success",
              "Rewards Points applied successfully"
            );
          } else {
            if (promotionType === "points") {
              promotionApplied = "";
              promotionType = "";
              promotionAmount = "";
              promotionSource = "";
              promoIsDelivery = "";
              usedPoints = 0;
              promoType = "";
              promoPercentage = "";
            }
            showCustomCenterAlert(
              "error",
              "Sorry! Did not applied Rewards Points"
            );
          }

          hideLoader("applypoints_cls", "class");

          cookie.save("reedemPointVal", reedemPointVal, cookieConfig);
          cookie.save("promoCodeVal", promoCodeVal, cookieConfig);
          cookie.save("promotionApplied", promotionApplied, cookieConfig);
          cookie.save("promotionType", promotionType, cookieConfig);
          cookie.save("promotionAmount", promotionAmount, cookieConfig);
          cookie.save("promotionSource", promotionSource, cookieConfig);
          cookie.save("promoIsDelivery", promoIsDelivery, cookieConfig);
          cookie.save("usedPoints", usedPoints, cookieConfig);
          cookie.save("promoType", promoType, cookieConfig);
          cookie.save("promoPercentage", promoPercentage, cookieConfig);
          cookie.save("promooutletproducts", promooutletproducts, cookieConfig);

          this.setState({
            reward_point_val: reedemPointVal,
            promo_code_val: promoCodeVal,
            promotion_applied: promotionApplied,
            promotion_type: promotionType,
            promotion_amount: promotionAmount,
            promotion_source: promotionSource,
            promoIs_delivery: promoIsDelivery,
            used_reward_point: usedPoints,
            promoType: promoType,
            promoPercentage: promoPercentage,
            promoOutlet: 0,
          });
        });
    } else if (type === "promoCode") {
      showLoader("applypromo_cls", "class");

      var categoryIdsDet = this.getProductIdsDet(cartItemsSateArr);
      var avilablityId = this.state.defaultAvilTy;

      var postObject = {
        app_id: appId,
        reference_id: this.state.UserId,
        promo_code: promoCodeVal,
        cart_amount: subTotal,
        cart_quantity: totalItems,
        category_id: categoryIdsDet,
        availability_id: avilablityId,
        outlet_id: cookie.load("orderOutletId"),
      };

      axios
        .post(
          apiUrl + "promotion_api_v2/apply_promotion",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          var promotionID = "";
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            var IsDelivery =
              pointDet.promotion_delivery_charge_applied == "Yes"
                ? "Yes"
                : "No";
            promotionApplied = "Yes";
            promotionType = "promoCode";
            promotionAmount = pointDet.promotion_amount;
            promotionSource = promoCodeVal;
            promoIsDelivery = IsDelivery;
            reedemPointVal = "";
            promoOutlet = pointDet.promotion_outlet_id;
            promotionID = pointDet.promotion_id;
            promoType = pointDet.promotion_type;
            promoPercentage = pointDet.promotion_percentage;
            usedPoints = 0;
            $.magnificPopup.close();
            showToastrAlert("Promo applied successfully");
          } else {
            var msgTxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Did not applied promo code";
            if (promotionType === "promoCode") {
              promotionApplied = "";
              promotionType = "";
              promotionAmount = "";
              promotionSource = "";
              promoIsDelivery = "";
              promoOutlet = "";
              promoType = "";
              promoPercentage = "";
            }
            showCustomCenterAlert("error", msgTxt);
          }

          hideLoader("applypromo_cls", "class");

          cookie.save("promotionID", promotionID, cookieConfig);
          cookie.save("reedemPointVal", reedemPointVal, cookieConfig);
          cookie.save("promoCodeVal", promoCodeVal, cookieConfig);
          cookie.save("promoOutlet", promoOutlet, cookieConfig);
          cookie.save("promotionApplied", promotionApplied, cookieConfig);
          cookie.save("promotionType", promotionType, cookieConfig);
          cookie.save("promotionAmount", promotionAmount, cookieConfig);
          cookie.save("promotionSource", promotionSource, cookieConfig);
          cookie.save("promoIsDelivery", promoIsDelivery, cookieConfig);
          cookie.save("usedPoints", usedPoints, cookieConfig);
          cookie.save("promoType", promoType, cookieConfig);
          cookie.save("promoPercentage", promoPercentage, cookieConfig);

          this.setState({
            reward_point_val: reedemPointVal,
            promo_code_val: promoCodeVal,
            promotion_applied: promotionApplied,
            promotion_type: promotionType,
            promotion_amount: promotionAmount,
            promotion_source: promotionSource,
            promoIs_delivery: promoIsDelivery,
            used_reward_point: usedPoints,
            promoOutlet: promoOutlet,
            promoType: promoType,
            promoPercentage: promoPercentage,
          });
        });
    }
  }

  removePointsAndPromo(flag, event) {
    if (flag === "fromclk") {
      event.preventDefault();
    }
    var paymentmode = this.state.paymentmodevalue;
    if (paymentmode === "promotion") {
      var settingsArr = this.state.globalSettings;
      var paymentmode = "Stripe";
      if (Object.keys(settingsArr).length > 0) {
        if (
          settingsArr.client_cod_enable == 1 &&
          settingsArr.client_cod_availability == 1
        ) {
          paymentmode = "Cash";
        }
      }
    }

    removePromoCkValue();

    this.setState({
      reward_point_val: "",
      promo_code_val: "",
      promotion_applied: "",
      promotion_type: "",
      promotion_amount: "",
      promotion_source: "",
      promoIs_delivery: "",
      used_reward_point: 0,
      paymentmodevalue: paymentmode,
      promoOutlet: "",
      promoType: "",
      promoPercentage: "",
    });
  }

  getProductIdsDet(cartItems) {
    var product_cartid = "";
    if (Object.keys(cartItems).length > 0) {
      for (var key in cartItems) {
        if (
          product_cartid !== "" &&
          cartItems[key].cart_item_product_id !== ""
        ) {
          product_cartid += ";";
        }
        product_cartid +=
          cartItems[key].cart_item_product_id +
          "|" +
          cartItems[key].cart_item_total_price +
          "|" +
          cartItems[key].cart_item_qty;
      }
    }

    return product_cartid;
  }

  payCash(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    this.postOrder(1);
  }

  postPromotionOrder(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });
    this.postOrder(4);
  }

  /* show online payment mode loading */
  onlinePaymentLoading() {
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      this.props.maxCart,
      this.state.cartItems,
      this.state.outletTax
    );
    var grandTotal = parseFloat(calculatedAmount["grandTotalAmount"]);

    if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.validateimage} />
          </div>
          <div className="process_right">
            <h5>Processing</h5>
            <p>Validating card information.</p>
          </div>
        </div>
      );
    }
  }
  /* show online payment mode loading */
  onlineTwoCPaymentLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.validateimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>Completing your online payment.</p>
        </div>
      </div>
    );
  }
  /**/

  /* show online payment mode loading */
  orderBCLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.placingorderimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>Placing your order now.</p>
        </div>
      </div>
    );
  }

  /* show online payment mode loading */
  amountCaptureLoading() {
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      this.props.maxCart,
      this.state.cartItems,
      this.state.outletTax
    );
    var grandTotal = parseFloat(calculatedAmount["grandTotalAmount"]);

    if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.completingpaymentimage} />
          </div>
          <div className="process_right">
            <h5>Processing</h5>
            <p>Completing your online payment.</p>
          </div>
        </div>
      );
    }
  }
  /**/

  /* this fuction used to post order to biz panel */
  postOrder(
    paymentMode,
    validation = "No",
    captureID = "",
    payGetWayType = "",
    Paymentpop = "No"
  ) {
    if (this.state.UserId === "") {
      cookie.save("loginpopupTrigger", "Yes", cookieConfig);
      this.props.history.push("/");
      return false;
    }

    if ($(".notavailabeOutlet").length > 0) {
      var outletError = "";
      $(".notavailabeOutlet").each(function () {
        outletError += $(this).val() + ", ";
      });
      console.log("aaaa");
      showAlert(
        "Error",
        outletError + " Currently unavailable. Let's try other restaurants!"
      );
      if ($(".alert_popup").length > 0) {
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }

      return false;
    }

    if (this.state.defaultAvilTy == deliveryId && paymentMode !== "initial") {
      var billingAddrsSameas = this.state.billing_addrs_sameas;
      var billingPostcode = this.state.billing_postcode;
      var billingAddrss = this.state.billing_delivery_address;
      if (
        billingAddrsSameas === "no" &&
        (billingPostcode === "" || billingAddrss === "")
      ) {
        showAlert("Error", "Please enter default billing address.");
        console.log("bbb");
        if ($(".alert_popup").length > 0) {
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
        return false;
      }
    }

    var unitNoOne = this.state.unitnumber1;
    if (unitNoOne == "" && this.state.defaultAvilTy == deliveryId) {
      if (unitNoOne === "") {
        showAlert(
          "Enter your unit/house number",
          "If your address does not require a unit/house number, please enter “N/A”."
        );
      }
      console.log("cccc");
      if ($(".alert_popup").length > 0) {
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
      smoothScroll(100, 250);
      $("#unit_no1_id").focus();
      return false;
    }

    var min_amount,
      subTotal,
      balance = 0;
    if (Object.keys(this.state.cartDetails).length > 0) {
      var globalSettings = this.state.globalSettings;
      var min_amount = parseFloat(globalSettings.pos_min_amount);
      var subTotal = parseFloat(this.state.cartDetails.cart_sub_total);
      var balance = parseFloat(min_amount - subTotal);
    }

    if (balance > 0 && min_amount > 0 && min_amount > subTotal) {
      balance = balance.toFixed(2);
      showAlert("Error", "$" + balance + " more to min order amount");
      console.log("dddd");
      if ($(".alert_popup").length > 0) {
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
      return false;
    }

    var finalcartItems = this.state.cartItems;
    var finalCartOutlet = this.state.cartOutlet;
    var products = [];

    if (Object.keys(this.state.cartItems).length > 0) {
      Object.keys(this.state.cartItems).map((index2) => {
        Object.keys(this.state.cartItems[index2]).map((index1) => {
          Object.keys(this.state.cartItems[index2][index1]).map((index) => {
            let cartproductList = this.state.cartItems[index2][index1][index];
            var row = cartproductList.map(
              function (item, i) {
                var modifierdata = [];
                var comboData = [];
                var ComboLen = item.set_menu_component.length;
                var modifierLen = item.modifiers.length;
                if (item.cart_item_type === "Modifier" && modifierLen > 0) {
                  for (var l = 0; l < modifierLen; l++) {
                    var modifierVal = [];
                    modifierVal.push({
                      modifier_value_id:
                        item.modifiers[l].modifiers_values[0].cart_modifier_id,
                      modifier_value_qty:
                        item.modifiers[l].modifiers_values[0].cart_modifier_qty,
                      modifier_value_name:
                        item.modifiers[l].modifiers_values[0]
                          .cart_modifier_name,
                      modifier_value_price:
                        item.modifiers[l].modifiers_values[0]
                          .cart_modifier_price,
                    });
                    modifierdata.push({
                      modifier_id: item.modifiers[l].cart_modifier_id,
                      modifier_name: item.modifiers[l].cart_modifier_name,
                      modifiers_values: modifierVal,
                    });
                  }
                } else if (
                  item.cart_item_type === "Component" &&
                  ComboLen > 0
                ) {
                  for (var m = 0; m < ComboLen; m++) {
                    var combomodifierdata = [];
                    /* build  modifier array */
                    var comboModifier =
                      item.set_menu_component[m].product_details[0].modifiers;

                    var combomodifierdata = [];
                    if (comboModifier.length > 0) {
                      for (var p = 0; p < comboModifier.length; p++) {
                        var comboModifierVal = [];
                        comboModifierVal.push({
                          modifier_value_id:
                            comboModifier[p].modifiers_values[0]
                              .cart_modifier_id,
                          modifier_value_qty:
                            comboModifier[p].modifiers_values[0]
                              .cart_modifier_qty,
                          modifier_value_name:
                            comboModifier[p].modifiers_values[0]
                              .cart_modifier_name,
                          modifier_value_price:
                            comboModifier[p].modifiers_values[0]
                              .cart_modifier_price,
                        });
                        combomodifierdata.push({
                          modifier_id: comboModifier[p].cart_modifier_id,
                          modifier_name: comboModifier[p].cart_modifier_name,
                          modifiers_values: comboModifierVal,
                        });
                      }
                    }

                    var comborVal = [];
                    var comboProductDetailslen =
                      item.set_menu_component[m].product_details.length;
                    if (comboProductDetailslen > 0) {
                      for (
                        var j = 0, lengthComboPro = comboProductDetailslen;
                        j < lengthComboPro;
                        j++
                      ) {
                        if (
                          parseInt(
                            item.set_menu_component[m].product_details[j]
                              .cart_menu_component_product_qty
                          ) > 0
                        ) {
                          var comboincombodata = [];
                          var comboInCombo =
                            item.set_menu_component[m].product_details[j]
                              .set_menu_component;
                          if (comboInCombo.length > 0) {
                            comboInCombo.map((iteml2) => {
                              var comboinComboPro = [];
                              if (iteml2.product_details.length > 0) {
                                iteml2.product_details.map((item1) => {
                                  comboinComboPro.push({
                                    product_id:
                                      item1.cart_inner_menu_component_product_id,
                                    product_name:
                                      item1.cart_inner_menu_component_product_name,
                                    product_sku:
                                      item1.cart_inner_menu_component_product_sku,
                                    product_qty:
                                      item1.cart_inner_menu_component_product_qty,
                                    product_price:
                                      item1.cart_inner_menu_component_product_price,
                                    modifiers: [],
                                    set_menu_component: [],
                                  });
                                });
                              }
                              comboincombodata.push({
                                menu_component_id: iteml2.menu_component_id,
                                menu_component_name: iteml2.menu_component_name,
                                product_details: comboinComboPro,
                              });
                            });
                          }

                          comborVal.push({
                            product_id:
                              item.set_menu_component[m].product_details[j]
                                .cart_menu_component_product_id,
                            outlet_id: index1,
                            product_name:
                              item.set_menu_component[m].product_details[j]
                                .cart_menu_component_product_name,
                            product_sku:
                              item.set_menu_component[m].product_details[j]
                                .cart_menu_component_product_sku,
                            product_qty:
                              item.set_menu_component[m].product_details[j]
                                .cart_menu_component_product_qty,
                            product_price:
                              item.set_menu_component[m].product_details[j]
                                .cart_menu_component_product_price,
                            modifiers: combomodifierdata,
                            menu_set_components: comboincombodata,
                          });
                        }
                      }
                    }

                    comboData.push({
                      menu_component_id:
                        item.set_menu_component[m].menu_component_id,
                      menu_component_name:
                        item.set_menu_component[m].menu_component_name,
                      product_details: comborVal,
                    });
                  }
                }

                products.push({
                  product_item_id: item.cart_item_id,
                  product_name: item.cart_item_product_name,
                  outlet_id: index1,
                  product_unit_price: item.cart_item_unit_price,
                  product_total_amount: item.cart_item_total_price,
                  product_sku: item.cart_item_product_sku,
                  product_image: item.cart_item_product_image,
                  product_id: item.cart_item_product_id,
                  product_qty: item.cart_item_qty,
                  condiments: "",
                  modifiers: modifierdata,
                  bakers_modifiers: "",
                  menu_set_components: comboData,
                  baby_pack: "",
                  product_special_notes: item.cart_item_special_notes,
                });

                return products;
              }.bind(this)
            );
          });
        });
      });
    }

    /* if  merge order date */
    var orderDate = "";

    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;

    /* For Advanced Slot */
    var order_is_timeslot = "No",
      ordSlotStrTm = "",
      ordSlotEndTm = "";
    if (seletedOrdDate !== "" && seletedOrdTime !== "") {
      var formatedDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();

      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

      orderDate = formatedDate + " " + orderTime;

      if (this.state.isAdvanced === "yes" && this.state.slotType === "2") {
        order_is_timeslot = "Yes";
        ordSlotStrTm = this.state.seleted_ord_slot_str;
        ordSlotEndTm = this.state.seleted_ord_slot_end;
      }
    }

    /*  ||
      ordSlotStrTm === "" ||
      ordSlotEndTm === "" */
    if (this.state.defaultAvilTy !== dineinId) {
      if (
        (this.state.isAdvanced === "" && this.state.slotType === "") ||
        order_is_timeslot === "no"
      ) {
        cookie.save("isAdvanced", "yes", cookieConfig);
        cookie.save("slotType", "2", cookieConfig);
        cookie.save("triggerTimeSlotPopup", "yes", cookieConfig);
        showAlert("Error", "Please select order time slot.");
        console.log("eeee", this.state.defaultAvilTy);
        if ($(".alert_popup").length > 0) {
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
        this.props.history.push("/refpage/checkout");
        return false;
      }
    }

    if (seletedOrdDate !== "" && seletedOrdTime !== "") {
      var formatedDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();

      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

      orderDate = formatedDate + " " + orderTime;
    }

    if (orderDate === "" && paymentMode !== "initial") {
      showAlert("Error", "Please select order date and time.");
      console.log("ffff");
      if ($(".alert_popup").length > 0) {
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
      return false;
    }

    if (
      this.state.chk_tarms_contn !== "Yes" &&
      this.state.termsAndConditions !== "" &&
      paymentMode !== "initial"
    ) {
      $(".tarms_chkbox_div").addClass("err_tarms_chk");
      window.scrollTo(0, 1000);
      return false;
    }

    if (paymentMode === "initial") {
      paymentMode = 2;
    }

    /*orderDate = '2019-07-10 10:15';*/

    /* insert customner details */
    if (validation !== "Yes") {
      var addressObject = {};
      var customerAddressId = 0;
      addressObject = {
        app_id: appId,
        customer_first_name: this.state.customer_first_name,
        customer_last_name: this.state.customer_last_name,
        customer_phone: this.state.customer_phone,
        customer_email: this.state.customer_email,
        customer_address_line1: cookie.load("orderDeliveryAddress"),
        customer_postal_code: cookie.load("orderPostalCode"),
        customer_address_name: this.state.unitnumber1,
        customer_address_name2: this.state.unitnumber2,
        refrence: this.state.UserId,
        customer_status: "A",
        customer_order_status: "order",
      };
      if (this.state.defaultAvilTy === deliveryId) {
        axios
          .post(
            apiUrl + "customer/secondary_address_add",
            qs.stringify(addressObject),
            headerconfig
          )
          .then((res) => {
            if (res.data.status === "ok") {
              customerAddressId = res.data.insert_id;
            }
          });
      }
    }
    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;

    var cartDetailsArr = this.state.cartDetails;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = promotionApplied;
    promoTionArr["promotionAmount"] = promotionAmount;
    promoTionArr["promoIsDelivery"] = promoIsDelivery;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      cartDetailsArr,
      promoTionArr,
      this.props.maxCart,
      this.state.cartItems,
      this.state.outletTax
    );

    var BillingAddress = this.state.billing_delivery_address;
    var BillingPostalCode = this.state.billing_postcode;
    var BillingUnitNo1 = this.state.billunitnumber1;
    var BillingUnitNo2 = this.state.billunitnumber2;

    if (this.state.billing_addrs_sameas === "yes") {
      BillingAddress = cookie.load("orderDeliveryAddress");
      BillingPostalCode = cookie.load("orderPostalCode");
      BillingUnitNo1 = this.state.unitnumber1;
      BillingUnitNo2 = this.state.unitnumber2;
    }

    var postObject = {};
    this.setState({
      paymentmodevalue: $('input[name="radio-group"]:checked').val(),
    });

    var paymentMethod = $('input[name="radio-group"]:checked').val();
    var two_c_two_p_order_id = "";
    var urlParams = new URLSearchParams(this.props.location.search);
    if (
      urlParams.get("two_c_two_p_status") == "success" &&
      urlParams.get("two_c_two_p_order_id") != ""
    ) {
      two_c_two_p_order_id = urlParams.get("two_c_two_p_order_id");
      this.setState({
        two_c_two_p_order_id: two_c_two_p_order_id,
      });
      paymentMethod = "2C2P";
      validation = "No";
      payGetWayType = "2C2P";
    }

    var orderOutletId = "";
    if (
      cookie.load("orderOutletId") !== "" &&
      typeof cookie.load("orderOutletId") !== undefined &&
      typeof cookie.load("orderOutletId") !== "undefined"
    ) {
      orderOutletId = cookie.load("orderOutletId");
    } else {
      if (this.props.cartOutletList.length > 0) {
        orderOutletId = this.props.cartOutletList[0];
        cookie.save("orderOutletId", orderOutletId, cookieConfig);
      }
    }

    var referenceNewId = getReferenceID();
    var userAccountType =
      typeof cookie.load("userAccountType") === "undefined"
        ? ""
        : cookie.load("userAccountType");
    var orderTableNo =
      cookie.load("orderTableNo") !== "" &&
      typeof cookie.load("orderTableNo") !== undefined &&
      typeof cookie.load("orderTableNo") !== "undefined"
        ? cookie.load("orderTableNo")
        : "";
    if (orderTableNo === "" && this.state.defaultAvilTy === dineinId) {
      /* showAlert("Error", "Please rescan QR code and try again");
      if ($(".alert_popup").length > 0) {
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      } */
      cookie.save("openqrcode", "Yes", cookieConfig);
      this.props.history.push("/");
      return false;
    }
    postObject = {
      /* cart details */
      app_id: appId,
      order_location_id: this.props.siteLocID,
      availability_id: this.state.defaultAvilTy,
      sub_total: parseFloat(calculatedAmount["cartSubTotalAmount"]).toFixed(2),
      grand_total: parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2),
      outlet_id: orderOutletId,
      zone_id:
        this.state.defaultAvilTy === deliveryId &&
        cookie.load("orderZoneId") !== "" &&
        typeof cookie.load("orderZoneId") !== undefined &&
        typeof cookie.load("orderZoneId") !== "undefined"
          ? cookie.load("orderZoneId")
          : "",
      table_number: orderTableNo,
      order_status: 1,
      order_source: "Web",
      order_date: orderDate,
      reference_new_id: referenceNewId,
      login_type: userAccountType,

      /* For Advanced Slot */
      order_is_timeslot: order_is_timeslot,
      order_pickup_time_slot_from: ordSlotStrTm,
      order_pickup_time_slot_to: ordSlotEndTm,

      order_remarks: this.state.order_specil_note,
      products: JSON.stringify(products),
      /*cartOutletList for 2c2p*/
      two_c_two_p_order_id: two_c_two_p_order_id,
      cart_outlet_list: this.props.cartOutletList,
      site_location: this.state.globalSettings.site_location,
      payment_method: paymentMethod,
      /* customer  Details */
      customer_id: this.state.UserId,
      customer_address_id: customerAddressId,
      customer_fname: this.state.customer_first_name,
      customer_lname: this.state.customer_last_name,
      customer_mobile_no: this.state.customer_phone,
      customer_email: this.state.customer_email,
      customer_address_line1: cookie.load("orderDeliveryAddress"),
      customer_address_line2: "",
      customer_postal_code: cookie.load("orderPostalCode"),
      customer_unit_no1: this.state.unitnumber1,
      customer_unit_no2: this.state.unitnumber2,

      billing_address_line1: BillingAddress,
      billing_postal_code: BillingPostalCode,
      billing_unit_no1: BillingUnitNo1,
      billing_unit_no2: BillingUnitNo2,

      /* Payment */
      payment_mode: paymentMode /*paymentMode, */,
      payment_reference: stripeReference,
      stripe_envir: "test",
      payment_type: payGetWayType,
      order_capture_token: captureID,
      order_payment_getway_type: "",
      order_payment_getway_status: "Yes",

      /* additional paras */
      delivery_charge: parseFloat(calculatedAmount["deliveryCharge"]).toFixed(
        2
      ),
      additional_delivery: parseFloat(
        calculatedAmount["additionalDelivery"]
      ).toFixed(2),
      order_tat_time:
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT"),
      tax_charge: calculatedAmount["orderDisplayGst"],
      order_tax_calculate_amount: parseFloat(
        calculatedAmount["orderGstAmount"]
      ).toFixed(2),
      order_data_encode: "Yes",

      /* discount */
      order_discount_applied: "",
      order_discount_amount: "",
      order_discount_type: "",

      order_special_discount_amount: cartDetailsArr.cart_special_discount,
      order_special_discount_type: cartDetailsArr.cart_special_discount_type,
      cutleryOption: this.state.cutlery,
      order_shop_type: shopType,
      product_leadtime: this.state.productLeadtime,
      order_customer_unique_id:
        cookie.load("memberID") !== "" &&
        typeof cookie.load("memberID") !== undefined &&
        typeof cookie.load("memberID") !== "undefined"
          ? cookie.load("memberID")
          : "",
    };
    /* check  validation */
    if (validation === "Yes") {
      postObject["validation_only"] = "Yes";
    }

    /*  promo code object - start */
    if (
      promotionApplied === "Yes" &&
      (parseFloat(promotionAmount) > 0 || promoIsDelivery === "Yes")
    ) {
      postObject["discount_applied"] = "Yes";
      postObject["discount_amount"] = promotionAmount;

      if (promotionType === "promoCode") {
        var categoryIdsDet = this.getProductIdsDet(finalcartItems);

        postObject["coupon_applied"] = "Yes";
        postObject["promo_code"] = promotionSource;
        postObject["cart_quantity"] = cartDetailsArr.cart_total_items;
        postObject["category_id"] = categoryIdsDet;
        postObject["coupon_amount"] = promotionAmount;
        postObject["order_discount_amount"] = promotionAmount;
        postObject["order_discount_applied"] = "Yes";

        postObject["promotion_delivery_charge_applied"] = promoIsDelivery;
      }

      if (promotionType === "points") {
        postObject["redeem_applied"] = "Yes";
        postObject["redeem_point"] = promotionSource;
        postObject["redeem_amount"] = promotionAmount;
      }
    }

    postObject["reward_point_status"] = "Yes";

    if (this.state.defaultAvilTy === deliveryId) {
      postObject["order_customer_send_gift"] =
        this.state.order_customer_send_gift;
      postObject["order_recipient_name"] = this.state.recipient_name;
      postObject["order_recipient_contact_no"] =
        this.state.recipient_contact_no;
      postObject["order_recipient_email"] = this.state.recipient_email;
      postObject["order_gift_message"] = this.state.gift_message;
    }

    axios
      .post(
        apiUrl + "ordersv1/submit_order",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ orderValidFail: 0 });

          if (validation !== "Yes") {
            var localOrderNo = res.data.common.local_order_no;
            this.setState({ placingorderimage: tickImage });

            /* capture payemnt */
            if (payGetWayType === "Stripe") {
              this.captureAmount(
                captureID,
                res.data.common.order_primary_id,
                localOrderNo
              );
            } else if (payGetWayType === "2C2P") {
              this.checkTwoCTwoP(
                this.state.two_c_two_p_order_id,
                localOrderNo,
                res.data.common.order_primary_id
              );
              //return true;
              /*  this.captureTwoCTwoPAmount(
                captureID,
                res.data.common.order_primary_id,
                localOrderNo
              ); */
            } else {
              this.showSuccessPage(
                localOrderNo,
                res.data.common.order_primary_id
              );
            }
          } else {
            if (Paymentpop === "Yes") {
              var popupIdTxt =
                payGetWayType === "Omise"
                  ? "#pay-omiseconf-popup"
                  : "#pay-conf-popup";
              console.log("gggg");
              $.magnificPopup.open({
                items: {
                  src: popupIdTxt,
                },
                type: "inline",
                closeOnBgClick: false,
              });
            }
            return true;
          }
        } else if (res.data.status === "error") {
          // This is used for stripe Auth Capture
          this.setState({ orderValidFail: 1 });
          if (res.data.form_error !== undefined) {
            showAlert("Error", res.data.form_error);
          } else {
            showAlert("Error", res.data.message);
          }
          console.log("hhhhh");
          if ($(".alert_popup").length > 0) {
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        }
      })
      .catch((error) => {
        $.magnificPopup.close();
        this.paymentFail(
          "Error",
          "Error code: 1004 Oops! Unable to processing your order. Please try again."
        );
        console.log("iiii");
        if ($(".warining_popup").length > 0) {
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });
        }
        return false;
      });
  }
  /* this fuction used to post order to biz panel */
  orderRequest(
    paymentMode,
    validation = "No",
    captureID = "",
    payGetWayType = "",
    Paymentpop = "No"
  ) {
    if (this.state.defaultAvilTy == deliveryId && paymentMode !== "initial") {
      var billingAddrsSameas = this.state.billing_addrs_sameas;
      var billingPostcode = this.state.billing_postcode;
      var billingAddrss = this.state.billing_delivery_address;
    }

    var min_amount,
      subTotal,
      balance = 0;
    if (Object.keys(this.state.cartDetails).length > 0) {
      var globalSettings = this.state.globalSettings;
      var min_amount = parseFloat(globalSettings.pos_min_amount);
      var subTotal = parseFloat(this.state.cartDetails.cart_sub_total);
      var balance = parseFloat(min_amount - subTotal);
    }

    var finalcartItems = this.state.cartItems;
    var finalCartOutlet = this.state.cartOutlet;
    var products = [];

    if (Object.keys(this.state.cartItems).length > 0) {
      Object.keys(this.state.cartItems).map((index2) => {
        Object.keys(this.state.cartItems[index2]).map((index1) => {
          Object.keys(this.state.cartItems[index2][index1]).map((index) => {
            let cartproductList = this.state.cartItems[index2][index1][index];
            var row = cartproductList.map(
              function (item, i) {
                var modifierdata = [];
                var comboData = [];
                var ComboLen = item.set_menu_component.length;
                var modifierLen = item.modifiers.length;
                if (item.cart_item_type === "Modifier" && modifierLen > 0) {
                  for (var l = 0; l < modifierLen; l++) {
                    var modifierVal = [];
                    modifierVal.push({
                      modifier_value_id:
                        item.modifiers[l].modifiers_values[0].cart_modifier_id,
                      modifier_value_qty:
                        item.modifiers[l].modifiers_values[0].cart_modifier_qty,
                      modifier_value_name:
                        item.modifiers[l].modifiers_values[0]
                          .cart_modifier_name,
                      modifier_value_price:
                        item.modifiers[l].modifiers_values[0]
                          .cart_modifier_price,
                    });
                    modifierdata.push({
                      modifier_id: item.modifiers[l].cart_modifier_id,
                      modifier_name: item.modifiers[l].cart_modifier_name,
                      modifiers_values: modifierVal,
                    });
                  }
                } else if (
                  item.cart_item_type === "Component" &&
                  ComboLen > 0
                ) {
                  for (var m = 0; m < ComboLen; m++) {
                    var combomodifierdata = [];
                    /* build  modifier array */
                    var comboModifier =
                      item.set_menu_component[m].product_details[0].modifiers;

                    var combomodifierdata = [];
                    if (comboModifier.length > 0) {
                      for (var p = 0; p < comboModifier.length; p++) {
                        var comboModifierVal = [];
                        comboModifierVal.push({
                          modifier_value_id:
                            comboModifier[p].modifiers_values[0]
                              .cart_modifier_id,
                          modifier_value_qty:
                            comboModifier[p].modifiers_values[0]
                              .cart_modifier_qty,
                          modifier_value_name:
                            comboModifier[p].modifiers_values[0]
                              .cart_modifier_name,
                          modifier_value_price:
                            comboModifier[p].modifiers_values[0]
                              .cart_modifier_price,
                        });
                        combomodifierdata.push({
                          modifier_id: comboModifier[p].cart_modifier_id,
                          modifier_name: comboModifier[p].cart_modifier_name,
                          modifiers_values: comboModifierVal,
                        });
                      }
                    }

                    var comborVal = [];
                    var comboProductDetailslen =
                      item.set_menu_component[m].product_details.length;
                    if (comboProductDetailslen > 0) {
                      for (
                        var j = 0, lengthComboPro = comboProductDetailslen;
                        j < lengthComboPro;
                        j++
                      ) {
                        if (
                          parseInt(
                            item.set_menu_component[m].product_details[j]
                              .cart_menu_component_product_qty
                          ) > 0
                        ) {
                          var comboincombodata = [];
                          var comboInCombo =
                            item.set_menu_component[m].product_details[j]
                              .set_menu_component;
                          if (comboInCombo.length > 0) {
                            comboInCombo.map((iteml2) => {
                              var comboinComboPro = [];
                              if (iteml2.product_details.length > 0) {
                                iteml2.product_details.map((item1) => {
                                  comboinComboPro.push({
                                    product_id:
                                      item1.cart_inner_menu_component_product_id,
                                    product_name:
                                      item1.cart_inner_menu_component_product_name,
                                    product_sku:
                                      item1.cart_inner_menu_component_product_sku,
                                    product_qty:
                                      item1.cart_inner_menu_component_product_qty,
                                    product_price:
                                      item1.cart_inner_menu_component_product_price,
                                    modifiers: [],
                                    set_menu_component: [],
                                  });
                                });
                              }
                              comboincombodata.push({
                                menu_component_id: iteml2.menu_component_id,
                                menu_component_name: iteml2.menu_component_name,
                                product_details: comboinComboPro,
                              });
                            });
                          }

                          comborVal.push({
                            product_id:
                              item.set_menu_component[m].product_details[j]
                                .cart_menu_component_product_id,
                            outlet_id: index1,
                            product_name:
                              item.set_menu_component[m].product_details[j]
                                .cart_menu_component_product_name,
                            product_sku:
                              item.set_menu_component[m].product_details[j]
                                .cart_menu_component_product_sku,
                            product_qty:
                              item.set_menu_component[m].product_details[j]
                                .cart_menu_component_product_qty,
                            product_price:
                              item.set_menu_component[m].product_details[j]
                                .cart_menu_component_product_price,
                            modifiers: combomodifierdata,
                            menu_set_components: comboincombodata,
                          });
                        }
                      }
                    }

                    comboData.push({
                      menu_component_id:
                        item.set_menu_component[m].menu_component_id,
                      menu_component_name:
                        item.set_menu_component[m].menu_component_name,
                      product_details: comborVal,
                    });
                  }
                }

                products.push({
                  product_item_id: item.cart_item_id,
                  product_name: item.cart_item_product_name,
                  outlet_id: index1,
                  product_unit_price: item.cart_item_unit_price,
                  product_total_amount: item.cart_item_total_price,
                  product_sku: item.cart_item_product_sku,
                  product_image: item.cart_item_product_image,
                  product_id: item.cart_item_product_id,
                  product_qty: item.cart_item_qty,
                  condiments: "",
                  modifiers: modifierdata,
                  bakers_modifiers: "",
                  menu_set_components: comboData,
                  baby_pack: "",
                  product_special_notes: item.cart_item_special_notes,
                });

                return products;
              }.bind(this)
            );
          });
        });
      });
    }

    /* if  merge order date */
    var orderDate = "";

    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;

    /* For Advanced Slot */
    var order_is_timeslot = "No",
      ordSlotStrTm = "",
      ordSlotEndTm = "";
    if (seletedOrdDate !== "" && seletedOrdTime !== "") {
      var formatedDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();

      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

      orderDate = formatedDate + " " + orderTime;

      if (this.state.isAdvanced === "yes" && this.state.slotType === "2") {
        order_is_timeslot = "Yes";
        ordSlotStrTm = this.state.seleted_ord_slot_str;
        ordSlotEndTm = this.state.seleted_ord_slot_end;
      }
    }

    if (seletedOrdDate !== "" && seletedOrdTime !== "") {
      var formatedDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();

      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

      orderDate = formatedDate + " " + orderTime;
    }

    if (paymentMode === "initial") {
      paymentMode = 2;
    }
    /* insert customner details */
    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;

    var cartDetailsArr = this.state.cartDetails;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = promotionApplied;
    promoTionArr["promotionAmount"] = promotionAmount;
    promoTionArr["promoIsDelivery"] = promoIsDelivery;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      cartDetailsArr,
      promoTionArr,
      this.props.maxCart,
      this.state.cartItems,
      this.state.outletTax
    );

    var BillingAddress = this.state.billing_delivery_address;
    var BillingPostalCode = this.state.billing_postcode;
    var BillingUnitNo1 = this.state.billunitnumber1;
    var BillingUnitNo2 = this.state.billunitnumber2;

    if (this.state.billing_addrs_sameas === "yes") {
      BillingAddress = cookie.load("orderDeliveryAddress");
      BillingPostalCode = cookie.load("orderPostalCode");
      BillingUnitNo1 = this.state.unitnumber1;
      BillingUnitNo2 = this.state.unitnumber2;
    }

    var postObject = {};
    this.setState({
      paymentmodevalue: $('input[name="radio-group"]:checked').val(),
    });

    var paymentMethod = $('input[name="radio-group"]:checked').val();
    var two_c_two_p_order_id = "";
    var urlParams = new URLSearchParams(this.props.location.search);
    if (
      urlParams.get("two_c_two_p_status") == "success" &&
      urlParams.get("two_c_two_p_order_id") != ""
    ) {
      two_c_two_p_order_id = urlParams.get("two_c_two_p_order_id");
      this.setState({
        two_c_two_p_order_id: two_c_two_p_order_id,
      });
      paymentMethod = "2C2P";
      validation = "No";
      payGetWayType = "2C2P";
    }
    var orderOutletId = "";
    if (
      cookie.load("orderOutletId") !== "" &&
      typeof cookie.load("orderOutletId") !== undefined &&
      typeof cookie.load("orderOutletId") !== "undefined"
    ) {
      orderOutletId = cookie.load("orderOutletId");
    } else {
      if (this.props.cartOutletList.length > 0) {
        orderOutletId = this.props.cartOutletList[0];
        cookie.save("orderOutletId", orderOutletId, cookieConfig);
      }
    }
    postObject = {
      /* cart details */
      app_id: appId,
      order_location_id: this.props.siteLocID,
      availability_id: this.state.defaultAvilTy,
      sub_total: parseFloat(calculatedAmount["cartSubTotalAmount"]).toFixed(2),
      grand_total: parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2),
      outlet_id: orderOutletId,
      table_number: "",
      order_status: 1,
      order_source: "Web",
      order_date: orderDate,

      /* For Advanced Slot */
      order_is_timeslot: order_is_timeslot,
      order_pickup_time_slot_from: ordSlotStrTm,
      order_pickup_time_slot_to: ordSlotEndTm,

      order_remarks: this.state.order_specil_note,
      products: JSON.stringify(products),
      /*cartOutletList for 2c2p*/
      two_c_two_p_order_id: two_c_two_p_order_id,
      cart_outlet_list: this.props.cartOutletList,
      site_location: this.state.globalSettings.site_location,
      payment_method: paymentMethod,
      /* customer  Details */
      customer_id: this.state.UserId,
      /* customer_address_id: customerAddressId, */
      customer_fname: this.state.customer_first_name,
      customer_lname: this.state.customer_last_name,
      customer_mobile_no: this.state.customer_phone,
      customer_email: this.state.customer_email,
      customer_address_line1: cookie.load("orderDeliveryAddress"),
      customer_address_line2: "",
      customer_postal_code: cookie.load("orderPostalCode"),
      customer_unit_no1: this.state.unitnumber1,
      customer_unit_no2: this.state.unitnumber2,

      billing_address_line1: BillingAddress,
      billing_postal_code: BillingPostalCode,
      billing_unit_no1: BillingUnitNo1,
      billing_unit_no2: BillingUnitNo2,

      /* Payment */
      payment_mode: paymentMode /*paymentMode, */,
      payment_reference: stripeReference,
      stripe_envir: "test",
      payment_type: payGetWayType,
      order_capture_token: captureID,
      order_payment_getway_type: "",
      order_payment_getway_status: "Yes",

      /* additional paras */
      delivery_charge: parseFloat(calculatedAmount["deliveryCharge"]).toFixed(
        2
      ),
      additional_delivery: parseFloat(
        calculatedAmount["additionalDelivery"]
      ).toFixed(2),
      order_tat_time:
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT"),
      tax_charge: calculatedAmount["orderDisplayGst"],
      order_tax_calculate_amount: parseFloat(
        calculatedAmount["orderGstAmount"]
      ).toFixed(2),
      order_data_encode: "Yes",

      /* discount */
      order_discount_applied: "",
      order_discount_amount: "",
      order_discount_type: "",

      order_special_discount_amount: cartDetailsArr.cart_special_discount,
      order_special_discount_type: cartDetailsArr.cart_special_discount_type,
      cutleryOption: this.state.cutlery,
      order_shop_type: shopType,
      product_leadtime: this.state.productLeadtime,
    };
    /* check  validation */
    if (validation === "Yes") {
      postObject["validation_only"] = "Yes";
    }

    /*  promo code object - start */
    if (
      promotionApplied === "Yes" &&
      (parseFloat(promotionAmount) > 0 || promoIsDelivery === "Yes")
    ) {
      postObject["discount_applied"] = "Yes";
      postObject["discount_amount"] = promotionAmount;

      if (promotionType === "promoCode") {
        var categoryIdsDet = this.getProductIdsDet(finalcartItems);
        postObject["coupon_applied"] = "Yes";
        postObject["promo_code"] = promotionSource;
        postObject["cart_quantity"] = cartDetailsArr.cart_total_items;
        postObject["category_id"] = categoryIdsDet;
        postObject["coupon_amount"] = promotionAmount;
        postObject["order_discount_amount"] = promotionAmount;
        postObject["order_discount_applied"] = "Yes";
        postObject["promotion_delivery_charge_applied"] = promoIsDelivery;
      }

      if (promotionType === "points") {
        postObject["redeem_applied"] = "Yes";
        postObject["redeem_point"] = promotionSource;
        postObject["redeem_amount"] = promotionAmount;
      }
    }

    if (this.state.defaultAvilTy === deliveryId) {
      postObject["order_customer_send_gift"] =
        this.state.order_customer_send_gift;
      postObject["order_recipient_name"] = this.state.recipient_name;
      postObject["order_recipient_contact_no"] =
        this.state.recipient_contact_no;
      postObject["order_rrecipient_email"] = this.state.recipient_email;
      postObject["order_gift_message"] = this.state.gift_message;
    }

    postObject["reward_point_status"] = "Yes";

    return postObject;
  }

  pageReload = () => {
    setTimeout(function () {
      window.location.reload();
    }, 15000);
  };

  paymentFail(header, message) {
    return false;
    $(".warining_popup").remove();
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '"class="btn btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>'
    );
  }

  /* Capture amount */
  captureAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {};
    var captureParams = captureID + "~" + orderPrimaryId + "~" + localOrderNo;
    cabtureObjects = {
      payment_reference: stripeReference,
      stripe_envir: this.state.globalSettings.stripe_envir,
      customer_id: cookie.load("UserId"),
      app_id: appId,
      token: captureID,
      order_id: orderPrimaryId,
    };
    axios
      .post(apiUrl + "payment/captureAmount", qs.stringify(cabtureObjects))
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });

          this.showSuccessPage(localOrderNo);
        } else if (captureRes.data.status === "error") {
          showStripePopup("Error", "Your order was not successful.");
          if ($(".alert_popup").length > 0) {
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        } else {
          showStripePopup("Error", "Your order was not successful.");
          if ($(".alert_popup").length > 0) {
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        }
      })
      .catch((error) => {});
  }

  /* 2C2P Capture Payment*/
  orderProceedTwoCTwoP(event) {
    event.preventDefault();
    /*$.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    this.postOrder(3, "", "", "2C2P");*/
    this.captureTwoCTwoPAmount("", "", "");
  }
  checkTwoCTwoP(two_c_two_p_order_id, localOrderNo, order_primary_id) {
    this.setState({ twoCtwoPPayment_loading: true }, function () {
      $.magnificPopup.open({
        items: {
          src: "#processing-popup",
        },
        type: "inline",
        showCloseBtn: false,
        midClick: true,
        closeOnBgClick: false,
      });
    });

    var cabtureObjects = {
      app_id: appId,
      order_id: two_c_two_p_order_id,
      order_primary_id: order_primary_id,
    };
    axios
      .post(
        apiUrl + "paymentv1/checkTwoCTwoP",
        qs.stringify(cabtureObjects),
        headerconfig
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.showSuccessPage(localOrderNo);
          return;
        } else if (captureRes.data.status === "error") {
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            captureRes.data.message != ""
              ? captureRes.data.message
              : "Your order was not successful.";
          showAlert("Error", omsMsgTxt, magnfPopup);
          if ($(".alert_popup").length > 0) {
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        } else {
        }
      })
      .catch((error) => {
        var magnfPopup = $.magnificPopup.instance;
        showAlert("Error", error, magnfPopup);
        if ($(".alert_popup").length > 0) {
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      });
  }
  captureTwoCTwoPAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {};
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    promoTionArr["promoOutlet"] = this.state.promoOutlet;
    promoTionArr["promoType"] = this.state.promoType;
    promoTionArr["promoPercentage"] = this.state.promoPercentage;
    promoTionArr["promoCodeVal"] = this.state.promo_code_val;
    promoTionArr["promotionID"] =
      cookie.load("promotionID") !== "" &&
      typeof cookie.load("promotionID") !== undefined &&
      typeof cookie.load("promotionID") !== "undefined"
        ? cookie.load("promotionID")
        : "";
    promoTionArr["promooutletproducts"] =
      cookie.load("promooutletproducts") !== "" &&
      typeof cookie.load("promooutletproducts") !== undefined &&
      typeof cookie.load("promooutletproducts") !== "undefined"
        ? cookie.load("promooutletproducts")
        : "";

    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      this.props.maxCart,
      this.state.cartItems,
      this.state.outletTax
    );
    var orderRequest = this.orderRequest(3, "No", "", "2C2P");

    var delivery = parseFloat(calculatedAmount["deliveryCharge"]).toFixed(2);
    cabtureObjects = {
      payment_reference: stripeReference,
      customer_id: this.state.UserId,
      app_id: appId,
      merchant_id: this.state.globalSettings.client_two_c_two_p_merchant_id,
      secret_key: this.state.globalSettings.client_two_c_two_p_secret_key,
      grand_total: calculatedAmount["grandTotalAmount"],
      site_loc_id: this.props.siteLocID,
      cart_items: this.props.cartItems,
      cart_details: this.state.cartDetails,
      availability: this.state.defaultAvilTy,
      outlet_id: cookie.load("orderOutletId"),
      return_url: apiUrl + "paymentv1/returnTwoCTwoP",
      delivery_details: calculatedAmount,
      local_url: window.location.href,
      promo: promoTionArr,
      order_shop_type: shopType,
      order_request: orderRequest,
    };
    axios
      .post(
        apiUrl + "paymentv1/captureAmountTwoCTwoP",
        qs.stringify(cabtureObjects),
        headerconfig
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          /*append form*/
          $("#two-c-two-p-div").html(captureRes.data.result_set.form_html);
          $("body").find("#two-c-two-p-form").submit();
          return;
        } else if (captureRes.data.status === "error") {
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            captureRes.data.message != ""
              ? captureRes.data.message
              : "Your order was not successful.";
          showAlert("Error", omsMsgTxt, magnfPopup);
          if ($(".alert_popup").length > 0) {
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        } else {
        }
      })
      .catch((error) => {
        // this.captureTwoCTwoPAmount(captureID, orderPrimaryId, localOrderNo);
      });
  }
  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  retrieve_charge_details = (captureID, localOrderNo, orderPrimaryId) => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      charge_id: captureID,
      app_id: appId,
      order_primary_id: orderPrimaryId,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(
        apiUrl + "paymentv1/retrieve_charge_details",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (
          res.data.status === "ok" &&
          res.data.payment_status === "successful"
        ) {
          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.showSuccessPage(localOrderNo);
            }.bind(this)
          );
        } else if (
          res.data.status === "ok" &&
          res.data.payment_status === "pending"
        ) {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        } else {
          var magnfPopup = $.magnificPopup.instance;
          var msgTxt =
            res.data.message != ""
              ? res.data.message
              : "Your order was not successful.";
          showAlert("Error", msgTxt, magnfPopup);
          if ($(".alert_popup").length > 0) {
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        }
      });
  };

  /* sucess page */
  showSuccessPage(localOrderNo, orderPrimaryId) {
    /* this.deleteOrderCookie(); */
    /*this.sendMailFun(orderPrimaryId);*/
    cookie.save("ChkOrderid", localOrderNo, cookieConfig);
    const { history } = this.props;
    //setTimeout(function () {
    $.magnificPopup.close();
    var classThis = this;
    if (
      classThis.state.globalSettings.client_enable_auto_push_delivery !== "" &&
      typeof classThis.state.globalSettings.client_enable_auto_push_delivery !==
        undefined &&
      typeof classThis.state.globalSettings.client_enable_auto_push_delivery !==
        "undefined" &&
      this.state.defaultAvilTy === deliveryId
    ) {
      if (
        classThis.state.globalSettings.client_enable_auto_push_delivery === "1"
      ) {
        history.push("/findingdriver/" + localOrderNo);
        return false;
      }
    } else {
      this.props.destroyCartDetail();
      history.push("/thankyou/" + localOrderNo);
    }

    //}, 450);
  }

  sendMailFun(orderPrimaryId) {
    if (orderPrimaryId != "") {
      var urlShringTxt =
        apiUrl +
        "ordersv1/order_email?app_id=" +
        appId +
        "&order_primary_id=" +
        orderPrimaryId +
        "&mode=Cron";
      axios.get(urlShringTxt, headerconfig).then((res) => {});
    }
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();
    cookie.remove("defaultAvilablityId", cookieConfig);
    cookie.remove("orderOutletId", cookieConfig);
    cookie.remove("orderPaymentMode", cookieConfig);
    cookie.remove("orderTableNo", cookieConfig);
    cookie.remove("product_remarks", cookieConfig);
    cookie.remove("carttotalitems", cookieConfig);
    cookie.remove("cartsubtotal", cookieConfig);
    cookie.remove("cartid", cookieConfig);

    /* Delivery avilablity */

    cookie.remove("unitNoOne", cookieConfig);
    cookie.remove("unitNoTwo", cookieConfig);
    cookie.remove("firstNavigation", cookieConfig);

    cookie.remove("promotion_id", cookieConfig);
    cookie.remove("promotion_applied", cookieConfig);
    cookie.remove("promotion_code", cookieConfig);
    cookie.remove("promotion_delivery_charge_applied", cookieConfig);
    cookie.remove("promotion_amount", cookieConfig);
    cookie.remove("promotion_category", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);
    cookie.remove("promoOutlet", cookieConfig);
    cookie.remove("promoType", cookieConfig);
    cookie.remove("promoPercentage", cookieConfig);

    /*Remove voucher*/
    cookie.remove("voucher_applied", cookieConfig);
    cookie.remove("voucher_code", cookieConfig);
    cookie.remove("voucher_amount", cookieConfig);

    cookie.remove("points_redeemed", cookieConfig);
    cookie.remove("points_used", cookieConfig);
    cookie.remove("points_amount", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);
  }

  choosePayment(paymentMode) {
    if (paymentMode === "Cash") {
      this.setState({ paymentmodevalue: "Cash" });
    } else if (paymentMode === "Stripe") {
      this.setState({ paymentmodevalue: "Stripe" });
    } else if (paymentMode === "2C2P") {
      this.setState({ paymentmodevalue: "2C2P" });
    } else {
      this.setState({ paymentmodevalue: "promotion" });
    }
    this.postOrder(2, "Yes");
  }

  /* stripe description  */
  stripeDescription() {
    //userName
    if (
      typeof cookie.load("UserLname") !== "undefined" &&
      cookie.load("UserLname") !== ""
    ) {
      return cookie.load("UserFname") + " " + cookie.load("UserLname");
    } else {
      return cookie.load("UserLname");
    }
  }

  /* post stripe account */
  onToken = (token) => {
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      midClick: true,
      closeOnBgClick: false,
    });

    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr
    );

    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);

    /*  load process html */
    var postObject = {};
    postObject = {
      app_id: appId,
      token: token.id,
      stripe_envir: this.state.globalSettings.stripe_envir,
      stripe_key: this.state.globalSettings.stripe_public_key,
      customer_id: cookie.load("UserId"),
      paid_amount: payAmount,
      outlet_name:
        typeof cookie.load("orderOutletName") === "undefined"
          ? cookie.load("orderOutletName")
          : "",
      payment_reference: stripeReference,
    };

    this.postOrder(2, "Yes"); // To validate the order

    if (this.state.orderValidFail === 0) {
      axios
        .post(apiUrl + "payment/stripeTokenPay", qs.stringify(postObject))
        .then((res) => {
          if (res.data.status === "ok") {
            var captureID = res.data.result_set.payment_reference_1;
            this.setState({ validateimage: tickImage });
            this.postOrder(3, "", captureID, "Stripe");
          } else if (res.data.status === "error") {
            $.magnificPopup.close();
            this.paymentFail(
              "Error",
              "Error code: 1001 Oops! Something went wrong! Please try again."
            );
            if ($(".warining_popup").length > 0) {
              $.magnificPopup.open({
                items: {
                  src: ".warining_popup",
                },
                type: "inline",
              });
            }
            return false;
          }
        })
        .catch((error) => {
          $.magnificPopup.close();
          this.paymentFail(
            "Error",
            "Error code: 1002 Oops! Unable to connect to server. Please try again."
          );
          if ($(".warining_popup").length > 0) {
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
          }
          return false;
        });
    } else {
      $.magnificPopup.close();
      this.paymentFail(
        "Error",
        "Error code:1003 Oops! Something went wrong! Please try again."
      );
      if ($(".warining_popup").length > 0) {
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
      }

      return false;
    }
  };

  selectTimeSlotPopup() {
    $.magnificPopup.open({
      items: {
        src: "#orderdatetimeslot-popup",
      },
      type: "inline",
    });
  }

  orderPayValidaion(payGetWayType, event) {
    event.preventDefault();
    this.postOrder(2, "Yes", "", payGetWayType, "Yes");
  }
  ProceedPayValidaion(payGetWayType, event) {
    event.preventDefault();
    if (this.state.placeOrderEnable == 1) {
      this.postOrder(2, "Yes", "", payGetWayType, "Yes");
    } else {
      $.magnificPopup.close();
    }
  }

  changeAddrrFun(event) {
    event.preventDefault();
    $("#changeaddress").val("1");
    if ($("#warning-popup").length > 0) {
      $.magnificPopup.open({
        items: {
          src: "#warning-popup",
        },
        type: "inline",
      });
    }
    return false;
  }

  CheckTarmsContnChk() {
    var tarmsContn = this.state.chk_tarms_contn;
    var chkBox = false;
    if (tarmsContn === "Yes") {
      chkBox = true;
    }
    return chkBox;
  }

  changeTarmsContnChk() {
    var tarmsContn = this.state.chk_tarms_contn;
    if (tarmsContn === "Yes") {
      this.setState({ chk_tarms_contn: "No" });
    } else {
      this.setState({ chk_tarms_contn: "Yes" });
    }
    $(".tarms_chkbox_div").removeClass("err_tarms_chk");
  }

  /* For Advanced Slot */
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
    } else if (field == "updatedTat") {
      cookie.save("orderTAT", value, cookieConfig);
    } else if (field == "triggerErrorPopup") {
      if ($("#outlet-error-popup").length > 0) {
        $.magnificPopup.open({
          items: {
            src: "#outlet-error-popup",
          },
          type: "inline",
        });
      }
    }
  };

  chkProStockCls(proSlug, Stock) {
    var returnText = "products-single-div no-stock-product " + proSlug;
    if (Stock > 0) {
      returnText = "products-single-div " + proSlug;
    }

    return returnText;
  }

  /* Addon product Listing */
  addonProductListing() {
    var addonProductlst = this.props.addonproductlist;
    var clientProductStock = "";
    if (Object.keys(this.state.globalSettings).length > 0) {
      clientProductStock = this.state.globalSettings.client_product_stock;
    }
    if (Object.keys(addonProductlst).length > 0) {
      if (Object.keys(addonProductlst[0].subcategorie).length > 0) {
        var slugType = "category";
        var slugValue = addonProductlst[0].subcategorie[0].pro_cate_slug;
        var addonProlstOnly = addonProductlst[0].subcategorie[0].products;
        var addonCommonImg = this.props.addonproductcommon.product_image_source;
        var tagImageSource = this.props.addonproductcommon.tag_image_source;
        const Phtml = addonProlstOnly.map((product, index) => (
          <div
            className={this.chkProStockCls(
              product.product_slug,
              product.product_stock,
              clientProductStock
            )}
            id={"proIndex-" + product.product_primary_id}
            key={index}
          >
            <div className="products-image-div">
              {product.product_thumbnail !== "" ? (
                <img src={addonCommonImg + "/" + product.product_thumbnail} />
              ) : (
                <img src={noimage} />
              )}
            </div>

            <div className="product-info-div">
              <div className="product-title-maindiv">
                <div className="product-title">
                  <h3>
                    {product.product_alias !== ""
                      ? stripslashes(product.product_alias)
                      : stripslashes(product.product_name)}
                  </h3>
                </div>
                {Object.keys(product.product_tag).length > 0 ? (
                  <div className="product-tag-list">
                    <ul>
                      {product.product_tag.map((producttag, index1) => {
                        return (
                          <li key={index1}>
                            {producttag.pro_tag_image !== "" ? (
                              <img
                                src={tagImageSource + producttag.pro_tag_image}
                              />
                            ) : (
                              ""
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="product-short-description">
                <p>
                  {product.product_short_description !== null &&
                  product.product_short_description !== ""
                    ? Parser(stripslashes(product.product_short_description))
                    : Parser("&nbsp;")}
                </p>
              </div>

              <div className="product-price">
                <h3>{showPriceValue(product.product_price)}</h3>
              </div>

              <div className="products-ordernow-action">
                {product.product_stock > 0 || product.product_stock === null ? (
                  product.product_type === "1" ? (
                    <a
                      className="button order_nowdiv smiple_product_lk disbl_href_action"
                      href="/"
                      onClick={this.addToCartSimple.bind(
                        this,
                        product,
                        "initial"
                      )}
                    >
                      Add to Cart
                    </a>
                  ) : (
                    <a
                      href="/"
                      onClick={this.viewProDetail.bind(this, product)}
                      title="Product Details"
                      id={"comboPro-" + product.product_slug}
                      className="button order_nowdiv compo_product_lk"
                    >
                      Order Now
                    </a>
                  )
                ) : (
                  <a
                    className="button order_nowdiv disabled disbl_href_action"
                    href="/"
                  >
                    Sold Out
                  </a>
                )}

                <div className="addcart_row addcart_done_maindiv">
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.proQtyAction.bind(
                        this,
                        product.product_primary_id,
                        "decr"
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      className="proqty_input"
                      readOnly
                      value="1"
                    />
                    <span
                      className="qty_plus"
                      onClick={this.proQtyAction.bind(
                        this,
                        product.product_primary_id,
                        "incr"
                      )}
                    >
                      +
                    </span>
                  </div>
                  <button
                    className="btn btn_black"
                    onClick={this.addToCartSimple.bind(this, product, "done")}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        ));

        return Phtml;
      }
    } else {
      return false;
    }
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      $("#proIndex-" + IndexFlg).addClass("active");
      $("#proIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      showLoader("proIndex-" + IndexFlg, "Idtext");
      var availabilityId = this.state.defaultAvilTy;
      /*var availabilityId = deliveryId;*/

      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId = this.state.UserId;
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        login_type:
          typeof cookie.load("userAccountType") === "undefined"
            ? ""
            : cookie.load("userAccountType"),
        reference_new_id: getReferenceID(),
      };

      axios
        .post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.sateValChange("cartflg", "yes");
            removePromoCkValue();
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            /*showCartLst();*/
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
          }

          return false;
        });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    if ($(".alert_popup").length > 0) {
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var productSlug = productDetail.product_slug;
    if (productSlug !== "") {
      $("#proIndex-" + productDetail.product_primary_id).addClass("active");
      this.sateValChange("view_pro_data", productSlug);
    }
    return false;
  }

  opentDeliveryInfo() {
    $.magnificPopup.open({
      items: {
        src: "#warning-delivery-info-popup",
      },
      type: "inline",
    });
  }

  setOrderDateTimeText() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    var seletedOrdSlotTxt = this.state.seleted_ord_slotTxt;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
      /* For Advanced Slot */
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();
      var orderDateTime = new Date(OrderDate);
      orderDateTime.setHours(OrderHours);
      orderDateTime.setMinutes(OrderMunts);
      orderDateTime.setSeconds(OrderSecnd);
      var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
      var deliveryTime =
        this.convPad(OrderHours) +
        ":" +
        this.convPad(OrderMunts) +
        ":" +
        this.convPad(OrderSecnd);

      cookie.save("orderDateTime", orderDateTime, cookieConfig);
      cookie.save("deliveryDate", deliveryDate, cookieConfig);
      cookie.save("deliveryTime", deliveryTime, cookieConfig);

      /* For Advanced Slot */
      var slotType = this.state.slotType;
      var orderSlotVal = "",
        orderSlotTxt = "",
        orderSlotStrTime = "",
        orderSlotEndTime = "";
      if (slotType === "2") {
        orderSlotVal = this.state.seleted_ord_slot;
        orderSlotTxt = this.state.seleted_ord_slotTxt;
        orderSlotStrTime = this.state.seleted_ord_slot_str;
        orderSlotEndTime = this.state.seleted_ord_slot_end;
      }

      cookie.save("slotType", slotType, cookieConfig);
      cookie.save("orderSlotVal", orderSlotVal, cookieConfig);
      cookie.save("orderSlotTxt", orderSlotTxt, cookieConfig);
      cookie.save("orderSlotStrTime", orderSlotStrTime, cookieConfig);
      cookie.save("orderSlotEndTime", orderSlotEndTime, cookieConfig);

      if (seletedOrdSlotTxt !== "") {
        var fnlTimeStr = dateFormat(orderDateTime, "ddd, dd mmm yyyy");
        var dp_datetimetext = fnlTimeStr + ", " + seletedOrdSlotTxt;
      } else {
        var dp_datetimetext = dateFormat(
          orderDateTime,
          "ddd, dd mmm yyyy, h:MM:ss TT"
        );
      }

      this.setState(
        { delivery_pickup_datetimetext: dp_datetimetext },
        function () {
          $.magnificPopup.close();
          /*$.magnificPopup.open({
            items: {
              src: "#order-popup",
            },
            type: "inline",
          });*/
        }.bind(this)
      );
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  /* Advanced Slot */
  showOrdTimeSlot() {
    var TodayDate = new Date();
    var deliveryDate =
      cookie.load("deliveryDate") !== "" &&
      typeof cookie.load("deliveryDate") !== undefined &&
      typeof cookie.load("deliveryDate") !== "undefined" &&
      cookie.load("deliveryDate") !== undefined &&
      cookie.load("deliveryDate") !== "undefined"
        ? cookie.load("deliveryDate")
        : "";
    if (deliveryDate !== "") {
      var order_date_exist = "";
      if (deliveryDate !== "") {
        var delivery_Date = deliveryDate.split("/");
        TodayDate = new Date(new Date(TodayDate).setDate(delivery_Date[0]));
        TodayDate = new Date(
          new Date(TodayDate).setMonth(delivery_Date[1] - 1)
        );
        TodayDate = new Date(new Date(TodayDate).setFullYear(delivery_Date[2]));

        order_date_exist = TodayDate;
      }

      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined" &&
        cookie.load("deliveryTime") !== undefined &&
        cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";
      var order_time_exist = "";
      if (deliveryTime !== "") {
        var delivery_Time = deliveryTime.split(":");
        TodayDate = new Date(new Date(TodayDate).setHours(delivery_Time[0]));
        TodayDate = new Date(new Date(TodayDate).setMinutes(delivery_Time[1]));
        TodayDate = new Date(new Date(TodayDate).setSeconds(delivery_Time[2]));
        order_time_exist = TodayDate;
      }
      var order_is_timeslot =
        cookie.load("slotType") !== "" &&
        typeof cookie.load("slotType") !== undefined &&
        typeof cookie.load("slotType") !== "undefined" &&
        cookie.load("slotType") !== undefined &&
        cookie.load("slotType") !== "undefined"
          ? cookie.load("slotType")
          : 1;

      var orderSlotEndTime =
        cookie.load("orderSlotEndTime") !== "" &&
        typeof cookie.load("orderSlotEndTime") !== undefined &&
        typeof cookie.load("orderSlotEndTime") !== "undefined" &&
        cookie.load("orderSlotEndTime") !== undefined &&
        cookie.load("orderSlotEndTime") !== "undefined"
          ? cookie.load("orderSlotEndTime")
          : "";
      var orderSlotStrTime =
        cookie.load("orderSlotStrTime") !== "" &&
        typeof cookie.load("orderSlotStrTime") !== undefined &&
        typeof cookie.load("orderSlotStrTime") !== "undefined" &&
        cookie.load("orderSlotStrTime") !== undefined &&
        cookie.load("orderSlotStrTime") !== "undefined"
          ? cookie.load("orderSlotStrTime")
          : "";
      var ordTmSlt = moment(order_date_exist).format("hh:mm A");

      if (
        order_is_timeslot === "2" &&
        orderSlotStrTime !== "" &&
        orderSlotEndTime !== ""
      ) {
        var slotTime1 =
          orderSlotStrTime !== "" ? orderSlotStrTime.split(":") : Array();
        var slotTime2 =
          orderSlotEndTime !== "" ? orderSlotEndTime.split(":") : Array();

        if (
          Object.keys(slotTime1).length > 0 &&
          Object.keys(slotTime2).length > 0
        ) {
          var startTimeVal = parseInt(slotTime1[0]);
          var startMinitVal = parseInt(slotTime1[1]);
          var strdatevalobj = new Date();
          strdatevalobj.setHours(startTimeVal);
          strdatevalobj.setMinutes(startMinitVal);

          var endTimeVal = parseInt(slotTime2[0]);
          var endMinitVal = parseInt(slotTime2[1]);
          var enddatevalobj = new Date();
          enddatevalobj.setHours(endTimeVal);
          enddatevalobj.setMinutes(endMinitVal);

          ordTmSlt =
            format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
        }
      }

      return format(order_date_exist, "iii dd LLL yyyy") + " " + ordTmSlt;
    }
  }

  switchToAvilTy() {
    this.setState({ trgavliblpopupFlg: "yes" });
  }
  changeAddressPopup() {
    this.setState({ changeAddresspopupFlg: "yes" });
  }
  render() {
    var showRdmPoints =
      parseFloat(this.state.reward_point_count) -
      parseFloat(this.state.used_reward_point);
    showRdmPoints = showRdmPoints.toFixed(2);
    var InptRdmPoints =
      parseFloat(this.state.reward_point_count) > 0
        ? this.state.reward_point_count
        : 0;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      this.props.maxCart,
      this.state.cartItems,
      this.state.outletTax
    );

    var outlet = (this.props.outletslist || []).find(
      (o) => o.outlet_id === cookie.load("orderOutletId")
    );
    console.log(this.props.outletslist);

    var subTotalAmount = parseFloat(calculatedAmount["cartSubTotalAmount"]);
    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);

    var TodayDate = new Date();
    var deliveryDate =
      cookie.load("deliveryDate") !== "" &&
      typeof cookie.load("deliveryDate") !== undefined &&
      typeof cookie.load("deliveryDate") !== "undefined" &&
      cookie.load("deliveryDate") !== undefined
        ? cookie.load("deliveryDate")
        : "";
    var order_date_exist = "";
    if (deliveryDate !== "") {
      var delivery_Date = deliveryDate.split("/");
      TodayDate = new Date(new Date(TodayDate).setDate(delivery_Date[0]));
      TodayDate = new Date(new Date(TodayDate).setMonth(delivery_Date[1] - 1));
      TodayDate = new Date(new Date(TodayDate).setFullYear(delivery_Date[2]));

      order_date_exist = TodayDate;
    }

    var deliveryTime =
      cookie.load("deliveryTime") !== "" &&
      typeof cookie.load("deliveryTime") !== undefined &&
      typeof cookie.load("deliveryTime") !== "undefined" &&
      cookie.load("deliveryTime") !== undefined
        ? cookie.load("deliveryTime")
        : "";
    var order_time_exist = "";
    if (deliveryTime !== "") {
      var delivery_Time = deliveryTime.split(":");
      TodayDate = new Date(new Date(TodayDate).setHours(delivery_Time[0]));
      TodayDate = new Date(new Date(TodayDate).setMinutes(delivery_Time[1]));
      TodayDate = new Date(new Date(TodayDate).setSeconds(delivery_Time[2]));
      order_time_exist = TodayDate;
    }

    var title = "Your Self Collection Details";

    if (cookie.load("defaultAvilablityId") === deliveryId) {
      title = "Your Delivery Details";
    } else if (cookie.load("defaultAvilablityId") === dineinId) {
      title = "Your Dine In Details";
    }

    return (
      <div className="checkout-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          trgavliblpopupFlg={this.state.trgavliblpopupFlg}
          changeAddresspopupFlg={this.state.changeAddresspopupFlg}
          sateValChange={this.sateValChange}
        />
        {/* Header End */}
        <div className="chkout-main-header">
          <h2>Checkout</h2>
        </div>

        {/* container - start */}
        <div className="container">
          {/* check_out_fourcommon - start */}
          <div className="check_out_fourcommon">
            <div className="container-one">
              {/* checkoutpage_form_outer - start */}
              <div className="checkoutpage_form_outer">
                <form
                  id="checkoutpage_form"
                  className=" form_sec checkout-total"
                  method="post"
                  acceptCharset="utf-8"
                >
                  <div className="cdd-details">
                    {/* cdd-details-lhs - start */}
                    <div className="cdd-details-lhs">
                      {/* <div className="checkout-top-header">
                        <div className="checkout-top-left">
                          <h2>
                            <span>Hi</span> {this.state.customer_first_name}{" "}
                            {this.state.customer_last_name}
                          </h2>
                        </div>
                        <div className="checkout-top-right">
                          <Link
                            to={"/logout"}
                            class="check_link"
                            title="Sign out"
                          >
                            Sign out
                          </Link>
                        </div>
                      </div> */}
                      <div className="text-center checkout-inner-header">
                        <span className="text-uppercase">{title}</span>
                      </div>
                      <div className="checkout-body-section">
                        {cookie.load("defaultAvilablityId") === deliveryId && (
                          <div className="checkout-delivery-details">
                            <div className="checkout-delivery-lhs">
                              <label className="chk_hea">
                                Order Handled By
                              </label>
                              <p>{cookie.load("orderOutletName")}</p>
                              <p>{cookie.load("orderHandledByText")}</p>
                            </div>
                            <div className="checkout-delivery-rhs">
                              <div className="checkout-control-group-top">
                                <label className="chk_hea">
                                  Delivery Location
                                </label>

                                <p>
                                  {this.state.order_delivery_address},{" "}
                                  {CountryTxt} {this.state.order_postcode}
                                </p>

                                <div className="form-group controls-three-small">
                                  <span>#</span>

                                  <div className="cthree-small-inner">
                                    <div
                                      className={
                                        this.state.unitnumber1 != ""
                                          ? "focus-out focused"
                                          : "focus-out"
                                      }
                                    >
                                      <input
                                        placeholder="Block"
                                        type="text"
                                        id="unit_no1_id"
                                        name="unit_no1"
                                        value={this.state.unitnumber1}
                                        className="form-control input-focus"
                                        onChange={this.handleAddrChange.bind(
                                          this
                                        )}
                                      />
                                    </div>
                                    <div
                                      className={
                                        this.state.unitnumber2 != ""
                                          ? "focus-out focused"
                                          : "focus-out"
                                      }
                                    >
                                      <input
                                        placeholder="Unit No"
                                        type="text"
                                        name="unit_no2"
                                        value={this.state.unitnumber2}
                                        className="form-control input-focus"
                                        onChange={this.handleAddrChange.bind(
                                          this
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <a
                                  href="/"
                                  onClick={this.changeAddrrFun.bind(this)}
                                  className=""
                                >
                                  Change Delivery Location
                                </a>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="checkout-pickup-main">
                          {cookie.load("defaultAvilablityId") === pickupId && (
                            <div className="checkout-pickup-group">
                              <div className="checkout-pickup-group-top">
                                <label className="chk_hea">
                                  Pickup Location
                                </label>
                                <p>
                                  {cookie.load("orderOutletName")} <br />
                                  {cookie.load("orderHandledByText")}
                                </p>
                                <div className="pickup-group-link">
                                  <a
                                    href="/"
                                    onClick={this.changeAddrrFun.bind(this)}
                                    className="change-pickup-location"
                                  >
                                    Change Pickup Location
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}

                          {cookie.load("defaultAvilablityId") === dineinId && (
                            <div className="checkout-control-group-top">
                              <label className="chk_hea">
                                {cookie.load("orderOutletName")}
                                <span>
                                  {outlet ? outlet.outlet_open_time : ""}
                                </span>
                              </label>
                              {/* <div className="col-xs-cls">
                                <p>{cookie.load("orderHandledByText")}</p>
                              </div> */}

                              {/* <label className="chk_hea">
                                Dine In Date & Time:{" "}
                                {moment(orderDateTmTxt).format(
                                  "DD/MM/YYYY hh:mm A"
                                )}
                              </label> */}
                              <label className="chk_hea">
                                Table
                                <span>{cookie.load("orderTableNo")}</span>
                              </label>
                            </div>
                          )}

                          <div className="checkout-pickup-group-middle">
                            <div className="form-group1234">
                              <OrderAdvancedDatetimeSlot
                                {...this.props}
                                hdrState={this.state}
                                setdateTimeFlg={this.setdateTimeFlg}
                                indutualText={true}
                                labelDateName={
                                  cookie.load("defaultAvilablityId") ===
                                  deliveryId
                                    ? "Delivery Date"
                                    : cookie.load("defaultAvilablityId") ===
                                      pickupId
                                    ? "Takeaway Date"
                                    : "Dine In Date"
                                }
                                labelTimeName={
                                  cookie.load("defaultAvilablityId") ===
                                  deliveryId
                                    ? "Delivery Time"
                                    : cookie.load("defaultAvilablityId") ===
                                      pickupId
                                    ? "Takeaway Time"
                                    : "Dine In Time"
                                }
                              />

                              <div className="ordrdatetime_error"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {cookie.load("defaultAvilablityId") === deliveryId && (
                        <div className="checkout-billing-address">
                          <label className="chk_hea">
                            Billing Address{" "}
                            <span>
                              Same As Delivery Address{" "}
                              <div className="custom_checkbox">
                                <input
                                  type="checkbox"
                                  name="bill_chk"
                                  className="addon_list_single"
                                  onChange={this.changeBillingAddrChk.bind(
                                    this
                                  )}
                                  checked={this.CheckBillingAddrChk("checkbox")}
                                />
                                <span></span>
                              </div>
                            </span>
                          </label>
                          <div
                            className="check-billing"
                            style={{
                              display: this.CheckBillingAddrChk("display"),
                            }}
                          >
                            <div className="form-group controls-three">
                              <div className="form-group">
                                <div
                                  className={
                                    this.state.billing_postcode != ""
                                      ? "focus-out focused"
                                      : "focus-out"
                                  }
                                >
                                  <label>Postal Code</label>
                                  <input
                                    type="text"
                                    name="billing_postcode"
                                    value={this.state.billing_postcode}
                                    onChange={this.changePostalCode.bind(
                                      this,
                                      "bill"
                                    )}
                                    onKeyPress={(e) => this.validateIntval(e)}
                                    className="form-control input-focus"
                                  />
                                </div>
                              </div>
                              <span>#</span>
                              <div
                                className={
                                  this.state.billunitnumber1 != ""
                                    ? "focus-out focused"
                                    : "focus-out"
                                }
                              >
                                <label className="unit-num">Unit No 01</label>
                                <input
                                  type="text"
                                  name="bill_unit_no1"
                                  value={this.state.billunitnumber1}
                                  className="form-control input-focus"
                                  onChange={this.handleAddrChange.bind(this)}
                                />
                              </div>
                              <div
                                className={
                                  this.state.billunitnumber2 != ""
                                    ? "focus-out focused"
                                    : "focus-out"
                                }
                              >
                                <label className="unit-num">Unit No 02</label>
                                <input
                                  type="text"
                                  name="bill_unit_no2"
                                  value={this.state.billunitnumber2}
                                  className="form-control input-focus"
                                  onChange={this.handleAddrChange.bind(this)}
                                />
                              </div>
                            </div>
                            <div className="form-group chk_daddress">
                              <div className="focus-out controls-single">
                                <input
                                  type="text"
                                  className="form-control input-focus"
                                  placeholder="Address"
                                  readOnly
                                  value={this.state.billing_delivery_address}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* cdd-details-lhs - end */}

                    {/* cdd-details-rhs - start */}
                    <div className="cdd-details-rhs fl">
                      <div className="chekout_cart_bar">
                        <div className="cart-header">
                          <div className="text-center checkout-heading">
                            <span className="text-uppercase">
                              Your Order Details
                            </span>
                          </div>
                        </div>
                        {this.loadCartOverallData()}
                      </div>
                    </div>

                    {/* cdd-details-rhs - end */}
                  </div>
                </form>
              </div>
              {/* checkoutpage_form_outer - end */}
            </div>
          </div>
          {/* check_out_fourcommon - end */}
        </div>
        {/* container - end */}

        {/*Payment confirm popup Start*/}
        {/*Payment confirm popup Start*/}
        <div
          id="pay-conf-popup"
          className="white-popup mfp-hide popup_sec pay-conf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">Alert!</div>
                <div className="alert_body">
                  <p>Awesome ! You are all set</p>
                  <div className="alt_btns">
                    {this.state.globalSettings.stripe_public_key &&
                      cookie.load("UserMobile") != "" &&
                      this.state.paymentmodevalue == "Stripe" &&
                      this.state.stripe_payment_enable === 1 && (
                        <StripeCheckout
                          name={stripeCompany}
                          image={stripeImage}
                          description={this.stripeDescription()}
                          token={this.onToken}
                          stripeKey={
                            this.state.globalSettings.stripe_public_key
                          }
                          amount={payAmount * 100}
                          email={cookie.load("UserEmail")}
                          currency={stripeCurrency}
                        >
                          {" "}
                          <a
                            href="javascript:;"
                            className="button"
                            title="Proceed To Payment"
                          >
                            Proceed To Payment
                          </a>
                        </StripeCheckout>
                      )}
                    {this.state.globalSettings.client_two_c_two_p_merchant_id !=
                      "" &&
                      this.state.customer_phone != "" &&
                      this.state.paymentmodevalue == "2C2P" &&
                      this.state.client_two_c_two_p_secret_key != "" &&
                      this.state.two_c_two_p_payment_enable === 1 && (
                        <a
                          href="/"
                          className="button"
                          onClick={this.orderProceedTwoCTwoP.bind(this)}
                          title="Proceed To Payment"
                        >
                          Proceed To Payment
                        </a>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment confirm popup - End*/}
        <div
          id="pay-conf-popup_old"
          className="white-popup mfp-hide popup_sec pay-conf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="edit-alert-body">
                <h4>Alert!</h4>
                <p>Awesome ! You are all set</p>
                <div className="alt_btns-alert">
                  {this.state.globalSettings.client_two_c_two_p_merchant_id !=
                    "" &&
                    this.state.customer_phone != "" &&
                    this.state.paymentmodevalue == "2C2P" &&
                    this.state.client_two_c_two_p_secret_key != "" &&
                    this.state.two_c_two_p_payment_enable === 1 && (
                      <a
                        href="/"
                        className="button"
                        onClick={this.orderProceedTwoCTwoP.bind(this)}
                        title="Proceed To Payment"
                      >
                        Proceed To Payment
                      </a>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment confirm popup - End*/}
        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">We Are Processing Your Order</h3>
            {this.state.twoCtwoPPayment_loading ? (
              <div className="process_inner">
                {this.onlineTwoCPaymentLoading()}
              </div>
            ) : (
              <div className="process_inner">
                {this.onlinePaymentLoading()}
                {this.orderBCLoading()}
                {this.amountCaptureLoading()}
              </div>
            )}
          </div>
        </div>

        <div id="dvLoading"></div>
        {/*2C2P form DIV*/}
        <div id="two-c-two-p-div" style={{ display: "none" }}></div>
        {/* Footer section */}
        <Footer />

        <ProductDetail
          {...this.props}
          productState={this.state}
          sateValChange={this.sateValChange}
        />

        {/* date time popup - Start */}
        {/* <div
          id="orderdatetimeslot-popup"
          className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime small-popup orderdatetimeslot-popupdiv"
        >
          <div className="order-body">
            <div className="datetime_selt_sec">
              <div className="datetime_hea_row">
                <div className="header-with-icon">
                  <h4>
                    {this.state.seletedAvilablityId === deliveryId
                      ? "Delivery date & time"
                      : "Pick-up date & time"}{" "}
                  </h4>
                </div>
              </div>

              <OrderAdvancedDatetimeSlot
                {...this.props}
                hdrState={this.state}
                setdateTimeFlg={this.setdateTimeFlg}
                showDateOnly={this.state.showDateOnly}
              />

              <div className="ordrdatetime_error"></div>
            </div>

            <input
              type="button"
              onClick={this.setOrderDateTimeText.bind(this)}
              className="button"
              value="Continue"
            />
          </div>
        </div> */}
        {/* date time popup popup - end */}

        {/* Promotion popup - Start */}
        <div
          id="open-promo-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <Promocode sateValChange={this.sateValChange} />
        </div>
        {/* Promotion popup - End */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var cartOutletList = 0;
  var cartOutlet = Array();
  var cartOutletslug = Array();
  var cartoutletavailability = Array();
  var cartTotalItmCount = 0;
  var productLeadtime = 0;
  var siteLocID = "";
  var maxCart = 0;
  var cartStatus = "";
  var cartTax = Array();
  var outletTat = 15;
  var cartGroups = Array();

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      productLeadtime = resultSetArr.product_lead_time;
      cartOutletList = resultSetArr.cartoutlet;
      cartOutletslug = resultSetArr.outletslug;
      cartOutlet = resultSetArr.outlets;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      cartoutletavailability = resultSetArr.cartoutletavailability;
      cartGroups = resultSetArr.cartGroups;
      siteLocID = resultSetArr.siteLocID;
      maxCart = resultSetArr.maxCart;
      cartTax = resultSetArr.tax;
      outletTat = resultSetArr.outletTat;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var activityCountArr = Array();
  if (Object.keys(state.activitycount).length > 0) {
    if (state.activitycount[0].status === "ok") {
      activityCountArr = state.activitycount[0].result_set;
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var addonProArr = Array();
  var addonCommonArr = Array();
  if (Object.keys(state.addonproduct).length > 0) {
    if (
      state.addonproduct[0].status === "ok" &&
      Object.keys(state.addonproduct[0].result_set).length > 0
    ) {
      addonProArr = state.addonproduct[0].result_set;
      addonCommonArr = state.addonproduct[0].common;
    }
  }

  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }

  if (Object.keys(state.alloutlets).length > 0 && !outletsArr.length) {
    if (state.alloutlets[0].status === "ok") {
      outletsArr = state.alloutlets[0].result_set;
    }
  }

  return {
    activitycountArr: activityCountArr,
    settingsArr: globalSettings,
    staticblack: blacksArr,
    zonedetails: zonedetailArr,
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    productLeadtime: productLeadtime,
    cartOutletList: cartOutletList,
    outletslist: outletsArr,
    cartOutlet: cartOutlet,
    cartOutletslug: cartOutletslug,
    siteLocID: siteLocID,
    maxCart: maxCart,
    cartGroups: cartGroups,
    cartTax: cartTax,
    cartoutletavailability: cartoutletavailability,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    addonproductlist: addonProArr,
    addonproductcommon: addonCommonArr,
    outletTat: outletTat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty) => {
      dispatch({ type: UPDATE_CART_DETAIL, productId, cartItemId, cartQty });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getAddonProList: (outletId) => {
      dispatch({ type: GET_ADDONPRODUCT, outletId });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Checkout);
