/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { Helmet } from "react-helmet";
import axios from "axios";
import update from "immutability-helper";
import Moment from "moment";
import FacebookLogin from "react-facebook-login";
import { format } from "date-fns";
var dateFormat = require("dateformat");

import {
  appId,
  apiUrl,
  apiUrlV2,
  siteURL,
  deliveryId,
  pickupId,
  projectTitle,
  shopType,
  headerconfig,
  cookieConfig,
  fbAppId,
  restaurantLink,
  dineinId,
} from "../Helpers/Config";

import {
  GET_GLOBAL_SETTINGS,
  GET_ZONE_DETAIL,
  GET_PICKUP_OUTLETS,
  GET_ALL_OUTLETS,
  DESTROY_CART_DETAIL,
  GET_LOGINDATA,
  GET_FBLOGINDATA,
  GET_FORGET_PASSWORD,
  GET_CHANGEPASSWORD,
  GET_REGISTRATION,
  GET_MENUDATA,
  GET_ALLUSERSECADDRDATA,
  GET_STATIC_BLOCK,
  GET_TABLEAVAILABILITY,
} from "../../actions";
import {
  getReferenceID,
  showAlert,
  showLoader,
  hideLoader,
  stripslashes,
  removeOrderDateTime,
  removePromoCkValue,
  addressFormat,
  showToastrAlert,
  getAliasName,
} from "../Helpers/SettingHelper";

import MenuNavigationmob from "./MenuNavigation/MenuNavigationmob";
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot";
import {
  Login,
  Forgotpassword,
  Signup,
  Guestcheckout,
  Changepassword,
} from "../../components/Myaccount/Index";

/* import images */
import mainLogo from "../../common/images/logo.png";
import iconUnhappy from "../../common/images/sad-smiley.png";
import iconWin from "../../common/images/icon-win.svg";
import warningImg from "../../common/images/warning.svg";
import mappin from "../../common/images/map-pin.svg";
import more from "../../common/images/readmore-dark.svg";
import searchicon from "../../common/images/search-purple.svg";
import closepurple from "../../common/images/close-purple.svg";
import dbw from "../../common/images/delivery-bike-white.svg";
import db from "../../common/images/delivery-bike.svg";
import tyw from "../../common/images/takeaway-white.svg";
import ty from "../../common/images/takeaway.svg";
import dinw from "../../common/images/dinein-white.svg";
import din from "../../common/images/dinein.svg";
import qrImg from "../../common/images/scan-code.png";
import popuplogo from "../../common/images/popuplogo.png";
import noimg from "../../common/images/no-img-product.png";

import CartSideBar from "./CartSideBar";
import { pick } from "underscore";
var base64 = require("base-64");
var Parser = require("html-react-parser");
class Header extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    var availability = cookie.load("defaultAvilablityId");

    var deliveryPincodeText =
      typeof cookie.load("deliveryPincodeText") === "undefined"
        ? ""
        : cookie.load("deliveryPincodeText");
    var pickupLocationText =
      typeof cookie.load("pickupLocationText") === "undefined"
        ? ""
        : cookie.load("pickupLocationText");
    var deliveryPickupDatetimetext =
      typeof cookie.load("deliveryPickupDatetimetext") === "undefined"
        ? ""
        : cookie.load("deliveryPickupDatetimetext");
    var seletedAvilablityId =
      typeof cookie.load("defaultAvilablityId") === "undefined"
        ? deliveryId
        : cookie.load("defaultAvilablityId");

    var pickupInfoArr = [];
    var deliveryInfoArr = [];
    if (
      (deliveryPincodeText !== "" || pickupLocationText !== "") &&
      deliveryPickupDatetimetext !== ""
    ) {
      var orderInfoDataArr = [];
      if (availability === deliveryId) {
        orderInfoDataArr["orderZoneId"] = cookie.load("orderZoneId");
        orderInfoDataArr["orderDeliveryAddress"] = cookie.load(
          "orderDeliveryAddress"
        );
        orderInfoDataArr["orderPostalCode"] = cookie.load("deliveryPostalCode");
      } else {
        orderInfoDataArr["orderPostalCode"] = cookie.load("orderPostalCode");
      }
      orderInfoDataArr["orderOutletId"] = cookie.load("orderOutletId");
      orderInfoDataArr["orderOutletName"] = cookie.load("orderOutletName");
      orderInfoDataArr["orderTAT"] = cookie.load("orderTAT");
      orderInfoDataArr["orderHandled"] = cookie.load("orderHandled");
      orderInfoDataArr["defaultAvilablityId"] = cookie.load(
        "defaultAvilablityId"
      );
      orderInfoDataArr["orderHandledByText"] =
        cookie.load("orderHandledByText");
      if (availability === deliveryId) {
        deliveryInfoArr = orderInfoDataArr;
      } else if (availability === pickupId) {
        pickupInfoArr = orderInfoDataArr;
      }
    }

    this.state = {
      currentPageUrl: this.props.location.pathname,
      slugRestaurant:
        this.props.match.params.slugRestaurant !== "" &&
        typeof this.props.match.params.slugRestaurant !== undefined &&
        typeof this.props.match.params.slugRestaurant !== "undefined"
          ? this.props.match.params.slugRestaurant
          : "",
      defaultAvilTy: availability,
      seletedAvilablityId: seletedAvilablityId,
      seletedOutletId: "",
      order_tat_time:
        cookie.load("orderTAT") !== "" &&
        typeof cookie.load("orderTAT") !== undefined &&
        typeof cookie.load("orderTAT") !== "undefined"
          ? cookie.load("orderTAT")
          : "",
      globalsettings: [],
      site_location_list: [],
      secondaryaddresslist: [],
      deliveryZipcodeList: [],
      deliveryOutlets: [],
      deliveryOutletsList: [],
      pickupOutlets: [],
      pickupOutletsList: [],
      delivery_outlet_id: "",
      searchProResult: [],
      selectedProResult: [],
      delivery_pincode_text: deliveryPincodeText,
      pickup_location_text: pickupLocationText,
      delivery_pickup_datetimetext: deliveryPickupDatetimetext,
      orderHandled: "",
      orderDeliveryAddress: "",
      pincode_input_value: "",
      nextavail: "",
      logindata: "",
      getDateTimeFlg: "",
      deliveryInfo: deliveryInfoArr,
      pickupInfo: pickupInfoArr,
      seleted_ord_date: "",
      seleted_ord_time: "",
      fields: {
        memberid: "",
        /*  first_name: "", */
        /* password: "", */
        /*  last_name: "", */
        member_customer_id: "",
        member_error: "",
      },
      fieldscheckout: {
        check_email: "",
        check_firstname: "",
        check_phone: "",
      },
      fpstatus: "initiating",
      regstatus: "initiating",
      fieldssignup: {
        firstname: "",
        email: "",
        password: "",
        rePassword: "",
        mobile: "",
        pdpa_consent: "",
        terms: "",
        completeReg: "N",
      },
      fieldschpassword: {
        oldpassword: "",
        newpassword: "",
        confirmpassword: "",
      },
      fieldsfgtpassword: {
        email: "",
      },
      menuData: [],
      selectedAvilHome: "No",
      cartOutletList: [],
      postalcodeInputVal: "",
      selectPostalCode: "No",
      selectPickupTime: "No",
      orderPostalCode:
        cookie.load("orderPostalCode") !== "" &&
        typeof cookie.load("orderPostalCode") !== undefined &&
        typeof cookie.load("orderPostalCode") !== "undefined"
          ? cookie.load("orderPostalCode")
          : "",
      staticblacks: [],
      headerInfo: "",
      headerlinks: "",

      getDateTimeFlg: "yes",
      seleted_ord_date: "",
      seleted_ord_time: "",
      seleted_ord_slot: "",
      seleted_ord_slotTxt: "",
      seleted_ord_slot_str: "",
      seleted_ord_slot_end: "",
      isAdvanced: "no",
      slotType: "2",
      search_keyword: "",
      trgavliblpopup: "no",
      showOrdDateOnly: "no",
      searchResult: [],
      searchResultDispaly: "",
    };
    this.props.getStaticBlock();
    this.props.getGlobalSettings();
    this.props.getPickupOutlets();
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availability === deliveryId && zoneIdTxt !== "") {
      this.props.getZoneDetail("", zoneIdTxt, 1);
    }
    this.props.getAllOutlets(deliveryId);
    this.props.getSecondaryAddress();
  }

  componentDidMount() {
    $("body").css("overflow", "auto");
    $("body").removeClass("mbl-hiden");
    if (
      cookie.load("loginpopupTrigger") !== "" &&
      typeof cookie.load("loginpopupTrigger") !== undefined &&
      typeof cookie.load("loginpopupTrigger") !== "undefined"
    ) {
      if (cookie.load("loginpopupTrigger") === "login") {
        $.magnificPopup.open({
          items: {
            src: "#login-main-popup",
          },
          type: "inline",
        });
        cookie.remove("loginpopupTrigger", cookieConfig);
      }
    }
    if (
      cookie.load("ordertypepopupTrigger") !== "" &&
      typeof cookie.load("ordertypepopupTrigger") !== undefined &&
      typeof cookie.load("ordertypepopupTrigger") !== "undefined"
    ) {
      if (cookie.load("ordertypepopupTrigger") === "yes") {
        $.magnificPopup.open({
          items: {
            src: "#order-popup",
          },
          type: "inline",
        });
        cookie.remove("ordertypepopupTrigger", cookieConfig);
      }
    }

    if (
      cookie.load("changeaddress") !== "" &&
      typeof cookie.load("changeaddress") !== undefined &&
      typeof cookie.load("changeaddress") !== "undefined"
    ) {
      if (cookie.load("changeaddress") === "yes") {
        $.magnificPopup.open({
          items: {
            src: "#order-popup",
          },
          type: "inline",
        });
        cookie.remove("changeaddress", cookieConfig);
      }
    }
    $(document)
      .off("click", ".open_cart_deatils")
      .on("click", ".open_cart_deatils", function (e) {
        $.magnificPopup.close();
        setTimeout(function () {
          $("body").addClass("cart-items-open");
          $(".hcart_dropdown").toggleClass("open");
        }, 10);
      });

    $(".custom_close").click(function (e) {
      e.preventDefault();
      $(".custom_alertcls, .custom_center_alertcls").hide();
    });
    $("html, body").animate(
      {
        scrollTop: $("body").offset().top,
      },
      100
    );
    $(".test-popup-link").magnificPopup({
      type: "image",
      showCloseBtn: true,
      verticalFit: true,
      callbacks: {
        change: function () {
          this.wrap.addClass("awardpopup");
        },
      },
      // other options
    });

    this.props.getMenuData("singapore-swimming-club-header");
    if ($(".open-popup-link").length > 0) {
      $(".open-popup-link").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    if ($(".trigger_login").length > 0) {
      $(".trigger_login").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    /* Input lable animation */
    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function () {
        $(this).parents(".focus-out").addClass("focused");
      });
      $(".input-focus").blur(function () {
        var inputValue = $(this).val();
        if (inputValue == "") {
          $(this).removeClass("filled");
          $(this).parents(".focus-out").removeClass("focused");
        } else {
          $(this).addClass("filled");
        }
      });
    }

    $("body").on("click", ".hsearch_trigger", function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(".search_result").hide();
      $(this).toggleClass("active");
      $(".hsearch_sec").toggleClass("open");
      $(".hsearch_sec .form-control").focus();
    });

    $(document).click(function (e) {
      if ($(".hsearch_trigger").length > 0) {
        if (
          !$(e.target).is(
            ".hsearch_trigger, .hsearch_sec, .hsearch_sec *, .htico_search, .htico_search img, .htico_search img "
          )
        ) {
          if ($(".hsearch_sec").is(":visible")) {
            $(".hsearch_sec").removeClass("open");
            $(".hsearch_trigger").removeClass("active");
            $(".hsearch_sec .form-control").blur();
          }
        }
      }
    });

    $("body").on("click", ".hsearch_trigger_mbl", function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(".search_result_mbl").hide();
      $(this).toggleClass("active");
      $(".hsearch_sec_mbl").toggleClass("open");
      $(".hsearch_sec_mbl .form-control").focus();
    });
    $(document).click(function (e) {
      if (
        !$(e.target).is(
          ".hsearch_trigger_mbl, .hsearch_sec_mbl, .hsearch_sec_mbl * "
        )
      ) {
        if ($(".hsearch_sec_mbl").is(":visible")) {
          $(".hsearch_sec_mbl").removeClass("open");
          $(".hsearch_trigger_mbl").removeClass("active");
          $(".hsearch_sec_mbl .form-control").blur();
        }
      }
    });
    /* } */

    if ($(".mobile_mainacc_menutrigger").length > 0) {
      $(".mobile_mainacc_menutrigger").click(function (e) {
        e.stopPropagation();
        if ($(".mobile_mainacc_menulist").is(":visible")) {
          $(".mobile_mainacc_menulist").hide();
        } else {
          $(".mobile_mainacc_menulist").show();
        }
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".mobile_account_item, .mobile_account_item * ")) {
          if ($(".mobile_mainacc_menulist").is(":visible")) {
            $(".mobile_mainacc_menulist").hide();
          }
        }
      });
    }

    /*NEW MENU SECTION*/
    if ($(".mobile_account_menu_click").length > 0) {
      $(".mobile_account_menu_click").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".mobile_account_menu").slideToggle();
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".arrow_myaccount, .mobile_account_menu_col")) {
          if ($(".mobile_account_menu").is(":visible")) {
            $(".mobile_account_menu").slideToggle();
          }
        }
      });
    }

    /* Mobile header menu */
    $("body").on("click", ".mobile-menu-more", function () {
      $(this).toggleClass("active");
      $(".product-mobile-menu").toggleClass("active");
    });
    $("body").on("click", ".product-mobile-menu", function () {
      $("mobile-menu-more").toggleClass("active");
      $(this).toggleClass("active");
    });

    $(document).click(function (e) {
      if (!$(e.target).is(".product-mobile-menu")) {
        if ($(".product-mobile-menu").hasClass("open")) {
          $(".hcategory_menu").removeClass("open");
          $(".mobile-menu-more").removeClass("active");
        }
      }
    });

    $(".disbl_href_action").click(function (e) {
      e.preventDefault();
    });
    /* this.getSearchProductList(); */
    /* 
    $.magnificPopup.open({
      items: {
        src: "#outlet-error-popup",
      },
      type: "inline",
    }); */
  }

  componentWillReceiveProps(PropsDt) {
    if (PropsDt.staticblack !== this.state.staticblacks) {
      var headerInfo = "";
      var headerlinks = "";

      if (Object.keys(PropsDt.staticblack).length > 0) {
        PropsDt.staticblack.map((data) => {
          if (data.staticblocks_slug === "ntice-board") {
            headerInfo =
              data.staticblocks_description !== ""
                ? Parser(data.staticblocks_description)
                : "";
            return "";
          }
          if (data.staticblocks_slug === "header-links") {
            headerlinks =
              data.staticblocks_description !== ""
                ? Parser(data.staticblocks_description)
                : "";
            return "";
          }
        });
      }

      this.setState({
        headerInfo: headerInfo,
        headerlinks: headerlinks,
        staticblacks: PropsDt.staticblack,
      });
    }

    if (PropsDt.menudata !== this.props.menudata) {
      this.setState({ menudata: PropsDt.menudata[0].result_set });
    }

    if (PropsDt.selectedAvilHome !== this.props.selectedAvilHome) {
      this.setState(
        {
          seletedAvilablityId: PropsDt.selectedAvilHome,
          selectedAvil: PropsDt.selectedAvilHome,
        },
        function () {
          if (PropsDt.selectedAvilHome === pickupId) {
            this.pickOutlet(this.state.pickupOutlets[0]);
          }
        }
      );
    }

    if (PropsDt.outletslist !== this.state.pickupOutletsList) {
      this.setState({
        pickupOutlets: PropsDt.outletslist,
        pickupOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.alloutletslist !== this.state.deliveryOutletsList) {
      this.setState({
        deliveryOutlets: PropsDt.alloutletslist,
        deliveryOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
      this.setState({ secondaryaddresslist: PropsDt.secondaryaddresslist });
    }

    if ("homePageState" in PropsDt) {
      if (this.state.search_keyword !== PropsDt.homePageState.search_keyword) {
        this.setState({ search_keyword: PropsDt.homePageState.search_keyword });
      }
      if (
        PropsDt.homePageState.nextavail !== undefined &&
        PropsDt.homePageState.nextavail !== "" &&
        PropsDt.homePageState.nextavail !== this.state.nextavail
      ) {
        this.setState({ nextavail: PropsDt.homePageState.nextavail });
      }
    }
    if (this.state.defaultAvilTy !== PropsDt.changeavail) {
      if (PropsDt.changeavail !== "" && PropsDt.changeavail !== undefined) {
        this.setState({ defaultAvilTy: PropsDt.changeavail });
      }
    }
    if (this.state.selectPostalCode !== PropsDt.selectPostalCode) {
      if (
        PropsDt.selectPostalCode !== "" &&
        PropsDt.selectPostalCode !== undefined
      ) {
        this.setState({ selectPostalCode: PropsDt.selectPostalCode });
      }
    }
    if (this.state.selectPickupTime !== PropsDt.selectPickupTime) {
      if (
        PropsDt.selectPickupTime !== "" &&
        PropsDt.selectPickupTime !== undefined
      ) {
        this.findOutletBasedZone("", pickupId);
        this.setState({
          selectPickupTime: PropsDt.selectPickupTime,
          seletedAvilablityId: pickupId,
        });
      }
    }

    if (PropsDt.globalsettings !== this.state.globalsettings) {
      if (
        PropsDt.globalsettings[0] !== "" &&
        PropsDt.globalsettings[0] !== undefined
      ) {
        var siteLocationArr = Array();
        if (Object.keys(PropsDt.globalsettings[0]).length > 0) {
          var resultSetArr = PropsDt.globalsettings[0].result_set;
          var siteLocationData =
            Object.keys(resultSetArr.site_location).length > 0
              ? resultSetArr.site_location
              : Array();
          for (var key of Object.keys(siteLocationData)) {
            siteLocationArr[key] = siteLocationData[key];
          }
        }

        this.setState({
          globalsettings: PropsDt.globalsettings[0].result_set,
          site_location_list: siteLocationArr,
        });
      }
    }

    if (PropsDt.showOrdDateOnly !== "") {
      this.setState({ showOrdDateOnly: PropsDt.showOrdDateOnly });
    }

    if (PropsDt.trgavliblpopupFlg === "yes") {
      var nextAvblId =
        this.state.defaultAvilTy === deliveryId ? pickupId : deliveryId;
      this.chooseAvailability(nextAvblId);
      this.setState({ trgavliblpopup: "yes" }, function () {
        $.magnificPopup.open({
          items: {
            src: "#order-popup",
          },
          type: "inline",
        });
        this.props.sateValChange("change_avlpoup", "no");
      });
    }

    if (PropsDt.changeAddresspopupFlg === "yes") {
      this.setState({ trgavliblpopup: "yes" }, function () {
        $.magnificPopup.open({
          items: {
            src: "#order-popup",
          },
          type: "inline",
        });
        this.props.sateValChange("change_addresspoup", "no");
      });
    }

    if (this.state.fbstatus === "loading") {
      if (PropsDt.fblogin !== undefined) {
        if (PropsDt.fblogin.length > 0) {
          this.setState({ fbstatus: "ok" }, function () {
            this.doLogin(PropsDt.fblogin[0]);
          });
        }
      }
    }

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0]);
    }

    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.registration !== undefined) {
        var formPay_load = this.state.fieldssignup;
        this.setState({
          regstatus: "ok",
          enableOtp: false,
          fieldssignup: {
            firstname: "",
            email: "",
            password: "",
            rePassword: "",
            mobile: "",
            pdpa_consent: "",
            terms: "",
            completeReg: "Y",
          },
        });
        hideLoader("otpverify_submit", "class");
        this.showMessage(PropsDt.registration[0]);
      }
    }
    if (PropsDt.changepassword !== this.props.changepassword) {
      //  const {changepassword} = nextProps.changepassword;
      $(".old_password").val("");
      $(".new_password").val("");
      $(".confirm_password").val("");
      hideLoader("change-pass-sbmt", "class");
      if (PropsDt.changepassword[0].status === "ok") {
        showAlert("Success", "Password changed successfully!");
      } else {
        if (PropsDt.changepassword[0].form_error) {
          showAlert("Alert", PropsDt.changepassword[0].form_error);
        } else {
          showAlert("Alert", PropsDt.changepassword[0].message);
        }
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }

    if (PropsDt.tableAvailability !== this.props.tableAvailability) {
      if (PropsDt.tableAvailability.status) {
        if (PropsDt.tableAvailability.status == "ok") {
          this.dineinOutlet(PropsDt.tableAvailability.result_set[0]);
        } else {
          let res_obj = {
            status: PropsDt.tableAvailability.status,
            message: PropsDt.tableAvailability.result_set,
          };
          this.showMessage(res_obj);
          hideLoader("dinein_popup_item", "class");
        }
      }
    }
  }

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });
    const formPayload = this.state.fieldsfgtpassword;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      email_address: formPayload.email,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject));
  };
  /*forget password  - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { fields: { [field]: { $set: value } } }),
      function () {
        /*
        if (field === "memberid") {
          axios
            .get(
              apiUrl +
                "customer/checkmembership_id?app_id=" +
                appId +
                "&membership_id=" +
                value,
              headerconfig
            )
            .then((res) => {
              var result_set = res.data.result_set;
              if (res.data.status === "ok") {
                this.setState(
                  update(this.state, {
                    fields: {
                      first_name: {
                        $set: result_set.customer_first_name,
                      },
                    },
                  })
                );
              } else {
                this.setState(
                  update(this.state, { fields: { first_name: { $set: "" } } })
                );
              }
            });
        } */
      }
    );
  };

  handleSignin = () => {
    const formPayload = this.state.fields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      logintype: "member",
      login_username: formPayload.memberid,
      table_number:
        cookie.load("orderTableNo") !== "" &&
        typeof cookie.load("orderTableNo") !== undefined &&
        typeof cookie.load("orderTableNo") !== "undefined"
          ? cookie.load("orderTableNo")
          : "",
      /* login_password: formPayload.password, */
    };
    showLoader("login_submit", "class");
    this.props.getLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked");
    }
    this.setState(
      update(this.state, { fieldssignup: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.fieldssignup;
    this.setState({ regstatus: "loading" });
    var pdpaConsent = formPayload.pdpa_consent === true ? "yes" : "no";

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.firstname,
      customer_email: formPayload.email,
      customer_password: formPayload.password,
      customer_phone: formPayload.mobile,
      customer_pdpa_consent: pdpaConsent,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("signup_submit", "class");
    this.props.getRegistration(qs.stringify(postObject));
  };

  /* for signup - end*/

  /* Change Password */
  fieldChangepassword = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };

  changepassword = () => {
    var old_password = $(".old_password").val();
    var new_password = $(".new_password").val();
    var confirm_password = $(".confirm_password").val();
    var postObject = {
      app_id: appId,
      type: "web",
      oldpassword: old_password,
      password: new_password,
      confirmpassword: confirm_password,
      refrence: cookie.load("UserId"),
    };
    showLoader("change-pass-sbmt", "class");
    var qs = require("qs");
    this.props.getChangePassword(qs.stringify(postObject));
  };

  /* main - menu navigation -start*/

  createLink(menu) {
    if (menu.nav_type === "0" && menu.nav_parent_title == "") {
      return (
        <Link to={"/" + menu.nav_title_slug} title={menu.nav_title}>
          <span>{menu.nav_title}</span>
        </Link>
      );
    } else if (menu.nav_type === "3" && menu.nav_parent_title == "") {
      var pageUrlTxt = menu.nav_pages;
      if (pageUrlTxt.includes("http")) {
        return (
          <a
            href={menu.nav_pages}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </a>
        );
      } else {
        return (
          <Link
            to={menu.nav_pages != "#" ? "/" + menu.nav_pages : ""}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </Link>
        );
      }
    }
  }

  createSubmenu(menu, type) {
    if (menu.nav_parent_title === "") {
      if (this.state.menudata) {
        var checkIngVal = 0;
        var liTxt = this.state.menudata.map(function (menuparent, i) {
          if (menu.nav_id == menuparent.nav_parent_title) {
            checkIngVal = 1;
            if (menuparent.nav_type === "0") {
              return (
                <li key={i + 100}>
                  <Link
                    to={"/" + menuparent.nav_title_slug}
                    title={menuparent.nav_title}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={i + 100}>
                  <Link
                    to={
                      menuparent.nav_pages != "#"
                        ? "/" + menuparent.nav_pages
                        : ""
                    }
                    title={menuparent.nav_title}
                    target={menuparent.nav_link_type == "blank" ? "_blank" : ""}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            }
          }
        }, this);

        if (type === "span" && checkIngVal === 1) {
          return <a href="/" className="submenu-arow disbl_href_action"></a>;
        } else if (type === "ul" && checkIngVal === 1) {
          return <ul className="submenu_list">{liTxt}</ul>;
        } else {
          return "";
        }
      }
    }
  }

  menuActiveCls(nav_pages) {
    var currenturl = window.location.href;
    var returnClsTx = "";
    if (nav_pages === "home" || nav_pages === "#" || nav_pages === "/") {
      returnClsTx =
        this.props.match.path === "/home" || this.props.match.path === "/"
          ? "active"
          : "";
    } else if (nav_pages === "menu") {
      returnClsTx =
        this.props.match.path === "/menu" ||
        this.props.match.path === "/menu/:slugType/:slugValue" ||
        this.props.match.path === "/menu/:slugType/:slugValue/:proValue" ||
        this.props.match.path === "/checkout" ||
        this.props.match.path === "/thankyou/:orderId"
          ? "active"
          : "";
    } else {
      returnClsTx = currenturl.includes(nav_pages) ? "active" : nav_pages;
    }
    return returnClsTx;
  }

  listMainNavigation() {
    if (this.state.menudata) {
      return this.state.menudata.map(function (menu, i) {
        let nav_pages = menu.nav_pages;
        if (menu.nav_type === "0") {
          nav_pages = menu.nav_title_slug;
        }

        return (
          <li key={i + 100} className={this.menuActiveCls(nav_pages)}>
            {this.createLink(menu)}
            {this.createSubmenu(menu, "span")}
            {this.createSubmenu(menu, "ul")}
          </li>
        );
      }, this);
    }
  }

  /* menu navigation -end */

  /* show message */
  showMessage(response) {
    hideLoader("signup_submit", "class");
    if (response.status === "ok") {
      showAlert("Success", response.message);
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  setAvailabilityFun(availability, event) {
    event.preventDefault();
    if (availability !== dineinId) {
      return false;
    }
    this.setState({ seletedAvilablityId: availability }, () => {
      this.chooseAvailabilityFun(availability, event);
    });
  }
  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");

    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      if (
        this.state.pickupOutlets !== "" &&
        typeof this.state.pickupOutlets !== undefined &&
        typeof this.state.pickupOutlets !== undefined
      ) {
        /*  if (this.state.pickupOutlets.length === 1) {
          this.pickOutlet(this.state.pickupOutlets[0]);
        } else { */
        popupIdtxt = "#takeaway-popup";
        /*  } */
      } else {
        popupIdtxt = "#takeaway-popup";
      }
    } else if (availability === dineinId) {
      cookie.save("defaultAvilablityId", dineinId, { path: "/" });
      $.magnificPopup.close();
      popupIdtxt = "#dinein-popup";
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  /*  Onchange  for Guest Checkout */
  fieldChangecheckout = (field, value) => {
    this.setState(
      update(this.state, { fieldscheckout: { [field]: { $set: value } } })
    );
  };

  handleCheckout = () => {
    const formPayload = this.state.fieldscheckout;
    if (
      document.getElementById("spn-email-error").innerHTML ===
      '<span class="error">This email already exists</span>'
    ) {
      return false;
    }
    if (
      document.getElementById("spn-mobile-error").innerHTML ===
      '<span class="error">This mobile number already exists</span>'
    ) {
      return false;
    }

    showLoader("login_in_guest", "class");

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.check_firstname,
      customer_email: formPayload.check_email,
      customer_phone: formPayload.check_phone,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    axios
      .post(
        apiUrl + "guestaccount/create",
        qs.stringify(postObject),
        headerconfig
      )
      .then((response) => {
        hideLoader("login_in_guest", "class");
        if (response.data.status === "ok") {
          window.scrollTo(0, 0);
          $.magnificPopup.close();

          var cust_birthdate = "";
          if (
            typeof response.data.result_set.customer_birthdate !==
              "undefined" &&
            response.data.result_set.customer_birthdate !== "null" &&
            response.data.result_set.customer_birthdate !== null &&
            response.data.result_set.customer_birthdate !== "" &&
            response.data.result_set.customer_birthdate !== "0000-00-00"
          ) {
            cust_birthdate = response.data.result_set.customer_birthdate;
          }

          /* set User cookie values - Start */
          var customerid = response.data.result_set.customer_id;
          cookie.save("UserId", customerid, cookieConfig);
          cookie.save(
            "UserFname",
            response.data.result_set.customer_first_name !== ""
              ? response.data.result_set.customer_first_name
              : "",
            cookieConfig
          );
          cookie.save(
            "UserLname",
            response.data.result_set.customer_last_name != ""
              ? response.data.result_set.customer_last_name
              : "",
            cookieConfig
          );
          cookie.save(
            "UserMobile",
            response.data.result_set.customer_phone,
            cookieConfig
          );
          cookie.save(
            "UserEmail",
            response.data.result_set.customer_email,
            cookieConfig
          );
          cookie.save(
            "UserDefaultAddress",
            response.data.result_set.customer_address_name,
            cookieConfig
          );
          cookie.save(
            "UserDefaultUnitOne",
            response.data.result_set.customer_address_line1,
            cookieConfig
          );
          cookie.save(
            "UserDefaultUnitTwo",
            response.data.result_set.customer_address_line2,
            cookieConfig
          );
          cookie.save(
            "UserDefaultPostalCode",
            response.data.result_set.customer_postal_code,
            cookieConfig
          );
          cookie.save("UserBirthdate", cust_birthdate, cookieConfig);
          cookie.save("userLoginFrom", "guest", cookieConfig);
          cookie.save("loginTableNo", "No", cookieConfig);

          const { history } = this.props;

          var qs = require("qs");
          var postObject = {
            app_id: appId,
            reference_id: getReferenceID(),
            customer_id: customerid,
            availability_id: this.state.defaultAvilTy,
          };
          if (this.state.defaultAvilTy === dineinId) {
            postObject["login_type"] = 2;
          }

          axios
            .post(
              apiUrl + "cart/update_customer_info",
              qs.stringify(postObject),
              headerconfig
            )
            .then((res) => {
              //  showToastrAlert("Please proceed with placing your order.");
              if (
                res.data.contents !== "" &&
                typeof res.data.contents !== undefined &&
                typeof res.data.contents !== "undefined" &&
                res.data.contents !== null
              ) {
                var cartDetails = res.data.contents.cart_details;
                cookie.save(
                  "orderOutletId",
                  cartDetails.outlet_id,
                  cookieConfig
                );
                cookie.save(
                  "outletLocationId",
                  cartDetails.cart_location_id,
                  cookieConfig
                );
                cookie.save(
                  "defaultAvilablityId",
                  cartDetails.cart_availability_id,
                  cookieConfig
                );
                cookie.save(
                  "orderZoneId",
                  cartDetails.outletzone_id,
                  cookieConfig
                );
                cookie.save("isAdvanced", "yes", cookieConfig);
                cookie.save("slotType", "2", cookieConfig);
              }
              if (res.data.status === "ok") {
                if (
                  cookie.load("checkoutredirect") === "fromcheckout" ||
                  cookie.load("checkoutredirect") === "yes"
                ) {
                  cookie.remove("loginpopupTrigger", cookieConfig);
                  cookie.remove("checkoutredirect", cookieConfig);
                  history.push("/checkout");
                } else {
                  history.push("/refpage/home");
                }
              } else {
                history.push("/refpage/home");
              }
            });
        } else {
          if (response.data.form_error) {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.form_error +
                "</div>"
            );
          } else {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.message +
                "</div>"
            );
          }
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  /* facebook login */
  responseFacebook = (response) => {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];
      var birthday = response.birthday;
      var qs = require("qs");
      var photo = response.picture.data.url;
      /* update gender field */
      var postGender = "";
      if (
        typeof response.gender !== "undefined" &&
        response.gender === "male"
      ) {
        postGender = "M";
      } else if (
        typeof response.gender !== "undefined" &&
        response.gender === "female"
      ) {
        postGender = "F";
      }
      /* update DOB */
      var dob = "";
      if (typeof birthday !== "undefined" && birthday !== "") {
        dob = dateFormat(response.birthday, "yyyy-mm-dd");
      }

      var postObject = {
        app_id: appId,
        type: "web",
        login_firstname: response.first_name,
        login_lastname: response.last_name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
        customer_photo: photo,
        customer_dob: dob,
      };
      this.setState({ fbstatus: "loading" });
      this.props.getFbLoginData(qs.stringify(postObject));
    }
  };

  /* for login and facebook login*/
  doLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }
      cookie.save("memberID", this.state.fields.memberid, { path: "/" });
      cookie.save("UserId", fbloginData.result_set.customer_id, { path: "/" });
      cookie.save("UserEmail", fbloginData.result_set.customer_email, {
        path: "/",
      });
      cookie.save(
        "UserFname",
        fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "",
        { path: "/" }
      );
      cookie.save(
        "UserLname",
        fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "",
        { path: "/" }
      );
      cookie.save("UserMobile", mobileno, { path: "/" });
      cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
      cookie.save(
        "UserDefaultAddress",
        fbloginData.result_set.customer_address_name,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitOne",
        fbloginData.result_set.customer_address_line1,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitTwo",
        fbloginData.result_set.customer_address_line2,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultPostalCode",
        fbloginData.result_set.customer_postal_code,
        { path: "/" }
      );
      if (
        cookie.load("orderTableNo") !== "" &&
        typeof cookie.load("orderTableNo") !== undefined &&
        typeof cookie.load("orderTableNo") !== "undefined"
      ) {
        cookie.save("loginTableNo", "Yes", cookieConfig);
      } else {
        cookie.save("loginTableNo", "No", cookieConfig);
      }
      if (cookie.load("defaultAvilablityId") === dineinId) {
        cookie.save("userAccountType", "2", cookieConfig);
      }
      const { history } = this.props;
      var qs = require("qs");
      var postObject = {
        app_id: appId,
        reference_id: getReferenceID(),
        customer_id: fbloginData.result_set.customer_id,
        availability_id: cookie.load("defaultAvilablityId"),
      };
      if (this.state.defaultAvilTy === dineinId) {
        postObject["login_type"] = 2;
      }

      axios
        .post(apiUrl + "cart/update_customer_info", qs.stringify(postObject))
        .then((res) => {
          if (res.data.status === "ok") {
            if (
              cookie.load("checkoutredirect") === "fromcheckout" ||
              cookie.load("checkoutredirect") === "yes"
            ) {
              cookie.remove("loginpopupTrigger", { path: "/" });
              cookie.remove("checkoutredirect", { path: "/" });
              history.push("/checkout");
            } else {
              showAlert("Success", "Logged in Successfully!");
              $.magnificPopup.open({
                items: {
                  src: ".alert_popup",
                },
                type: "inline",
              });
              history.push("/myaccount");
            }
          } else {
            showAlert("Success", "Logged in Successfully!");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            history.push("/myaccount");
          }
        });
    } else {
      cookie.remove("loginpopupTrigger", { path: "/" });
      showAlert("Error", fbloginData.message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  closepopup(event) {
    event.preventDefault();
    this.setState({ seletedAvilablityId: dineinId });
    $.magnificPopup.open({
      items: {
        src: "#dinein-popup",
      },
      type: "inline",
    });
  }

  close_popup(event) {
    event.preventDefault();
    $.magnificPopup.close();
  }

  gobckOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#delevery-postcode-popup",
      },
      type: "inline",
    });
  }

  gobckPkupOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#takeaway-popup",
      },
      type: "inline",
    });
  }

  changeAvailability() {
    var tempArr = [],
      tempVl = "";
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
    });

    /* For Advanced Slot Start */
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
      slotType: tempVl,
      seleted_ord_slot: tempVl,
      seleted_ord_slotTxt: tempVl,
      seleted_ord_slot_str: tempVl,
      seleted_ord_slot_end: tempVl,
    });
    /* For Advanced Slot End */

    var change_shop_type = $("#change_shop_type").val();
    if ($("#changeaddress").length > 0 && $("#changeaddress").val() !== "") {
      cookie.save("changeaddress", "yes", cookieConfig);
    }

    if (change_shop_type !== "") {
      this.destroyCartShopeType("");
      return false;
    } else {
      this.destroyCart("Yes");
    }
    var nextavail = "";
    if (this.state.nextavail !== "") {
      nextavail = this.state.nextavail;
    } else {
      nextavail =
        cookie.load("defaultAvilablityId") !== "" &&
        typeof cookie.load("defaultAvilablityId") !== undefined &&
        typeof cookie.load("defaultAvilablityId") !== "undefined"
          ? cookie.load("defaultAvilablityId")
          : "";
    }
    var popupIdtxt = "";
    if (nextavail === deliveryId) {
      popupIdtxt = "#delevery-postcode-popup";
    } else if (nextavail === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (nextavail === dineinId) {
      popupIdtxt = "#dinein-popup";
    }
    if (
      $("#changeaddressheader").length > 0 &&
      $("#changeaddressheader").val() !== ""
    ) {
      popupIdtxt = "#delevery-postcode-popup";
    }
    if (popupIdtxt !== "") {
      cookie.remove("defaultAvilablityId", cookieConfig);
      this.setState({ seletedAvilablityId: nextavail });
      setTimeout(function () {
        $.magnificPopup.open({
          items: {
            src: popupIdtxt,
          },
          type: "inline",
        });
      }, 500);
    }
    this.props.history.push("/");
  }

  destroyCart(clear = "No") {
    this.props.destroyCartDetail();
    this.deleteOrderCookie(clear);
  }

  destroyCartShopeType() {
    this.props.destroyCartDetail();
    cookie.remove("cartsubtotal", cookieConfig);
    cookie.remove("cartid", cookieConfig);

    /* Delivery avilablity */

    cookie.remove("unitNoOne", cookieConfig);
    cookie.remove("unitNoTwo", cookieConfig);

    cookie.remove("promotion_id", cookieConfig);
    cookie.remove("promotion_applied", cookieConfig);
    cookie.remove("promotion_code", cookieConfig);
    cookie.remove("promotion_delivery_charge_applied", cookieConfig);
    cookie.remove("promotion_amount", cookieConfig);
    cookie.remove("promotion_category", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);

    /*Remove voucher*/
    cookie.remove("voucher_applied", cookieConfig);
    cookie.remove("voucher_code", cookieConfig);
    cookie.remove("voucher_amount", cookieConfig);

    cookie.remove("points_redeemed", cookieConfig);
    cookie.remove("points_used", cookieConfig);
    cookie.remove("points_amount", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);
    location.reload();
  }

  deleteOrderCookie(clear = "Yes") {
    if (clear == "Yes") {
      cookie.remove("orderZoneId", cookieConfig);
      cookie.remove("orderOutletId", cookieConfig);
    }

    removeOrderDateTime();
    removePromoCkValue();
    cookie.remove("orderPaymentMode", cookieConfig);
    cookie.remove("orderTableNo", cookieConfig);
    cookie.remove("product_remarks", cookieConfig);
    cookie.remove("orderOutletName", cookieConfig);
    cookie.remove("orderOutletSlug", cookieConfig);
    cookie.remove("carttotalitems", cookieConfig);
    cookie.remove("cartsubtotal", cookieConfig);
    cookie.remove("cartid", cookieConfig);
    cookie.remove("firstNavigation", cookieConfig);

    /* Delivery avilablity */
    cookie.remove("orderDateTime", cookieConfig);
    cookie.remove("deliveryDate", cookieConfig);
    cookie.remove("deliveryTime", cookieConfig);
    cookie.remove("unitNoOne", cookieConfig);
    cookie.remove("unitNoTwo", cookieConfig);

    cookie.remove("deliveryPincodeText", cookieConfig);
    cookie.remove("pickupLocationText", cookieConfig);
    cookie.remove("deliveryPickupDatetimetext", cookieConfig);

    /* For Advanced Slot */
    cookie.remove("isAdvanced", cookieConfig);
    cookie.remove("slotType", cookieConfig);
    cookie.remove("orderSlotVal", cookieConfig);
    cookie.remove("orderSlotTxt", cookieConfig);
    cookie.remove("orderSlotStrTime", cookieConfig);
    cookie.remove("orderSlotEndTime", cookieConfig);

    cookie.remove("promotion_id", cookieConfig);
    cookie.remove("promotion_applied", cookieConfig);
    cookie.remove("promotion_code", cookieConfig);
    cookie.remove("promotion_delivery_charge_applied", cookieConfig);
    cookie.remove("promotion_amount", cookieConfig);
    cookie.remove("promotion_category", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);

    /*Remove voucher*/
    cookie.remove("voucher_applied", cookieConfig);
    cookie.remove("voucher_code", cookieConfig);
    cookie.remove("voucher_amount", cookieConfig);

    cookie.remove("points_redeemed", cookieConfig);
    cookie.remove("points_used", cookieConfig);
    cookie.remove("points_amount", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);
  }

  /* find Zone*/
  findOutletBasedZone(first, availability) {
    var postalcode = "";
    /* if (first) {
      if (first == 2) {
        if (availability === deliveryId) {
          postalcode = cookie.load("deliveryPostalCode");
        } else {
          postalcode = cookie.load("orderPostalCode");
        }
      } else {
        //postalcode = $("#postalcode").val();
        postalcode = this.state.postalcodeInputVal;
      }
    } else {
      postalcode = $("#postalcode1").val();
    } */
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }
    if (
      postalcode !== "" &&
      typeof postalcode !== undefined &&
      typeof postalcode !== "undefined"
    ) {
      if (postalcode.length < 5) {
        $(".postal_error").html(
          '<span class="error">Please enter valid postal code.</span>'
        );
        return false;
      }
    } else {
      if (availability === deliveryId) {
        $.magnificPopup.open({
          items: {
            src: "#order-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var outletId = "";

    if (
      cookie.load("orderOutletId") !== undefined &&
      cookie.load("orderOutletId") !== ""
    ) {
      outletId = cookie.load("orderOutletId");
    }

    showLoader("delivery_submit_cls", "class");
    if (availability === deliveryId) {
      axios
        .all([
          axios.get(
            apiUrlV2 +
              "outlets/findOutletZone?app_id=" +
              appId +
              "&availability_id=" +
              availability +
              "&postal_code=" +
              postalcode +
              "&shop_type=" +
              shopType,
            headerconfig
          ),
          axios.get(
            apiUrlV2 +
              "settings/chkTimeslotIsAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              availability,
            headerconfig
          ),
        ])
        .then(
          axios.spread((res, timeslt) => {
            hideLoader("delivery_submit_cls", "class");

            var deliveryInfo = [];
            /* Success response */
            if (res.data.status === "ok") {
              $.magnificPopup.close();

              var orderDeliveryAddress =
                res.data.result_set.postal_code_information.zip_buno +
                " " +
                res.data.result_set.postal_code_information.zip_sname;
              var orderHandled =
                stripslashes(res.data.result_set.outlet_name) +
                ", Crew will be seeing you in " +
                res.data.result_set.outlet_delivery_timing +
                " Minutes";

              deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
              deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
              deliveryInfo["orderOutletName"] = stripslashes(
                res.data.result_set.outlet_name
              );
              deliveryInfo["orderPostalCode"] =
                res.data.result_set.postal_code_information.zip_code;
              deliveryInfo["orderTAT"] =
                res.data.result_set.outlet_delivery_timing;
              deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress;
              deliveryInfo["orderHandled"] = orderHandled;
              deliveryInfo["defaultAvilablityId"] = availability;

              var unitNum = this.showUnitNum(
                res.data.result_set.outlet_unit_number1,
                res.data.result_set.outlet_unit_number2
              );

              var orderHandledText =
                res.data.result_set.outlet_address_line1 +
                " " +
                res.data.result_set.outlet_address_line2 +
                ", " +
                unitNum +
                " Singapore " +
                postalcode;

              deliveryInfo["orderHandledByText"] = orderHandledText;
              var order_tat =
                this.props.globalsettings[0].result_set.order_tat_all
                  .client_tat_setting_restaurants_delivery;

              this.setState({
                deliveryInfo: deliveryInfo,
                seletedAvilablityId: availability,
                defaultAvilTy: availability,
                seletedOutletId: res.data.result_set.outlet_id,
                order_tat_time: order_tat,
                orderHandled: orderHandled,
                orderDeliveryAddress:
                  orderDeliveryAddress +
                  " Singapore " +
                  res.data.result_set.postal_code_information.zip_code,
              });

              if (this.state.selectPostalCode === "Yes") {
                cookie.save("orderPostalCode", postalcode, cookieConfig);
                if (availability === deliveryId) {
                  cookie.save("deliveryPostalCode", postalcode, cookieConfig);
                  cookie.save(
                    "orderDeliveryAddress",
                    orderDeliveryAddress,
                    cookieConfig
                  );
                  cookie.save("defaultAvilablityId", deliveryId, cookieConfig);
                } else {
                  cookie.save("defaultAvilablityId", pickupId, cookieConfig);
                }
                cookie.save(
                  "orderZoneId",
                  deliveryInfo["orderZoneId"],
                  cookieConfig
                );

                this.props.sateValChange("selectPostalCode", "Yes");
                this.props.sateValChange("postalcode", postalcode);
                var orderDate =
                  cookie.load("deliveryDate") !== "" &&
                  typeof cookie.load("deliveryDate") !== undefined &&
                  typeof cookie.load("deliveryDate") !== "undefined"
                    ? cookie.load("deliveryDate")
                    : "";
                if (
                  this.props.match.params.slugRestaurant !== "" &&
                  typeof this.props.match.params.slugRestaurant !== undefined &&
                  typeof this.props.match.params.slugRestaurant !==
                    "undefined" &&
                  orderDate !== ""
                ) {
                  location.reload();
                }
                if (orderDate !== "") {
                  return false;
                }
              }
              /* Success time slot response */
              if (timeslt.data.status === "success") {
                /* For Advanced Slot */
                var isAdvanced = timeslt.data.isAdvanced,
                  slotType = "0";
                if (isAdvanced === "yes") {
                  slotType = timeslt.data.slotType;
                }
                /*temp val */
                slotType = "2";
                this.setState({
                  getDateTimeFlg: "yes",
                  isAdvanced: isAdvanced,
                  slotType: slotType,
                });

                removeOrderDateTime();
                removePromoCkValue();

                $.magnificPopup.open({
                  items: {
                    src: "#awesome-popup",
                  },
                  type: "inline",
                });
              } else {
                $.magnificPopup.open({
                  items: {
                    src: "#order-popup",
                  },
                  type: "inline",
                });
              }
            }

            /* Error response */
            if (res.data.status === "error") {
              this.setState({ deliveryInfo: deliveryInfo });

              $.magnificPopup.close();
              $.magnificPopup.open({
                items: {
                  src: "#order-popup",
                },
                type: "inline",
              });

              if (first === 0) {
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $(".postal_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }
            }
          })
        );
    } else {
      axios
        .all([
          axios.get(
            apiUrl +
              "outlets/pickup_outlets?app_id=" +
              appId +
              "&availability=" +
              availability +
              "&outlet_id=" +
              outletId,
            headerconfig
          ),
          axios.get(
            apiUrlV2 +
              "settings/chkTimeslotIsAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              availability +
              "&outletId=" +
              outletId,
            headerconfig
          ),
        ])
        .then(
          axios.spread((res, timeslt) => {
            hideLoader("delivery_submit_cls", "class");

            var pickupInfo = [];
            /* Success response */
            if (res.data.status === "ok") {
              $.magnificPopup.close();
              var resultSet = res.data.result_set[0];
              var orderDeliveryAddress =
                resultSet.outlet_unit_number1 +
                " " +
                resultSet.outlet_unit_number2;
              var orderHandled =
                stripslashes(resultSet.outlet_name) +
                ", Crew will be seeing you in " +
                resultSet.outlet_pickup_tat +
                " Minutes";

              pickupInfo["orderOutletId"] = resultSet.outlet_id;
              pickupInfo["orderOutletName"] = stripslashes(
                resultSet.outlet_name
              );
              pickupInfo["orderPostalCode"] = resultSet.outlet_postal_code;
              pickupInfo["orderTAT"] = resultSet.outlet_pickup_tat;

              pickupInfo["orderHandled"] = orderHandled;
              pickupInfo["defaultAvilablityId"] = availability;

              var unitNum = this.showUnitNum(
                resultSet.outlet_unit_number1,
                resultSet.outlet_unit_number2
              );

              var orderHandledText =
                resultSet.outlet_address_line1 +
                " " +
                resultSet.outlet_address_line2 +
                ", " +
                unitNum +
                " Singapore " +
                postalcode;

              pickupInfo["orderHandledByText"] = orderHandledText;

              this.setState({
                pickupInfo: pickupInfo,
                seletedAvilablityId: availability,
                defaultAvilTy: availability,
                seletedOutletId: res.data.result_set.oa_outlet_id,
                order_tat_time: resultSet.outlet_pickup_tat,
                orderHandled: orderHandled,
                orderDeliveryAddress:
                  orderDeliveryAddress +
                  " Singapore " +
                  resultSet.outlet_postal_code,
              });

              if (this.state.selectPostalCode === "Yes") {
                cookie.save("orderPostalCode", postalcode, cookieConfig);

                cookie.save("defaultAvilablityId", pickupId, cookieConfig);

                this.props.sateValChange("selectPostalCode", "Yes");
                this.props.sateValChange("postalcode", postalcode);

                if (
                  this.props.match.params.slugRestaurant !== "" &&
                  typeof this.props.match.params.slugRestaurant !== undefined &&
                  typeof this.props.match.params.slugRestaurant !== "undefined"
                ) {
                  location.reload();
                }
                return false;
              }
              /* Success time slot response */
              if (timeslt.data.status === "success") {
                this.setState({ getDateTimeFlg: "yes" });

                //removeOrderDateTime();
                removePromoCkValue();

                $.magnificPopup.open({
                  items: {
                    src: "#awesome-popup",
                  },
                  type: "inline",
                });
              } else {
                $.magnificPopup.open({
                  items: {
                    src: "#outlet-error-popup",
                  },
                  type: "inline",
                });
              }
            }

            /* Error response */
            if (res.data.status === "error") {
              this.setState({ deliveryInfo: deliveryInfo });

              $.magnificPopup.close();
              $.magnificPopup.open({
                items: {
                  src: "#error-postal-popup",
                },
                type: "inline",
              });

              if (first === 0) {
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $(".postal_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }
            }
          })
        );
    }
  }

  /* Select outlet */
  selectOutlet(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

    if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");

    axios
      .get(
        apiUrl +
          "outlets/find_outlet?skip_timing=Yes&app_id=" +
          appId +
          "&availability_id=" +
          availability +
          "&postal_code=" +
          postalcode,
        headerconfig
      )
      .then((res) => {
        hideLoader("delivery_submit_cls", "class");

        /* Success response */
        if (res.data.status === "ok") {
          $.magnificPopup.close();
          var orderDeliveryAddress =
            res.data.result_set.postal_code_information.zip_buno +
            " " +
            res.data.result_set.postal_code_information.zip_sname;
          var orderHandled =
            stripslashes(res.data.result_set.outlet_name) +
            ", Crew will be seeing you in " +
            res.data.result_set.outlet_delivery_timing +
            " Minutes";
          this.setState({
            orderDeliveryAddress:
              orderDeliveryAddress +
              " Singapore " +
              res.data.result_set.postal_code_information.zip_code,
          });
          this.setState({ orderHandled: orderHandled });

          cookie.save(
            "orderOutletName",
            stripslashes(res.data.result_set.outlet_name),
            cookieConfig
          );
          cookie.save(
            "orderPostalCode",
            res.data.result_set.postal_code_information.zip_code,
            cookieConfig
          );
          cookie.save(
            "orderTAT",
            res.data.result_set.outlet_delivery_timing,
            cookieConfig
          );
          cookie.save(
            "orderDeliveryAddress",
            orderDeliveryAddress,
            cookieConfig
          );
          cookie.save("orderHandled", orderHandled, cookieConfig);
          cookie.save("defaultAvilablityId", availability, cookieConfig);
          this.setState({ defaultAvilTy: availability });

          var orderHandledText =
            res.data.result_set.outlet_address_line1 +
            " " +
            res.data.result_set.outlet_address_line2 +
            ", Singapore " +
            postalcode;
          cookie.save("orderHandledByText", orderHandledText, cookieConfig);

          removeOrderDateTime();
          removePromoCkValue();

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
        }

        /* Error response */
        if (res.data.status === "error") {
          $.magnificPopup.close();
          $.magnificPopup.open({
            items: {
              src: "#error-postal-popup",
            },
            type: "inline",
          });

          if (first === 0) {
            var mgsTxt =
              res.data.message !== ""
                ? res.data.message
                : "Please enter valid postal code.";
            $(".postal_error").html(
              '<span class="error">' + mgsTxt + "</span>"
            );
          }
        }
      });
  }

  gotoProducts() {
    if (
      cookie.load("orderOutletId") == undefined ||
      cookie.load("orderOutletId") == ""
    ) {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    } else {
      $.magnificPopup.close();
      if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
        cookie.remove("popuptriggerFrom", { path: "/" });
        this.props.history.push("/");
      } else {
        this.props.history.push("/restaurants");
      }
    }
  }

  validateDatetimeFun() {
    showLoader("pickup-outletlist-main", "class");

    axios
      .get(
        apiUrlV2 +
          "settings/chkTimeslotIsAvaiable?app_id=" +
          appId +
          "&availability_id=" +
          pickupId /*+
             "&outletId=" +
            seletedOutletId */,
        headerconfig
      )
      .then((res) => {
        hideLoader("pickup-outletlist-main", "class");

        /* Success response */
        if (res.data.status === "success") {
          /* For Advanced Slot */
          var isAdvanced = res.data.isAdvanced,
            slotType = "0";
          if (isAdvanced === "yes") {
            slotType = res.data.slotType;
          }
          /*temp val */
          slotType = "2";
          this.setState({
            getDateTimeFlg: "yes",
            isAdvanced: isAdvanced,
            slotType: slotType,
          });

          removeOrderDateTime();
          removePromoCkValue();

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
        } else {
          $.magnificPopup.open({
            items: {
              src: "#order-popup",
            },
            type: "inline",
          });
        }
      });
  }

  selectDatetm() {
    /*  var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    if (seletedOutletId !== "" && Object.keys(pickupInfo).length > 0) { */
    showLoader("takeaway-btn-act", "class");

    axios
      .get(
        apiUrlV2 +
          "settings/chkTimeslotIsAvaiable?app_id=" +
          appId +
          "&availability_id=" +
          pickupId /*+
             "&outletId=" +
            seletedOutletId */,
        headerconfig
      )
      .then((res) => {
        hideLoader("takeaway-btn-act", "class");

        /* Success response */
        if (res.data.status === "success") {
          /* For Advanced Slot */
          var isAdvanced = res.data.isAdvanced,
            slotType = "0";
          if (isAdvanced === "yes") {
            slotType = res.data.slotType;
          }
          /*temp val */
          slotType = "2";
          this.setState({
            getDateTimeFlg: "yes",
            isAdvanced: isAdvanced,
            slotType: slotType,
          });

          removeOrderDateTime();
          removePromoCkValue();

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
        } else {
          /*  $.magnificPopup.open({
            items: {
              src: "#outlet-error-popup",
            },
            type: "inline",
          }); */
        }
      });
    /*  } else {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    } */
  }

  /* chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");

    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
     
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      if (
        this.state.pickupOutlets !== "" &&
        typeof this.state.pickupOutlets !== undefined &&
        typeof this.state.pickupOutlets !== undefined
      ) {
        if (this.state.pickupOutlets.length === 1) {
          this.pickOutlet(this.state.pickupOutlets[0]);
        } else {
          popupIdtxt = "#takeaway-popup";
        }
      } else {
        popupIdtxt = "#takeaway-popup";
      }
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  } */

  chooseAvailability(availability) {
    this.clearInputErrorMsg();
    this.setState({
      delivery_pincode_text: "",
      pickup_location_text: "",
      postalcodeInputVal: "",
      delivery_pickup_datetimetext: "",
      seletedAvilablityId: availability,
    });
  }

  gobackAvblPoup() {
    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  selectDlyOutlet() {
    if (this.state.delivery_outlet_id === "") {
      $(".delivery_outletpoup")
        .find(".outlet_error")
        .html('<span class="error"> Please choose one outlet.</span>');
    } else {
      $.magnificPopup.close();
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
    }
  }

  handleKeyPress = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.pickupOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      });

    this.setState({ pickupOutlets: matches });
  };

  /* load site location list  */
  loadSiteLocationList() {
    if (Object.keys(this.state.site_location_list).length > 0) {
      return this.state.site_location_list.map((loaddata, index) => (
        <li key={index} className={this.activeOutlet(loaddata.sl_location_id)}>
          <span onClick={this.pickSiteLocation.bind(this, loaddata)}>
            <div className="outlet-list-lhs">
              <img src={noimg} alt={stripslashes(loaddata.sl_name)} />
            </div>
            <div className="outlet-list-rhs">
              <h4 className="outlet-list-name">
                {stripslashes(loaddata.sl_name)}
              </h4>
              <p className="outlet-list-address">
                {loaddata.sl_pickup_address_line1},{" "}
                {this.showUnitNum(
                  loaddata.sl_pickup_unit_number1,
                  loaddata.sl_pickup_unit_number2
                )}
                , Singapore {loaddata.sl_pickup_postal_code}
              </p>
              <ul>
                <li className="otulet-location">
                  <span>5 km</span>
                </li>

                <li className="otulet-waiting">
                  <span>Waiting : 10 min</span>
                </li>

                <li className="otulet-direction">
                  <span>
                    <a href="" target="_blank">
                      Direction
                    </a>
                  </span>
                </li>

                <li className="otulet-hous">
                  <span>operations : 10am - 6pm</span>
                </li>
              </ul>
            </div>
          </span>
        </li>
      ));
    } else {
      return (
        <li>
          <a>No site location found</a>
        </li>
      );
    }
  }

  /* load outlets  */
  loadOutletsList() {
    if (Object.keys(this.state.pickupOutlets).length > 0) {
      return this.state.pickupOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.pickOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            , Singapore {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  activeOutlet(outletID) {
    var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    var actTxt =
      parseInt(seletedOutletId) === parseInt(outletID) &&
      Object.keys(pickupInfo).length > 0
        ? "active"
        : "";
    return actTxt;
  }

  handleKeyPressDly = (event) => {
    var checkEvt = event ? event : window.event;
    var charCode = checkEvt.which ? checkEvt.which : checkEvt.keyCode;
    var pincodeValue = event.target.value.toLowerCase();
    pincodeValue = pincodeValue.trim();
    /* if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      var tempVal =
        pincodeValue !== "" ? pincodeValue.slice(0, -1) : pincodeValue;
      $("#delivery_postal_code").val(tempVal);
    } else { */
    if (pincodeValue.length > 2) {
      axios
        .get(
          apiUrlV2 + "outlets/zipcodesearch?zip_keytext=" + pincodeValue,
          headerconfig
        )
        .then((res) => {
          var deliveryOutletsArr = Array();
          if (res.data.status === "ok") {
            deliveryOutletsArr = res.data.result_set;
          }
          this.setState({
            deliveryZipcodeList: deliveryOutletsArr,
            pincode_input_value: pincodeValue,
          });
        });
    } else {
      var deliveryOutletsArr = Array();
      this.setState({
        deliveryZipcodeList: deliveryOutletsArr,
        pincode_input_value: pincodeValue,
      });
    }
    /* } */
  };

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo : "";
  }

  getZipcodeTxt(loaddata) {
    var zip_buno =
      loaddata.zip_buno !== "" ? stripslashes(loaddata.zip_buno) + " " : "";
    var zip_sname_code =
      loaddata.zip_sname !== ""
        ? stripslashes(loaddata.zip_sname) + ", Singapore " + loaddata.zip_code
        : "";
    var returnText = zip_buno + zip_sname_code;
    return returnText;
  }

  /* load delivery outlets  */
  loadDeliveryOutletsList() {
    if (Object.keys(this.state.deliveryZipcodeList).length > 0) {
      return this.state.deliveryZipcodeList.map((loaddata, index) => (
        <li key={index}>
          <a href="/" onClick={this.deliveryOutlet.bind(this, loaddata)}>
            {this.getZipcodeTxt(loaddata)}
          </a>
        </li>
      ));
    } else {
      var postcodeLngth = this.state.pincode_input_value;
      if (postcodeLngth !== "" && postcodeLngth.length > 2) {
        return (
          <li key={12} className="no-postal-list">
            Oh no, we currently do not deliver to this address/postal code.{" "}
            <br />
            Please try another address. Alternatively, you can choose{" "}
            <a href="/" onClick={this.selectPickupPopup.bind(this)}>
              self pick-up
            </a>{" "}
            instead.
          </li>
        );
      }
    }
  }

  activeDlyOutlet(outletID) {
    var orderOutletId =
      this.state.delivery_outlet_id !== ""
        ? this.state.delivery_outlet_id
        : cookie.load("orderOutletId");

    return orderOutletId === outletID ? "active" : "";
  }

  /* pick outlet */
  pickSiteLocation(loaddata, event) {
    if (event !== "" && event !== undefined) {
      event.preventDefault();
    }
    var unitNum = this.showUnitNum(
      loaddata.sl_pickup_unit_number1,
      loaddata.sl_pickup_unit_number2
    );
    var order_tat =
      this.props.globalsettings[0].result_set.order_tat_all
        .client_tat_setting_restaurants_pickup;
    /* 
      stripslashes(loaddata.sl_name) +
      " " + */
    var orderHandled =
      loaddata.sl_pickup_address_line1 +
      " " +
      loaddata.sl_pickup_address_line2 +
      ", " +
      unitNum +
      " Singapore " +
      loaddata.sl_pickup_postal_code;

    var pickupInfo = [];
    pickupInfo["orderOutletId"] = loaddata.sl_location_id;
    pickupInfo["orderOutletName"] = stripslashes(loaddata.sl_name);
    pickupInfo["orderPostalCode"] = loaddata.sl_pickup_postal_code;
    pickupInfo["orderTAT"] = "";
    pickupInfo["orderHandled"] = orderHandled;
    pickupInfo["orderHandledByText"] = orderHandled;
    pickupInfo["defaultAvilablityId"] = pickupId;

    this.setState(
      {
        pickupInfo: pickupInfo,
        pickup_location_text: orderHandled,
        seletedAvilablityId: pickupId,
        seletedOutletId: loaddata.sl_location_id,
        order_tat_time: order_tat,
        orderHandled: orderHandled,
      },
      function () {
        /* $.magnificPopup.open({
          items: {
            src: "#order-popup",
          },
          type: "inline",
        }); */
        this.validateDatetimeFun();
      }.bind(this)
    );
  }

  /* pick outlet */
  pickOutlet(loaddata, event) {
    if (event !== "" && event !== undefined) {
      event.preventDefault();
    }

    this.selectDatetm();
    var unitNum = this.showUnitNum(
      loaddata.outlet_unit_number1,
      loaddata.outlet_unit_number2
    );

    var orderHandled =
      stripslashes(loaddata.outlet_name) +
      " " +
      loaddata.outlet_address_line1 +
      " " +
      loaddata.outlet_address_line2 +
      ", " +
      unitNum +
      " Singapore " +
      loaddata.outlet_postal_code;

    var pickupInfo = [];
    pickupInfo["orderOutletId"] = loaddata.outlet_id;
    pickupInfo["orderOutletName"] = stripslashes(loaddata.outlet_name);
    pickupInfo["orderPostalCode"] = loaddata.outlet_postal_code;
    pickupInfo["orderTAT"] = loaddata.outlet_pickup_tat;
    pickupInfo["orderHandled"] = orderHandled;
    pickupInfo["orderHandledByText"] = orderHandled;
    pickupInfo["defaultAvilablityId"] = pickupId;

    this.setState(
      {
        pickupInfo: pickupInfo,
        seletedAvilablityId: pickupId,
        seletedOutletId: loaddata.outlet_id,
        order_tat_time: loaddata.outlet_pickup_tat,
        orderHandled: orderHandled,
      },
      function () {
        this.selectDatetm();
      }.bind(this)
    );
  }

  pickWithoutOutlet() {
    this.selectDatetm();
  }

  deliveryOutlet(loaddata, event) {
    event.preventDefault();
    $(".delivery_outletpoup").find(".outlet_error").html("");
    var zipcodeTxt = this.getZipcodeTxt(loaddata);
    this.setState(
      {
        delivery_pincode_text: zipcodeTxt,
        postalcodeInputVal: loaddata.zip_code,
      },
      function () {
        this.findOutletBasedZone(1, deliveryId);
        /*$.magnificPopup.open({
          items: {
            src: "#order-popup",
          },
          type: "inline",
        });*/
      }.bind(this)
    );
  }

  trgContinuBtn(idTxt) {
    $("#" + idTxt).trigger("click");
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "ordericon_link ";
    var availability = this.state.defaultAvilTy;
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  changPostalValue(type, pstVl) {
    if (type === 1) {
      $("#postalcode").val(pstVl);
    } else {
      $("#postalcode1").val(pstVl);
    }
  }

  userAddressList(typeTxt) {
    if (this.state.secondaryaddresslist.length > 0) {
      var addListTxt = this.state.secondaryaddresslist.map((addr, index) => (
        <div className="address_linfo" key={typeTxt + "-" + index}>
          <div className="custom_radio">
            <input
              type="radio"
              name={"address_chk" + typeTxt}
              value={addr.postal_code}
              className="address_chk"
              onChange={this.changPostalValue.bind(
                this,
                typeTxt,
                addr.postal_code
              )}
            />
            <span>
              {addressFormat(
                addr.unit_code,
                addr.unit_code2,
                addr.address,
                addr.country,
                addr.postal_code
              )}
            </span>
          </div>
        </div>
      ));

      return (
        <div>
          {addListTxt}
          <div className="address_linfo" key={typeTxt + "dfl"}>
            <div className="custom_radio">
              <input
                type="radio"
                name={"address_chk" + typeTxt}
                value=""
                defaultChecked={true}
                className="address_chk"
                onChange={this.changPostalValue.bind(this, typeTxt, "")}
              />
              <span>Enter New Address</span>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  /* For Advanced Slot */
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderDateTimeText() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    var seletedOrdSlotTxt = this.state.seleted_ord_slotTxt;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
      /* For Advanced Slot */
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();
      var orderDateTime = new Date(OrderDate);
      orderDateTime.setHours(OrderHours);
      orderDateTime.setMinutes(OrderMunts);
      orderDateTime.setSeconds(OrderSecnd);
      var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
      var deliveryTime =
        this.convPad(OrderHours) +
        ":" +
        this.convPad(OrderMunts) +
        ":" +
        this.convPad(OrderSecnd);

      if (seletedOrdSlotTxt !== "") {
        var fnlTimeStr = dateFormat(orderDateTime, "ddd, dd mmm yyyy");
        var dp_datetimetext = fnlTimeStr + ", " + seletedOrdSlotTxt;
      } else {
        var dp_datetimetext = dateFormat(
          orderDateTime,
          "ddd, dd mmm yyyy, h:MM:ss TT"
        );
      }

      this.setState(
        { delivery_pickup_datetimetext: dp_datetimetext },
        function () {
          $.magnificPopup.open({
            items: {
              src: "#order-popup",
            },
            type: "inline",
          });
        }.bind(this)
      );
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  setOrderDateTimeOnly() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    var seletedOrdSlotTxt = this.state.seleted_ord_slotTxt;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
      /* For Advanced Slot */
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();
      var orderDateTime = new Date(OrderDate);
      orderDateTime.setHours(OrderHours);
      orderDateTime.setMinutes(OrderMunts);
      orderDateTime.setSeconds(OrderSecnd);
      var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
      var deliveryTime =
        this.convPad(OrderHours) +
        ":" +
        this.convPad(OrderMunts) +
        ":" +
        this.convPad(OrderSecnd);

      if (seletedOrdSlotTxt !== "") {
        var fnlTimeStr = dateFormat(orderDateTime, "ddd, dd mmm yyyy");
        var dp_datetimetext = fnlTimeStr + ", " + seletedOrdSlotTxt;
      } else {
        var dp_datetimetext = dateFormat(
          orderDateTime,
          "ddd, dd mmm yyyy, h:MM:ss TT"
        );
      }

      var slotType =
        typeof cookie.load("slotType") === "undefined"
          ? ""
          : cookie.load("slotType");

      this.setState(
        { delivery_pickup_datetimetext: dp_datetimetext, slotType: slotType },
        function () {
          this.setOrderOutletDateTimeVal();
        }.bind(this)
      );
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  doneAvblDateTime() {
    var errorFlg = "no";
    if (
      this.state.seletedAvilablityId === deliveryId &&
      this.state.delivery_pincode_text === ""
    ) {
      errorFlg = "yes";
      $(".postalcodeinput_maindiv").addClass("inputerror-topdiv");
      $(".postalcodeinput_maindiv")
        .find(".inputerror-msg-cls")
        .html("Please select enter your postal code.");
      return false;
    }
    if (
      this.state.seletedAvilablityId === pickupId &&
      this.state.pickup_location_text === ""
    ) {
      errorFlg = "yes";
      $(".pickuplocationinput_maindiv").addClass("inputerror-topdiv");
      $(".pickuplocationinput_maindiv")
        .find(".inputerror-msg-cls")
        .html("Please select a location.");
      return false;
    }
    if (this.state.delivery_pickup_datetimetext === "") {
      errorFlg = "yes";
      if (this.state.seletedAvilablityId === deliveryId) {
        $(".deliverydatetimeinput_maindiv").addClass("inputerror-topdiv");
        $(".deliverydatetimeinput_maindiv")
          .find(".inputerror-msg-cls")
          .html("Please select delivery date & time.");
      } else if (this.state.seletedAvilablityId === pickupId) {
        $(".pickupdatetimeinput_maindiv").addClass("inputerror-topdiv");
        $(".pickupdatetimeinput_maindiv")
          .find(".inputerror-msg-cls")
          .html("Please select pick-up date & time.");
      }
      return false;
    }

    if (errorFlg === "no") {
      this.setOrderOutletDateTimeData();
    }
  }

  clearInputErrorMsg() {
    $(".inputerror-topdiv").find(".inputerror-msg-cls").html("");
    $(".focus-out").removeClass("inputerror-topdiv");
  }

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var orderInfoData =
        this.state.seletedAvilablityId === pickupId
          ? this.state.pickupInfo
          : this.state.deliveryInfo;
      if (Object.keys(orderInfoData).length > 0) {
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
        /* For Advanced Slot */
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);

        cookie.save("orderDateTime", orderDateTime, cookieConfig);
        cookie.save("deliveryDate", deliveryDate, cookieConfig);
        cookie.save("deliveryTime", deliveryTime, cookieConfig);

        var deliveryPincodeText = this.state.delivery_pincode_text;
        var pickupLocationText = this.state.pickup_location_text;
        var deliveryPickupDatetimetext =
          this.state.delivery_pickup_datetimetext;

        cookie.save("deliveryPincodeText", deliveryPincodeText, cookieConfig);
        cookie.save("pickupLocationText", pickupLocationText, cookieConfig);
        cookie.save(
          "deliveryPickupDatetimetext",
          deliveryPickupDatetimetext,
          cookieConfig
        );

        /* For Advanced Slot */
        var isAdvanced = this.state.isAdvanced;
        var slotType = this.state.slotType;
        var orderSlotVal = "",
          orderSlotTxt = "",
          orderSlotStrTime = "",
          orderSlotEndTime = "";
        if (slotType === "2") {
          orderSlotVal = this.state.seleted_ord_slot;
          orderSlotTxt = this.state.seleted_ord_slotTxt;
          orderSlotStrTime = this.state.seleted_ord_slot_str;
          orderSlotEndTime = this.state.seleted_ord_slot_end;
        }
        cookie.save("isAdvanced", isAdvanced, cookieConfig);
        cookie.save("slotType", slotType, cookieConfig);
        cookie.save("orderSlotVal", orderSlotVal, cookieConfig);
        cookie.save("orderSlotTxt", orderSlotTxt, cookieConfig);
        cookie.save("orderSlotStrTime", orderSlotStrTime, cookieConfig);
        cookie.save("orderSlotEndTime", orderSlotEndTime, cookieConfig);
        /* For Advanced Slot End */

        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save(
            "orderZoneId",
            orderInfoData["orderZoneId"],
            cookieConfig
          );

          cookie.save(
            "orderDeliveryAddress",
            orderInfoData["orderDeliveryAddress"],
            cookieConfig
          );
        }
        cookie.save(
          "orderOutletName",
          orderInfoData["orderOutletName"],
          cookieConfig
        );

        cookie.save(
          "orderPostalCode",
          orderInfoData["orderPostalCode"],
          cookieConfig
        );
        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save(
            "deliveryPostalCode",
            orderInfoData["orderPostalCode"],
            cookieConfig
          );
        }
        if (orderInfoData["orderTAT"] !== "") {
          cookie.save("orderTAT", orderInfoData["orderTAT"], cookieConfig);
        } else {
          cookie.save("orderTAT", this.state.order_tat_time, cookieConfig);
        }

        cookie.save(
          "orderHandled",
          orderInfoData["orderHandled"],
          cookieConfig
        );
        cookie.save(
          "defaultAvilablityId",
          orderInfoData["defaultAvilablityId"],
          cookieConfig
        );
        this.setState({
          defaultAvilTy: orderInfoData["defaultAvilablityId"],
          orderPostalCode: orderInfoData["orderPostalCode"],
        });
        cookie.save(
          "orderHandledByText",
          orderInfoData["orderHandledByText"],
          cookieConfig
        );

        $.magnificPopup.close();
        if (
          (this.props.match.params.slugRestaurant !== "" &&
            typeof this.props.match.params.slugRestaurant !== undefined &&
            typeof this.props.match.params.slugRestaurant !== "undefined") ||
          this.props.match.path === "/restaurants"
        ) {
          location.reload();
          return false;
        }
        if (
          cookie.load("checkoutredirect") === "yes" &&
          this.state.trgavliblpopup !== "yes"
        ) {
          this.props.history.push("/checkout");
        } else if (
          cookie.load("popuptriggerFrom") === "FeaturedPro" &&
          this.state.trgavliblpopup !== "yes"
        ) {
          cookie.remove("popuptriggerFrom", cookieConfig);
          //this.props.history.push("/");
          this.props.history.push("/refpage/");
        } else {
          location.reload();
        }
      } else {
        $.magnificPopup.open({
          items: {
            src: "#outlet-error-popup",
          },
          type: "inline",
        });
      }
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  setOrderOutletDateTimeVal() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
      /* For Advanced Slot */
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();
      var orderDateTime = new Date(OrderDate);
      orderDateTime.setHours(OrderHours);
      orderDateTime.setMinutes(OrderMunts);
      orderDateTime.setSeconds(OrderSecnd);

      var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
      var deliveryTime =
        this.convPad(OrderHours) +
        ":" +
        this.convPad(OrderMunts) +
        ":" +
        this.convPad(OrderSecnd);

      cookie.save("orderDateTime", orderDateTime, cookieConfig);
      cookie.save("deliveryDate", deliveryDate, cookieConfig);
      cookie.save("deliveryTime", deliveryTime, cookieConfig);

      var deliveryPincodeText = this.state.delivery_pincode_text;
      var pickupLocationText = this.state.pickup_location_text;
      var deliveryPickupDatetimetext = this.state.delivery_pickup_datetimetext;

      cookie.save("deliveryPincodeText", deliveryPincodeText, cookieConfig);
      cookie.save("pickupLocationText", pickupLocationText, cookieConfig);
      cookie.save(
        "deliveryPickupDatetimetext",
        deliveryPickupDatetimetext,
        cookieConfig
      );

      /* For Advanced Slot */
      var isAdvanced = this.state.isAdvanced;
      var slotType = this.state.slotType;
      var orderSlotVal = "",
        orderSlotTxt = "",
        orderSlotStrTime = "",
        orderSlotEndTime = "";
      if (slotType === "2") {
        orderSlotVal = this.state.seleted_ord_slot;
        orderSlotTxt = this.state.seleted_ord_slotTxt;
        orderSlotStrTime = this.state.seleted_ord_slot_str;
        orderSlotEndTime = this.state.seleted_ord_slot_end;
      }
      cookie.save("isAdvanced", isAdvanced, cookieConfig);
      cookie.save("slotType", slotType, cookieConfig);
      cookie.save("orderSlotVal", orderSlotVal, cookieConfig);
      cookie.save("orderSlotTxt", orderSlotTxt, cookieConfig);
      cookie.save("orderSlotStrTime", orderSlotStrTime, cookieConfig);
      cookie.save("orderSlotEndTime", orderSlotEndTime, cookieConfig);
      /* For Advanced Slot End */

      $.magnificPopup.close();
      location.reload();
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  cart_Outlet_List = (field, value) => {
    if (
      this.props.location.pathname !== "/myaccount" &&
      this.props.location.pathname !== "/myorders" &&
      this.props.location.pathname !== "/mypromotions" &&
      this.props.match.path !== "/thankyou/:orderId"
    ) {
      if (field !== "") {
        if (field === "cartOutletList" && this.state.slugRestaurant !== "") {
          this.props.sateValChange("cartOutletList", value);
        }
        if (field === "totalCartCount") {
          if (this.state.slugRestaurant !== "") {
            this.props.sateValChange("totalCartCount", value);
          }
        }
        if (field === "cartDetails" && value !== "") {
          this.props.sateValChange("cartDetails", value);
        }
        if (field === "cartflg" && value !== "") {
          this.setState({ cartTriggerFlg: value });
        }
      }
    }
  };

  selectAvailabilityPopup(availability) {
    $.magnificPopup.close();
    this.clearInputErrorMsg();
    if (availability === "awesome-popup") {
      $.magnificPopup.open({
        items: {
          src: "#awesome-popup",
        },
        type: "inline",
      });
      return false;
    } else {
      var avlPopupId =
        availability === "delivery"
          ? "#delevery-postcode-popup"
          : "#takeaway-popup";
      $.magnificPopup.open({
        items: {
          src: avlPopupId,
        },
        type: "inline",
        midClick: false,
        callbacks: {
          open: function () {
            setTimeout(function () {
              if (availability === "delivery") {
                $("#delivery_postal_code").focus();
              }
            }, 100);
          },
        },
      });
      return false;
    }
  }
  selectPickupPopup(event) {
    event.preventDefault();
    this.chooseAvailability(pickupId);
    $.magnificPopup.close();
    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
    return false;
  }

  /* Advanced Slot */
  showOrdTimeSlot() {
    var TodayDate = new Date();
    var deliveryDate =
      cookie.load("deliveryDate") !== "" &&
      typeof cookie.load("deliveryDate") !== undefined &&
      typeof cookie.load("deliveryDate") !== "undefined" &&
      cookie.load("deliveryDate") !== undefined &&
      cookie.load("deliveryDate") !== "undefined"
        ? cookie.load("deliveryDate")
        : "";
    if (deliveryDate !== "") {
      var order_date_exist = "";
      if (deliveryDate !== "") {
        var delivery_Date = deliveryDate.split("/");
        TodayDate = new Date(new Date(TodayDate).setDate(delivery_Date[0]));
        TodayDate = new Date(
          new Date(TodayDate).setMonth(delivery_Date[1] - 1)
        );
        TodayDate = new Date(new Date(TodayDate).setFullYear(delivery_Date[2]));

        order_date_exist = TodayDate;
      }

      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined" &&
        cookie.load("deliveryTime") !== undefined &&
        cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";
      var order_time_exist = "";
      if (deliveryTime !== "") {
        var delivery_Time = deliveryTime.split(":");
        TodayDate = new Date(new Date(TodayDate).setHours(delivery_Time[0]));
        TodayDate = new Date(new Date(TodayDate).setMinutes(delivery_Time[1]));
        TodayDate = new Date(new Date(TodayDate).setSeconds(delivery_Time[2]));
        order_time_exist = TodayDate;
      }
      var order_is_timeslot =
        cookie.load("slotType") !== "" &&
        typeof cookie.load("slotType") !== undefined &&
        typeof cookie.load("slotType") !== "undefined" &&
        cookie.load("slotType") !== undefined &&
        cookie.load("slotType") !== "undefined"
          ? cookie.load("slotType")
          : 1;

      var orderSlotEndTime =
        cookie.load("orderSlotEndTime") !== "" &&
        typeof cookie.load("orderSlotEndTime") !== undefined &&
        typeof cookie.load("orderSlotEndTime") !== "undefined" &&
        cookie.load("orderSlotEndTime") !== undefined &&
        cookie.load("orderSlotEndTime") !== "undefined"
          ? cookie.load("orderSlotEndTime")
          : "";
      var orderSlotStrTime =
        cookie.load("orderSlotStrTime") !== "" &&
        typeof cookie.load("orderSlotStrTime") !== undefined &&
        typeof cookie.load("orderSlotStrTime") !== "undefined" &&
        cookie.load("orderSlotStrTime") !== undefined &&
        cookie.load("orderSlotStrTime") !== "undefined"
          ? cookie.load("orderSlotStrTime")
          : "";
      var ordTmSlt = Moment(order_date_exist).format("hh:mm A");

      if (
        order_is_timeslot === "2" &&
        orderSlotStrTime !== "" &&
        orderSlotEndTime !== ""
      ) {
        var slotTime1 =
          orderSlotStrTime !== "" ? orderSlotStrTime.split(":") : Array();
        var slotTime2 =
          orderSlotEndTime !== "" ? orderSlotEndTime.split(":") : Array();

        if (
          Object.keys(slotTime1).length > 0 &&
          Object.keys(slotTime2).length > 0
        ) {
          var startTimeVal = parseInt(slotTime1[0]);
          var startMinitVal = parseInt(slotTime1[1]);
          var strdatevalobj = new Date();
          strdatevalobj.setHours(startTimeVal);
          strdatevalobj.setMinutes(startMinitVal);

          var endTimeVal = parseInt(slotTime2[0]);
          var endMinitVal = parseInt(slotTime2[1]);
          var enddatevalobj = new Date();
          enddatevalobj.setHours(endTimeVal);
          enddatevalobj.setMinutes(endMinitVal);

          ordTmSlt =
            format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
        }
      }

      return format(order_date_exist, "iii dd LLL yyyy") + " " + ordTmSlt;
    }
  }

  handleChangeTxt = (item, event) => {
    var value = event.target.value;
    this.setState({ [item]: value }, function () {
      this.props.sateValChange("search_keyword", value);
    });
  };
  handleKeyDown = (event) => {
    if (
      this.props.match.path !== "/search/:searchKeyword" &&
      this.props.match.path !== "/search"
    ) {
      var currentThis = this;
      if (event.keyCode === 13) {
        event.preventDefault();
        currentThis.props.history.push("/search/" + this.state.search_keyword);
      }
    }
  };

  openSearch() {
    /* if (this.props.match.path !== "/search") {
      this.props.history.push("/search/" + this.state.search_keyword);
    } */
  }
  checkAblBtn() {
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    var actText = "Order Now";
    if (
      availability === deliveryId /*  &&
      orderOutletId !== "" &&
      orderOutletId !== undefined */
    ) {
      actText = "Delivery";
    } else if (
      availability === pickupId /*  &&
      orderOutletId !== "" &&
      orderOutletId !== undefined */
    ) {
      actText = "Takeaway";
    } else if (
      availability === dineinId /*  &&
      orderOutletId !== "" &&
      orderOutletId !== undefined */
    ) {
      actText = "Dine In";
    }
    return (
      <a
        href="/"
        onClick={this.closepopup.bind(this)}
        className="hordertype_btn"
        title={actText}
      >
        {actText}
      </a>
    );
  }
  searBlkFun(typeTxt) {
    var defaultAvilTyId = cookie.load("defaultAvilablityId");
    var liTopCls = "htico_search";
    var scearDivTopCls = "hsearch_sec";
    var triggerActCls = "hsearch_trigger";
    var searchResultCls = "search_result";
    var searchIconcls = "search_i_icon";
    if (typeTxt === "mbl") {
      liTopCls = "htico_search_mbl";
      scearDivTopCls = "hsearch_sec_mbl";
      triggerActCls = "hsearch_trigger_mbl";
      searchResultCls = "search_result_mbl";
      searchIconcls = "fa fa-search";
    }
    var settingsArr = this.props.globalsettings;
    if (Object.keys(settingsArr).length > 0) {
      return (
        <>
          <a href={void 0} className={triggerActCls} title="Search">
            <img className="search-glass" src={searchicon} />
          </a>

          <div className={scearDivTopCls}>
            <div className="input-sec header-search-sec">
              <input
                type="text"
                name="searchkey"
                className="productsearch"
                id={"productsearch_" + typeTxt}
                placeholder="Search..."
                onKeyUp={this.searchProKeyPress.bind(this)}
              />

              <a
                href="/"
                id="clearSearch"
                onClick={this.clearSearchFun.bind(this)}
                className="search_text_clear disbl_href_action"
              >
                X
              </a>
            </div>

            <ul className={searchResultCls} id={searchResultCls}>
              {this.state.searchResultDispaly}
            </ul>
          </div>
        </>
      );
    }
  }

  startQRScan() {
    this.setState({ dine_in_click: "Yes" });
    $.magnificPopup.close();
    this.props.history.push("/scanqrcode");
  }

  handleScan = (data) => {
    if (data) {
      // cookie.save('orderTableNo', data,{ path: '/' });
      showLoader("dinein_popup_item", "class");
      this.setState({ dine_in_click: "No", table_number: data });
      // let table_no = data;
      this.findTable();
    }
  };
  handleError = (err) => {
    this.setState({ dine_in_click: "No" });
    $.magnificPopup.close();
  };

  findTable() {
    showLoader("dinein_popup_item", "class");

    var table_number = this.state.table_number;

    if (table_number !== "") {
      this.setState({ seletedAvilablityId: dineinId });
      var qs = require("qs");
      var postObject = {
        app_id: appId,
        tm_table_number: table_number,
      };
      this.props.getTableAvailability(qs.stringify(postObject));
    } else {
      hideLoader("dinein_popup_item", "class");
      showAlert("Error", "Scan QR or Enter Table Number to Continue!");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  HandleTableNumberchng = (event) => {
    // this.findTable(event.target.value);
    this.setState({ dine_in_click: "No", table_number: event.target.value });
  };

  dineinOutlet(outletDetails) {
    $.magnificPopup.close();

    var outlet_name = outletDetails.outlet_name;
    var outlet_id = outletDetails.outlet_id;
    var outlet_unit_number1 = outletDetails.outlet_unit_number1;
    var outlet_unit_number2 = outletDetails.outlet_unit_number2;
    var outlet_address_line1 = outletDetails.outlet_address_line1;
    var outlet_address_line2 = outletDetails.outlet_address_line2;
    var outlet_postal_code = outletDetails.outlet_postal_code;
    var outlet_dine_tat = outletDetails.outlet_dine_tat;
    var outlet_location_id = outletDetails.outlet_location_id;

    if (outlet_unit_number1 != "" && outlet_unit_number2 != "") {
      var unitTxt = ", #" + outlet_unit_number1 + "-" + outlet_unit_number2;
      var orderHandled =
        outlet_name +
        " " +
        outlet_address_line1 +
        " " +
        outlet_address_line2 +
        unitTxt +
        ", " +
        CountryTxt +
        " " +
        outlet_postal_code;
    } else if (outlet_unit_number1 != "" || outlet_unit_number2 != "") {
      var unitTxt =
        outlet_unit_number1 != ""
          ? ", #" + outlet_unit_number1
          : ", #" + outlet_unit_number2;
      var orderHandled =
        outlet_name +
        " " +
        outlet_address_line1 +
        " " +
        outlet_address_line2 +
        unitTxt +
        ", Singapore " +
        outlet_postal_code;
    } else {
      var orderHandled =
        outlet_name +
        " " +
        outlet_address_line1 +
        " " +
        outlet_address_line2 +
        ", Singapore " +
        outlet_postal_code;
    }
    cookie.save("orderTableNo", this.state.table_number, { path: "/" });
    this.setState({ orderHandled: orderHandled });
    cookie.save("defaultAvilablityId", dineinId, { path: "/" });
    cookie.save("orderOutletId", outlet_id, { path: "/" });
    cookie.save("orderOutletName", outlet_name, { path: "/" });
    cookie.save("orderOutletSlug", outletDetails.outlet_slug, { path: "/" });
    cookie.save("orderPostalCode", outlet_postal_code, { path: "/" });
    cookie.save("orderTAT", outlet_dine_tat, { path: "/" });
    cookie.save("orderHandled", orderHandled, { path: "/" });
    cookie.save("orderHandledByText", orderHandled, { path: "/" });
    if (cookie.load("openqrcode") !== "Yes") {
      this.props.destroyCartDetail();
    }

    if (cookie.load("openqrcode") === "Yes") {
      this.props.history.push("/checkout");
      cookie.remove("openqrcode", cookieConfig);
    } else if (this.props.match.path === "/food/:slugRestaurant/") {
      this.props.history.push(
        "/refpage/restaurant/" + outletDetails.outlet_slug
      );
    } else {
      this.props.history.push("/food/" + outletDetails.outlet_slug);
    }
  }

  clearSearchFun() {
    var emtytxt = "";
    $(".productsearch").val(emtytxt);
    this.setState({ selectedProResult: this.state.searchProResult });
  }

  getSearchProductList() {
    var orderOutletIdtext = cookie.load("orderOutletId");
    var addquery_txt = "";
    if (typeof orderOutletIdtext !== "undefined" && orderOutletIdtext !== "") {
      addquery_txt = "&outletId=" + orderOutletIdtext;
    }
    var searchResult = [];
    return axios
      .get(
        apiUrlV2 +
          "products/search_products?app_id=" +
          appId +
          "&status=A&availability=" +
          cookie.load("defaultAvilablityId") +
          addquery_txt
      )
      .then((response) => {
        if (response.data.status === "ok") {
          var resultData = response.data.result_set;
          for (var key in resultData) {
            var subcatListArr = !("product_list" in resultData[key])
              ? Array()
              : resultData[key].product_list;

            if (Object.keys(subcatListArr).length > 0) {
              if (Object.keys(subcatListArr[0]).length > 0) {
                var subCatArr = !("subcategorie" in subcatListArr[0][0])
                  ? Array()
                  : subcatListArr[0][0].subcategorie;

                for (var sctkey in subCatArr) {
                  var productsArr = !("products" in subCatArr[sctkey])
                    ? Array()
                    : subCatArr[sctkey].products;
                  for (var prokey in productsArr) {
                    var proNameTxt = getAliasName(
                      productsArr[prokey].product_alias,
                      productsArr[prokey].product_name
                    );
                    searchResult.push({
                      cate_slug: subCatArr[sctkey].pro_cate_slug,
                      subcate_slug: subCatArr[sctkey].pro_subcate_slug,
                      value: productsArr[prokey].product_slug,
                      label: stripslashes(proNameTxt),
                    });
                  }
                }
              }
            }
          }
        }
        if (
          this.props.match.path === "/menu/:slugType/:slugValue" ||
          this.props.match.path === "/menu"
        ) {
          this.props.sateValChange("productlist", searchResult);
        }

        this.setState({
          searchProResult: searchResult,
          selectedProResult: searchResult,
        });
      });
  }

  searchProKeyPress(event) {
    var value = event.target.value.toLowerCase();
    axios
      .get(
        apiUrl +
          "products/appsearch?app_id=" +
          appId +
          "&search_keyword=" +
          value,
        headerconfig
      )
      .then((res) => {
        /* Success response */
        if (res.data.status === "ok") {
          this.setState({ searchResult: res.data.productList }, function () {
            this.loadProSearchList();
          });
        } else if (res.data.status === "error") {
          /* Error response */
          this.setState({ searchResult: [] }, function () {
            this.loadProSearchList();
          });
        }
      });
  }

  /* load product search result  */
  loadProSearchList() {
    var searchResultDispaly = "";
    if (this.state.searchResult.length > 0) {
      searchResultDispaly = this.state.searchResult.map((loaddata, index) => (
        <li key={index}>
          <Link
            to={
              "/" +
              restaurantLink +
              "/" +
              loaddata.outlet_slug +
              "/" +
              loaddata.cat_slug +
              "/" +
              loaddata.subcate_slug +
              "/" +
              loaddata.product_slug
            }
            title="Product Details"
            onClick={this.closeSearch.bind(this)}
          >
            {loaddata.product_alias !== "" && loaddata.product_alias !== null
              ? stripslashes(loaddata.product_alias)
              : stripslashes(loaddata.product_name)}
          </Link>
        </li>
      ));
    } else {
      searchResultDispaly = <li className="no-pro-found">No Product found</li>;
    }
    this.setState({ searchResultDispaly: searchResultDispaly }, function () {
      $(".search_result").show();
      if ($("#search_result_mbl").length > 0) {
        $("#search_result_mbl").show();
      }
    });
  }
  closeSearch() {
    $(".hsearch_trigger_mbl").trigger("click");
  }
  clearSearchFun() {
    var emtytxt = "";
    $(".productsearch").val(emtytxt);
    this.setState({ selectedProResult: this.state.searchProResult });
  }

  render() {
    var currenturl = window.location.href;
    var substring = "products";
    var isCheckout = "checkout";

    var showCatryName =
      typeof this.props.showCatryName !== "undefined"
        ? this.props.showCatryName
        : "Category Name";

    var currentSlug = this.props.match.path;
    return (
      <>
        {this.state.headerInfo}
        {this.state.globalsettings !== "" && (
          <Helmet>
            {this.state.globalsettings.client_meta_title !== "" &&
              this.state.globalsettings.client_meta_title !== null &&
              currentSlug !== "/food/:slugRestaurant/" &&
              currentSlug !==
                "/food/:slugRestaurant/:slugType/:slugValue/:proValue" &&
              currentSlug !==
                "/food/:slugRestaurant/:slugType/:slugValue/:proValue" &&
              currentSlug !== "/food/:slugRestaurant/:slugType/:slugValue" && (
                <meta
                  name="title"
                  content={this.state.globalsettings.client_meta_title}
                />
              )}
            {this.state.globalsettings.client_meta_description !== "" &&
              this.state.globalsettings.client_meta_description !== null &&
              currentSlug !== "/food/:slugRestaurant/" &&
              currentSlug !==
                "/food/:slugRestaurant/:slugType/:slugValue/:proValue" &&
              currentSlug !==
                "/food/:slugRestaurant/:slugType/:slugValue/:proValue" &&
              currentSlug !== "/food/:slugRestaurant/:slugType/:slugValue" && (
                <meta
                  name="description"
                  content={this.state.globalsettings.client_meta_description}
                />
              )}
            {this.state.globalsettings.client_meta_keyword !== "" &&
              this.state.globalsettings.client_meta_keyword !== null &&
              currentSlug !== "/food/:slugRestaurant/" &&
              currentSlug !==
                "/food/:slugRestaurant/:slugType/:slugValue/:proValue" &&
              currentSlug !==
                "/food/:slugRestaurant/:slugType/:slugValue/:proValue" &&
              currentSlug !== "/food/:slugRestaurant/:slugType/:slugValue" && (
                <meta
                  name="keywords"
                  content={this.state.globalsettings.client_meta_keyword}
                />
              )}

            {this.state.globalsettings.client_meta_title !== "" &&
              this.state.globalsettings.client_meta_title !== null && (
                <meta
                  name="og:title"
                  content={this.state.globalsettings.client_meta_title}
                />
              )}
            {this.state.globalsettings.client_meta_description !== "" &&
              this.state.globalsettings.client_meta_description !== null && (
                <meta
                  name="og:description"
                  content={this.state.globalsettings.client_meta_description}
                />
              )}
          </Helmet>
        )}
        {/*currentSlug !== "/checkout" && currentSlug !== "/thankyou/:orderId" && (
          <div className="header-login-area">
            <div className="container">
              <ul>
                {!cookie.load("UserId") && (
                  <li className="hla-login">
                    <a
                      href="#login-popup"
                      data-effect="mfp-zoom-in"
                      className="open-popup-link"
                    >
                      <img src={profile} alt="avatar" /> Login
                    </a>
                  </li>
                )}
                {cookie.load("UserId") && (
                  <li className="hla-login">
                    <Link
                      to={
                        cookie.load("userLoginFrom") === "ocid"
                          ? "/myaccount"
                          : "/"
                      }
                    >
                      <img src={profile} alt="avatar" />{" "}
                      {cookie.load("UserFname")}
                    </Link>
                  </li>
                )}
                {this.state.headerlinks !== "" && (
                  <li>{this.state.headerlinks}</li>
                )}
              </ul>
            </div>
          </div>
        )*/}

        <header>
          {this.state.globalsettings !== "" &&
            this.state.globalsettings.client_web_maintenance_mode === "Yes" && (
              <div className="top-info-menu">
                <div className="container">
                  <div className="info-text">
                    <p>{this.state.globalsettings.client_maintenance_desc}</p>
                  </div>
                </div>
              </div>
            )}

          <div className="header-top-cls">
            <div className="container">
              <div className="logo-main-section">
                <div className="mobile-menu with-login">
                  <div className="mobile-menu-header">
                    <div className="mobile-menu-close">
                      <span id="close_mobile_menu">
                        <img src={closepurple} alt="Close" />
                      </span>
                    </div>
                    <div className="mobile-menu-logo">
                      <Link
                        to={"https://www.sswimclub.org.sg/"}
                        title={projectTitle}
                        target="_blank"
                      >
                        <img src={mainLogo} alt="Logo" />
                      </Link>
                    </div>
                  </div>
                  <div className="mobile-menu-body">
                    <ul className="menu">
                      {this.state.menudata && this.listMainNavigation()}
                      {/* {!cookie.load("UserId") && (
                        <>
                          <li>
                            <a
                              href="#login-main-popup"
                              data-effect="mfp-zoom-in"
                              className="open-popup-link htico_sign"
                              title="Login"
                              onClick={(e) => {
                                e.preventDefault();
                                $("body").removeClass("mbl-hiden");
                              }}
                            >
                              <span>Login</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#login-main-popup"
                              data-effect="mfp-zoom-in"
                              className="open-popup-link"
                              title="Sign up"
                              onClick={(e) => {
                                e.preventDefault();
                                $("body").removeClass("mbl-hiden");
                              }}
                            >
                              {" "}
                              <span>Sign Up</span>
                            </a>
                          </li>
                        </>
                      )} */}

                      {/* {cookie.load("UserId") && (
                        <>
                          {cookie.load("loginTableNo") !== "Yes" && (
                            <li>
                              <Link
                                className={
                                  this.props.match.path === "/myaccount" ||
                                  this.props.match.path === "/myorders" ||
                                  this.props.match.path === "/rewards" ||
                                  this.props.match.path === "/voucher" ||
                                  this.props.match.path === "/mypromotions"
                                    ? "htico_sign active"
                                    : "htico_sign"
                                }
                                to="/myaccount"
                                title="My Account"
                              >
                                <i /> <span>My Account</span>
                              </Link>
                            </li>
                          )}

                          <li>
                            <Link
                              className="htico_sign"
                              to="/logout"
                              title="Logout"
                            >
                              <i /> <span>Logout</span>
                            </Link>
                          </li>
                        </>
                      )} */}
                    </ul>
                  </div>
                </div>

                {this.props.match.path.includes("/checkout") ? (
                  <a
                    href={`/food/${
                      this.state.seletedOutletId && this.state.overAllcart
                        ? this.state.overAllcart.outletslug?.[
                            this.state.seletedOutletId
                          ]
                        : "the-palms"
                    }`}
                    className="back-to-menu"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      dataSlot="icon"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                    Back to Menu
                  </a>
                ) : (
                  <div className="menu_icon trigger_menunav_act">
                    {/*   <span className="icon-bar icon-bar1"></span>
                    <span className="icon-bar icon-bar2"></span>
                    <span className="icon-bar icon-bar3"></span> */}
                  </div>
                )}

                <div className="logo">
                  <a
                    href={"https://www.sswimclub.org.sg/"}
                    target="_blank"
                    title={projectTitle}
                  >
                    <img src={mainLogo} alt="Logo" />
                  </a>
                </div>
                <div className="hmenu_sec">
                  <ul className="hmenu_list desktop_hmenu_list">
                    {this.state.menudata && this.listMainNavigation()}
                  </ul>
                </div>

                <div className="hmenu-login-section">
                  <ul className="hmenu-login-act">
                    {/* this.state.orderPostalCode !== "" && (
                      <li>
                        <div
                          className="header-address"
                          onClick={this.gobackAvblPoup.bind(this)}
                        >
                          <div className="header-info">
                            <img
                              src={mappin}
                              alt="Map"
                              title="Map"
                              className="map-loc"
                            />
                            <a href={void 0}>
                              {this.state.defaultAvilTy === deliveryId ? (
                                <>Deliver to S{this.state.orderPostalCode}</>
                              ) : (
                                <>Pick-up from S{this.state.orderPostalCode}</>
                              )}{" "}
                              <img src={more} alt="Arrow" title="Arrow" />
                            </a>
                            <span>{this.showOrdTimeSlot()}</span>
                           
                          </div>
                        </div>
                      </li>
                    ) */}

                    {this.searBlkFun("mbl")}

                    {cookie.load("UserId") ? (
                      <>
                        <li
                          className="hsign_sec"
                          style={{
                            display:
                              this.state.menudata !== "" &&
                              typeof this.state.menudata !== undefined &&
                              typeof this.state.menudata !== "undefined" &&
                              this.state.menudata.length > 0
                                ? ""
                                : "none",
                          }}
                          key="2"
                        >
                          {cookie.load("loginTableNo") !== "Yes" && (
                            <>
                              <Link
                                className={
                                  this.props.match.path === "/myaccount" ||
                                  this.props.match.path === "/myorders" ||
                                  this.props.match.path === "/rewards" ||
                                  this.props.match.path === "/mypromotions"
                                    ? "htico_sign active"
                                    : "htico_sign"
                                }
                                to={"/myaccount"}
                                title="My Account"
                              >
                                <i /> <span>My Account</span>
                              </Link>
                              <span>|</span>
                            </>
                          )}
                          <Link
                            className="htico_sign"
                            to="/logout"
                            title="Logout"
                          >
                            <i /> <span>Logout</span>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <li className="hsign_sec">
                        <a
                          href="#login-main-popup"
                          data-effect="mfp-zoom-in"
                          className="open-popup-link htico_sign"
                          title="Login"
                          onClick={(e) => {
                            e.preventDefault();
                            $.magnificPopup.open({
                              items: {
                                src: "#login-main-popup",
                              },
                              type: "inline",
                            });
                          }}
                        >
                          <span>Login</span>
                        </a>
                        <span>|</span>
                        <a
                          href="#signup-popup"
                          data-effect="mfp-zoom-in"
                          className="open-popup-link"
                          onClick={(e) => {
                            e.preventDefault();
                            $.magnificPopup.open({
                              items: {
                                src: "#login-main-popup",
                              },
                              type: "inline",
                            });
                          }}
                          title="Sign up"
                        >
                          {" "}
                          <span>Sign Up</span>
                        </a>
                      </li>
                    )}

                    <li className="hordertype_sec">{this.checkAblBtn()}</li>
                    <li className="htico_search">
                      {this.searBlkFun("desktop")}
                    </li>

                    {this.props.match.path !== "/package" &&
                      this.props.match.path !== "/package/checkout" && (
                        <li className="htico_cart">
                          <CartSideBar
                            {...this.props}
                            headerState={this.state}
                            prpSateValChange={this.props.sateValChange}
                            cartTriggerFlg={this.props.cartTriggerFlg}
                            cartClearTriggerFlg={this.props.cartClearTriggerFlg}
                            cart_Outlet_List={this.cart_Outlet_List}
                          />
                        </li>
                      )}
                    {this.props.match.path === "/package" && (
                      <li className="htico_cart catering_htico_cart">
                        <Link to="/package" className="card_icon">
                          <img src={shoppingBag} alt="cart" />
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* {!this.props.match.path.includes("/food") ? (
            <div className="mobile-order-now">
              <div className="mobile-order-lhs">
                {cookie.load("UserId") ? (
                  <>
                    {cookie.load("loginTableNo") !== "Yes" && (
                      <Link
                        className={
                          this.props.match.path === "/myaccount" ||
                          this.props.match.path === "/myorders" ||
                          this.props.match.path === "/rewards" ||
                          this.props.match.path === "/mypromotions"
                            ? "htico_sign active"
                            : "htico_sign"
                        }
                        to={"/myaccount"}
                        title="My Account"
                      >
                        <i /> <span>My Account</span>
                      </Link>
                    )}{" "}
                    <Link to="/logout" title="Logout">
                      <span>Logout</span>
                    </Link>
                  </>
                ) : (
                  <>
                    <a
                      href="#login-main-popup"
                      data-effect="mfp-zoom-in"
                      title="Login"
                      onClick={(e) => {
                        e.preventDefault();
                        $.magnificPopup.open({
                          items: {
                            src: "#login-main-popup",
                          },
                          type: "inline",
                        });
                      }}
                    >
                      <span>Login</span>
                    </a>
                    <a
                      href="#login-main-popup"
                      data-effect="mfp-zoom-in"
                      title="Login"
                      onClick={(e) => {
                        e.preventDefault();
                        $.magnificPopup.open({
                          items: {
                            src: "#login-main-popup",
                          },
                          type: "inline",
                        });
                      }}
                    >
                      <span>Signup</span>
                    </a>
                  </>
                )}
              </div>
              <div className="mobile-order-rhs">{this.checkAblBtn()}</div>
            </div>
          ) : ""} */}

          {currenturl.includes(substring) && (
            <div className="hcategory_sec">
              <a href="/" className="hcategory_selected_text disbl_href_action">
                {showCatryName} <span className="hcategory_trigger"></span>
              </a>
              <div className="hcategory_menu ">
                <MenuNavigationmob />
              </div>
            </div>
          )}

          <div
            className="custom_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
            id="jquery-success-msg"
            style={{ display: "none" }}
          >
            {" "}
            <a
              href="/"
              type="button"
              className="custom_close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-success-msg">
              Great! Your item has been added to the cart
            </p>{" "}
          </div>

          <div
            className="custom_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
            id="jquery-error-msg"
            style={{ display: "none" }}
          >
            {" "}
            <a
              href="/"
              className="custom_close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-error-msg">Something went wrong</p>{" "}
          </div>

          <div
            className="custom_center_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
            id="jquery-common-success-msg"
            style={{ display: "none" }}
          >
            {" "}
            <a
              href="/"
              type="button"
              className="custom_close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-common-success-msg">
              Great! Your item has been added to the cart
            </p>{" "}
          </div>

          <div
            className="custom_center_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
            id="jquery-common-error-msg"
            style={{ display: "none" }}
          >
            {" "}
            <a
              href="/"
              className="custom_close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-common-error-msg">Something went wrong</p>{" "}
          </div>

          {/* login popup */}
          <div id="login-main-popup" className="mfp-hide popup_sec login-popup">
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3> Continue to Checkout </h3>
                <p>Update your informations and continue</p>
              </div>
              <div className="full-login-new-body">
                <div className="popup-logo-body">
                  <img src={popuplogo} alt="" />
                  <h4>Membership</h4>
                  <p>Earn & Redeem Points with every order</p>
                </div>
                <br />
                <Login
                  fields={this.state.fields}
                  onChange={this.fieldChange}
                  onValid={this.handleSignin}
                  onInvalid={() => console.log("Form invalid!")}
                />
                <div className="popup-logo-button">
                  {/* <a
                    href="#login-popup"
                    data-effect="mfp-zoom-in"
                    title="Login"
                    className="button btnnow"
                    onClick={(e) => {
                      e.preventDefault();
                      $.magnificPopup.open({
                        items: {
                          src: "#login-popup",
                        },
                        type: "inline",
                      });
                    }}
                  >
                    Login Using Membership Now
                  </a> */}

                  {/*  <a
                    href="#signup-popup"
                    data-effect="mfp-zoom-in"
                    title="Sign Up"
                    className="button regmem"
                    onClick={(e) => {
                      e.preventDefault();
                      $.magnificPopup.open({
                        items: {
                          src: "#signup-popup",
                        },
                        type: "inline",
                      });
                    }}
                  >
                    Register for Membership
                  </a> */}
                  <span>
                    <strong>Or</strong>
                  </span>

                  <a
                    href="#guest-popup"
                    data-effect="mfp-zoom-in"
                    title="Login"
                    className="button btnguest"
                    onClick={(e) => {
                      e.preventDefault();
                      $.magnificPopup.open({
                        items: {
                          src: "#guest-popup",
                        },
                        type: "inline",
                      });
                    }}
                  >
                    Checkout as guest
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div id="login-popup" className="mfp-hide popup_sec login-popup">
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3> Login </h3>
                <p>Update your informations and continue</p>
              </div>
              <div className="full-login-new-body">
                {/* <div className="popup-footer">
                  <FacebookLogin
                    appId={fbAppId}
                    fields="name,email,picture,first_name,last_name,birthday,gender"
                    callback={this.responseFacebook}
                    scope="public_profile,email,user_birthday"
                    cssclassName="btn btn-black  fa fa-facebook"
                    redirectUri={siteURL}
                    icon="fa-facebook"
                    textButton="&nbsp; Login with Facebook"
                  />

                  <div className="or-seperator">
                    <span>Or Sign in with</span>
                  </div>
                </div> */}
                <Login
                  fields={this.state.fields}
                  onChange={this.fieldChange}
                  onValid={this.handleSignin}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          </div>

          {/* Signup popup */}
          <div id="signup-popup" className="mfp-hide popup_sec signup-popup">
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3> Create an Account </h3>
                <p>Update your informations and continue</p>
              </div>
              <div className="full-login-new-body">
                <div className="popup-footer signup-popup-head">
                  <FacebookLogin
                    appId={fbAppId}
                    fields="name,email,picture,first_name,last_name,birthday,gender"
                    callback={this.responseFacebook}
                    scope="public_profile,email,user_birthday"
                    cssclassName="btn btn-black  fa fa-facebook"
                    redirectUri={siteURL}
                    icon="fa-facebook"
                    textButton="&nbsp; Login with Facebook"
                  />

                  <div className="or-seperator">
                    <span>Or Register With</span>
                  </div>
                </div>

                <Signup
                  fields={this.state.fieldssignup}
                  onChange={this.fieldChangeSignup}
                  onValid={this.handleSignup}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          </div>

          {/* Forgot Password Popup */}

          <div
            id="forgot-password-popup"
            className="mfp-hide popup_sec forgot-password-popup"
          >
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3>Forgot your PIN?</h3>
                <p>You can reset your PIN here.</p>
              </div>

              <div className="full-login-new-body">
                <Forgotpassword
                  fields={this.state.fieldsfgtpassword}
                  onChange={this.fieldforgot}
                  onValid={this.forgotpassword}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          </div>

          {/* Change Password */}
          <div
            id="change-password-popup"
            className="white-popup mfp-hide popup_sec changepw_popup"
          >
            <div className="pouup_in">
              <div id="form-msg"></div>
              <h3 className="title1 text-center">Change Password</h3>
              <Changepassword
                fields={this.state.fieldschpassword}
                onChange={this.fieldChangepassword}
                onValid={this.changepassword}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>

          {/* Guest Checkout */}
          <div
            id="guest-popup"
            className="white-popup mfp-hide popup_sec guest-popup"
          >
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3> Checking out as a guest? </h3>
                <p>Update your informations and continue</p>
              </div>
              <div className="full-login-new-body">
                <Guestcheckout
                  fields={this.state.fieldscheckout}
                  onChange={this.fieldChangecheckout}
                  onValid={this.handleCheckout}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          </div>

          {/* login Popup */}
          <div className="mbl-menu-overly"></div>
        </header>

        {/* Order popup - start */}
        <div
          id="order-popup"
          className="white-popup mfp-hide popup_sec  order_popup"
        >
          <div className="order-popup-header">
            <h3>Choose Ordering Type</h3>
          </div>
          <div className="full-login-new-body">
            <div className="order_delivery_row">
              <div className="order_delivery_col">
                <ul className="order_delivery_item">
                  <li>
                    <a
                      href={void 0}
                      onClick={this.setAvailabilityFun.bind(this, deliveryId)}
                      /* className={this.checkActiveDivHd(deliveryId)} */
                    >
                      <div className="header-ordernow-single-img">
                        <img className="normalstate" src={db} />
                        <img className="hoverstate" src={dbw} />
                      </div>
                      <p>Delivery</p>
                      <label>Coming Soon</label>
                    </a>
                  </li>
                  <li>
                    <a
                      href={void 0}
                      onClick={this.setAvailabilityFun.bind(this, pickupId)}
                      /* className={this.checkActiveDivHd(deliveryId)} */
                    >
                      <div className="header-ordernow-single-img">
                        <img className="normalstate" src={ty} />
                        <img className="hoverstate" src={tyw} />
                      </div>
                      <p>Takeaway</p>
                      <label>Coming Soon</label>
                    </a>
                  </li>
                  <li>
                    <a
                      href={void 0}
                      onClick={this.setAvailabilityFun.bind(this, dineinId)}
                      /* className={this.checkActiveDivHd(deliveryId)} */
                    >
                      <div className="header-ordernow-single-img">
                        <img className="normalstate" src={din} />
                        <img className="hoverstate" src={dinw} />
                      </div>
                      <p>Dine In</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          id="order-popup"
          className="white-popup mfp-hide popup_sec order_popup"
        >
          <div className="order-body">
            <div className="order-body-header">
              <h2>Choose Your Ordering Type</h2>
              <p>Please Choose </p>
            </div>
            <div className="order_delivery_row">
              <div className="order_delivery_col">
                <ul className="order_delivery_item">
                  <li>
                    <a
                      href="/"
                      onClick={this.chooseAvailabilityFun.bind(
                        this,
                        deliveryId
                      )}
                      className={this.checkActiveDivHd(deliveryId)}
                    >
                      <div className="header-ordernow-single-img">
                      
                      </div>
                      <h3>Delivery</h3>
                    </a>
                  </li>

                  <li>
                    <a
                      href="/"
                      onClick={this.chooseAvailabilityFun.bind(this, pickupId)}
                      className={this.checkActiveDivHd(pickupId)}
                    >
                      <div className="header-ordernow-single-img">
                     
                      </div>
                      <h3>Takeaway</h3>
                    </a>
                  </li>
                </ul>
                <div className="continue">
                  <a href="#" className="button">
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Order popup - end */}

        {/*  Delivery Postal code Popup - start */}
        <div
          id="delevery-postcode-popup"
          className="white-popup mfp-hide popup_sec delevery_popup"
        >
          <div className="full-login-new-header">
            <h3> Delivery </h3>
            <p>Let us know Your Delivery Location</p>
          </div>

          <div className="full-login-new-body">
            {/* <img
              className="pop-scooter-img"
              src={fbscootersvg}
              alt="Delivery"
            /> */}

            <div className="innervmid_in">
              {cookie.load("UserId") && (
                <div className="address-list-cls address-list-main">
                  {this.userAddressList(1)}
                </div>
              )}

              <div className="form-group">
                <div className="datetime_selt_lbl">Enter your postal code</div>
                <div
                  className={
                    this.state.secondaryaddresslist.length > 0
                      ? "focus-out focused"
                      : "focus-out"
                  }
                >
                  <input
                    type="text"
                    id="postalcode"
                    pattern="\d*"
                    maxLength="6"
                    placeholder="Postal Code"
                    className="form-control input-focus"
                  />
                  <div className="postal_error"></div>
                </div>
              </div>

              <div className="btn_sec">
                <div className="two-button-row">
                  <div className="go_second">
                    {/*<a href="javascript:;" onClick={this.closepopup.bind(this)} className="button button-left" title="Go Back">Go Back</a>*/}
                    <a
                      href="/"
                      /*  onClick={this.gobackOrderNow.bind(this)} */
                      className="button button-left"
                      title="Go Back"
                    >
                      Go Back
                    </a>
                  </div>
                  <div className="con_first delivery_submit_cls">
                    <input
                      type="button"
                      onClick={this.findOutletBasedZone.bind(
                        this,
                        1,
                        deliveryId
                      )}
                      className="button button-right delivery_submit"
                      value="Continue"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          id="delevery-postcode-popup"
          className="white-popup mfp-hide popup_sec delevery_popup small-popup"
        >
          <div className="order-body">
            {cookie.load("UserId") && (
              <div className="address-list-cls address-list-main">
                {this.userAddressList(1)}
              </div>
            )}

            <div className="form-group">
              <div
                className={
                  this.state.secondaryaddresslist.length > 0
                    ? "focus-out focused"
                    : "focus-out"
                }
              >
                <input
                  type="text"
                  id="postalcode"
                  pattern="\d*"
                  maxLength="6"
                  className="form-control input-focus"
                />
                <div className="postal_error"></div>
              </div>
            </div>
            <div className="small-address">
              <ul>
                <li>
                  <a href="#">Hi</a>
                </li>
                <li>
                  <a href="#">Hi</a>
                </li>
              </ul>
            </div>
            <div className="con_first delivery_submit_cls">
              <input
                type="button"
                onClick={this.findOutletBasedZone.bind(this, 1, deliveryId)}
                className="button delivery_submit"
                value="Continue"
              />
            </div>
          </div>
        </div> */}
        {/* Delevery Popup - end */}

        {/* Delevery Popup error - start */}
        <div
          id="error-postal-popup"
          className="white-popup mfp-hide popup_sec error_postal_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title poptt_icontop text-center">
                  <img src={iconUnhappy} />
                  <h2 className="text-uppercase">Sorry </h2>
                  <small>We cant find your postal code</small>
                </div>
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                {/*<h4>Enter your postal code</h4>*/}

                {cookie.load("UserId") && (
                  <div className="address-list-cls address-list-error">
                    {this.userAddressList(2)}
                  </div>
                )}

                <form className="form_sec">
                  <div className="form-group">
                    <div
                      className={
                        this.state.secondaryaddresslist.length > 0
                          ? "focus-out focused"
                          : "focus-out"
                      }
                    >
                      <label>Enter your postal code</label>
                      <input
                        type="text"
                        id="postalcode1"
                        pattern="\d*"
                        maxLength="6"
                        className="form-control input-focus"
                      />
                      <div className="postal_error"></div>
                    </div>
                  </div>
                  <div className="btn_sec delivery_submit_cls delivery_submit_div">
                    {/*<input type="button" onClick={this.selectOutlet.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />*/}
                    <input
                      type="button"
                      onClick={this.findOutletBasedZone.bind(
                        this,
                        0,
                        deliveryId
                      )}
                      className="button delivery_submit"
                      value="Continue"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Delevery Popup error - Start */}

        {/* success popup - Start */}
        <div
          id="awesome-popup"
          className="white-popup mfp-hide delevery_popup_datetime"
        >
          <div className="full-login-new-body">
            <img className="thumbsup" src={iconWin} alt="Awesome" />
            <h2 className="text-uppercase">Awesome</h2>

            {this.state.seletedAvilablityId === deliveryId ? (
              <div className="awesome_del">
                <h5>We can Deliver To </h5>
                <h2>{this.state.orderDeliveryAddress}</h2>
              </div>
            ) : (
              <div className="awesome_del">
                <h5>You Can Pickup From</h5>
                <h2>
                  {this.state.pickupInfo.orderOutletName}
                  <br />
                  {this.state.orderHandled}
                </h2>
              </div>
            )}

            <div className="datetime_selt_sec">
              <div className="datetime_selt_lbl">
                {this.state.seletedAvilablityId === deliveryId
                  ? "Choose Date & Time"
                  : "Choose Takeaway Date & Time"}
              </div>

              {!currenturl.includes(isCheckout) && (
                <OrderAdvancedDatetimeSlot
                  {...this.props}
                  hdrState={this.state}
                  setdateTimeFlg={this.setdateTimeFlg}
                  indutualText={false}
                />
              )}

              <div className="ordrdatetime_error"></div>
            </div>

            <div className="btn_sec">
              <div className="two-button-row">
                <div className="go_second">
                  <a
                    href="/"
                    className="button button-left"
                    title="Go Back"
                    /* onClick={this.goBackSlot.bind(this)} */
                  >
                    Back
                  </a>
                </div>
                <div className="delivery_submit_cls">
                  <input
                    type="button"
                    onClick={this.setOrderOutletDateTimeData.bind(this)}
                    className="button button-right disbl_href_action"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          id="awesome-popup"
          className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime small-popup"
        >
          <div className="order-body">
            <div className="datetime_selt_sec">
              <div className="datetime_hea_row">
                <div className="header-with-icon">
                  {this.state.showOrdDateOnly !== "yes" && (
                    <div
                      className="left-back-arrow"
                      onClick={this.gobackAvblPoup.bind(this)}
                    >
                      <img className="" src={leftArowIcon} />
                    </div>
                  )}
                  <h4>
                    {this.state.seletedAvilablityId === deliveryId
                      ? "Delivery date & time"
                      : "Pick-up date & time"}{" "}
                  </h4>
                </div>
              </div>

              <OrderAdvancedDatetimeSlot
                {...this.props}
                hdrState={this.state}
                setdateTimeFlg={this.setdateTimeFlg}
              />

              <div className="ordrdatetime_error"></div>
            </div>

            <input
              type="button"
              onClick={
                this.state.showOrdDateOnly === "yes"
                  ? this.setOrderDateTimeOnly.bind(this)
                  : this.setOrderDateTimeText.bind(this)
              }
              className="button"
              value="Continue"
            />
          </div>
        </div> */}
        {/* success popup - end */}

        {/* success popup - Start */}
        <div
          id="awesome-popup-old"
          className="white-popup mfp-hide awesome_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title poptt_icontop text-center">
                  <img src={iconWin} />
                  <h2 className="text-uppercase">Awesome</h2>
                  <small>We can Deliver !</small>
                </div>
                <div className="awesome_del">
                  <h5>Your Delivery Address :</h5>
                  <h2>{this.state.orderDeliveryAddress}</h2>
                </div>
                <div className="btn_sec">
                  <input
                    type="button"
                    onClick={this.gotoProducts.bind(this)}
                    className="button"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {/* error Popup - start */}
        <div
          id="outlet-error-popup"
          className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup"
        >
          <div className="custom_alert">
            <div className="edit-alert-body">
              <h4>
                Sorry, we don’t
                <br /> deliver here yet!
              </h4>
              <p>
                Thank you for your interest in Singapore Swimming Club. We hope
                to serve your area soon. In the meantime, you can try our self
                pick-up service!
              </p>
              <div className="alt_btns">
                {this.state.seletedAvilablityId === pickupId ? (
                  <a
                    href="/"
                    onClick={this.gobckPkupOutletpopup.bind(this)}
                    className="button button-right popup-modal-dismiss disbl_href_action"
                  >
                    Select new address/self pick-up
                  </a>
                ) : (
                  <a
                    href="/"
                    onClick={this.gobckOutletpopup.bind(this)}
                    className="button button-right popup-modal-dismiss disbl_href_action"
                  >
                    Select new address/self pick-up
                  </a>
                )}
                <div className="login-links">
                  <Link to={"/delivery-locations"}>
                    View delivery locations
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* error Popup - end */}

        {/* Takeaway Popup - Start */}
        <div
          id="takeaway-popup"
          className="white-popup mfp-hide popup_sec self_popup"
        >
          <div className="full-login-new-header">
            <h3> Takeaway </h3>
            <p>Let us Know your Takeaway Outlet</p>
          </div>
          <div className="full-login-new-body">
            <img src={tyw} className="takeaway-bag" alt="Takeaway" />

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus search_outlet"
                          onKeyUp={this.handleKeyPress}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <ul className="self_outlet_inner">
                      {this.loadSiteLocationList()}
                    </ul>
                    {this.state.error_pickup_outlet === 1 && (
                      <>
                        <br />
                        <div className="postal_error">
                          <span className="error">
                            Please select any one outlet
                          </span>
                        </div>
                      </>
                    )}
                    <div className="takelist-btn-part common-button-row">
                      <a
                        className="button btn_empty"
                        href="/"
                        /*  onClick={this.gobackOrderNow.bind(this)} */
                      >
                        Back
                      </a>
                      <a
                        className="button btn_fill"
                        href="/"
                        /*  onClick={this.gobackOrderNow.bind(this)} */
                      >
                        Continue
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Takeaway Popup - end */}

        {/*  Dinein Popup - start */}
        <div
          id="dinein-popup"
          className="white-popup mfp-hide popup_sec self_popup"
        >
          <div className="full-login-new-header">
            <h3> dine in </h3>
            <p>Let us know your dine in table number</p>
          </div>
          <div className="full-login-new-body">
            {/* <img src={dineImg} alt="DineIn" className="dinein-plate" /> */}
            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item dinein_popup_item">
                  {this.state.dine_in_click !== "Yes" && (
                    <button
                      className="scan-qr"
                      typ="button"
                      onClick={this.startQRScan.bind(this)}
                    >
                      <img src={qrImg} alt="QR Code" />
                      <span>Scan Your QR Code</span>
                    </button>
                  )}

                  {/* {(this.state.dine_in_click === "Yes" && <>
                      <div className="self_popup_hea_row" id="qr_code">
                        <QrReader
                        delay={300}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        style={{ width: '312px' }}
                        />
                      </div>
                      <div>
                        <button typ="button" onClick={ this.handleError }>Cancel</button>
                      </div>
                    </>)} */}

                  {this.state.dine_in_click !== "Yes" && (
                    <div className="innervmid_in">
                      <div className="form-group">
                        <input
                          type="text"
                          id="table_number"
                          onChange={this.HandleTableNumberchng}
                          placeholder="Enter Your Table Number"
                          className="form-control input-focus"
                        />
                      </div>
                    </div>
                  )}

                  {this.state.dine_in_click !== "Yes" && (
                    <div className="takelist-btn-part btn_sec">
                      <a
                        className="button btn_empty"
                        href={void 0}
                        /*  onClick={this.gobackOrderNow.bind(this)} */
                      >
                        Back
                      </a>
                      &nbsp; &nbsp;
                      <button
                        type="button"
                        className="button takeaway-btn-act"
                        onClick={this.findTable.bind(this)}
                      >
                        Continue
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Dinein Popup - end */}

        {/*  Coming Soon Popup - start */}
        <div
          id="comingsoon-popup"
          className="white-popup mfp-hide popup_sec comingsoon_popup"
        >
          <div className="order-body">
            <div className="comingsoon_popup_hea_row">
              <div className="comingsoon_popup_hea_col">
                <h2>COMING SOON.</h2>
              </div>
            </div>
            <div className="comingsoon_pop_row">
              <p> can you select another availability.</p>
              <a
                href="/"
                onClick={this.closepopup.bind(this)}
                className="button disbl_href_action"
                title="Go Back"
              >
                Go Back
              </a>
            </div>
          </div>
        </div>
        {/* Coming Soon Popup - end */}

        {/* Warning Popup - start */}
        <div
          id="warning-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">Warning</div>
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  <input type="hidden" id="changeaddressheader" />
                  <p>By switching you are about to clear your cart.</p>
                  <p>Do you wish to proceed ?</p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      No
                    </a>
                    <input type="hidden" value="" id="change_shop_type" />
                    <a
                      href="/"
                      onClick={this.changeAvailability.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      Yes
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Popup - end */}

        {/* Warning Maximum Order Popup - start */}
        <div
          id="warning-max-orderpopup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="edit-alert-body">
              <h4>
                You can only order from up to{" "}
                {this.state.globalsettings !== "" &&
                  this.state.globalsettings.max_order_handle}{" "}
                outlets in a single order
              </h4>
              <p>
                To add this item to your cart, please remove all items from one
                or more outlets in your cart.
              </p>
              <div className="button-full no-link-after">
                <a
                  href="/"
                  className="popup-modal-dismiss button ghost-button disbl_href_action"
                >
                  View your cart
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Maximum Order Popup - end */}

        {/* Warning Maximum Order Popup - start */}
        <div
          id="warning-not-deliver"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_body">
                  <h3>Sorry!</h3>
                  <p>We are only able to handle orders from this restaurant</p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      CANCEL
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Maximum Order Popup - end */}

        <div
          id="warning-min-order"
          className="white-popup mfp-hide popup_sec warning_popup warning_min_order"
        >
          <div className="custom_alert">
            <div className="edit-alert-body">
              <h4>Minimum order not met!</h4>
              <p id="minimum_amt_txt">&nbsp</p>
              <div className="button-full">
                <a
                  href="/"
                  className="popup-modal-dismiss button disbl_href_action"
                >
                  Order more food!
                </a>
              </div>
              <a href={void 0} className="open_cart_deatils">
                Return to cart
              </a>
            </div>
          </div>
        </div>

        {this.state.orderPostalCode !== "" &&
          (this.props.match.path === "/" ||
            this.props.match.path === "/restaurants") && (
            <div className="mobile-location">
              <div className="container">
                <div className="header-address">
                  <div
                    className="header-info"
                    onClick={this.gobackAvblPoup.bind(this)}
                  >
                    <img src={mappin} alt="Map" className="map-loc" />
                    <a href={void 0}>
                      {this.state.defaultAvilTy === deliveryId ? (
                        <>Deliver to S{this.state.orderPostalCode}</>
                      ) : (
                        <>Pick-up from S{this.state.orderPostalCode}</>
                      )}{" "}
                      <img src={more} alt="Arrow" />
                    </a>
                    <span>{this.showOrdTimeSlot()}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }

  var alloutletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      alloutletsArr = state.alloutlets[0].result_set;
    }
  }

  var secondarydataArr = Array();
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondarydataArr = state.secondaryaddress[0].result_set;
    }
  }
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  var tableAvailability = Array();
  if (Object.keys(state.tableavailability).length > 0) {
    // if (state.tableavailability[0].status === "ok") {
    tableAvailability = state.tableavailability[0];
    // }
  }

  return {
    globalsettings: state.settings,
    zonedetails: zonedetailArr,
    outletslist: outletsArr,
    alloutletslist: alloutletsArr,
    menudata: state.menudata,
    secondaryaddresslist: secondarydataArr,
    staticblack: blacksArr,
    logindata: state.login,
    fblogin: state.fblogin,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
    changepassword: state.changepassword,
    tableAvailability: tableAvailability,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId, shop_type) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId, shop_type });
    },
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getMenuData: (menuslug) => {
      dispatch({ type: GET_MENUDATA, menuslug });
    },
    getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getFbLoginData: (formPayload) => {
      dispatch({ type: GET_FBLOGINDATA, formPayload });
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
    getTableAvailability: (formPayload) => {
      dispatch({ type: GET_TABLEAVAILABILITY, formPayload });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Header));
