export const appId = "F9908894-8FA1-4372-8E83-FD87D4A2D9F3";
/* Live */
export const apiUrl = "https://neptune.ninjaos.com/api/";
export const apiUrlV2 = "https://neptune.ninjaos.com/apiv2/";
export const apiUrlNotify = "https://neptune.ninjaos.com/Pushorder/";
export const timThumpUrl = "https://neptune.ninjaos.com/timthumb.php?src=";
export const blogImageUrl = "https://neptune.ninjaos.com/media/dev_team/blog/";
export const proFolderUrl =
  "https://neptune.ninjaos.com/media/dev_team/products/";
export const mediaUrl = "https://neptune.ninjaos.com/media/dev_team/";
//export const siteURL = "https://sswimclub.promobuddy.asia/";
//export const siteURL = "https://www.sswimclub.org.sg/orders/";
export const siteURL = "https://orders.sswimclub.org.sg/";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const dineinId = "EF9FB350-4FD4-4894-9381-3E859AB74019";
export const stripeImage = "/img/stripe_logo.png";
export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Singapore Swimming Club";
export const stripeReference = "singaporeswimmingclub";
export const projectTitle = "Singapore Swimming Club";
export const stripeDesc = "My Checkout";
export const fbAppId = "824077091820152";
export const restaurantLink = "food";
export const shopType = "1";
export const stripeCurrency = "SGD";
export const cookieConfig = {
  path: "/",
  secure: true,
  httpOnly: false,
};
export const headerconfig = {
  headers: {
    "x-api-key": "UY64GJICOKTIEDYZP04NXO4J6F1HH8",
  },
};
