/* eslint-disable */
import React, { Component } from "react"
import { Link } from "react-router-dom"
import { restaurantLink, proFolderUrl } from "../Helpers/Config"
import { stripslashes, capitalize_Words, showPriceValue } from "../Helpers/SettingHelper"
import zoomimg from "../../common/images/search-purple.svg"
import OwlCarousel from "react-owl-carousel2"
import "../../common/css/owl.carousel.css"
import noimage from "../../common/images/no-img-product.png"
import searchicon from "../../common/images/search-purple.svg"
var settingsmenu = {
  items: 4,
  dots: false,
  autoWidth: true,
  autoplay: false,
  nav: false,
  margin: 20,
  responsive: {
    0: {
      margin: 0,
    },
    580: {
      margin: 5,
    },
  },
  /*  loop: true, */
}
var Parser = require("html-react-parser")
let timeout = null

class MenuNavigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navigateMenu: [],
      outlet_slug: this.props.match.params.slugRestaurant,
      loadMobileMenu: false,
      displaySearchProduct: "",
      noOfItemShow: 9,
      showMore: false,
    }
  }

  handleResize() {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      let navigateMenuArr = this.props.navigateMenu || []

      let totalWidth = 0
      let noOfItem = 0
      navigateMenuArr.forEach((nav, i) => {
        totalWidth += nav.menu_custom_title.length * 10 + 10
        if (totalWidth > Math.min(window.innerWidth, 1280) && !noOfItem) {
          noOfItem = i
        }
      })
      this.setState({ noOfItemShow: noOfItem })
    }, 500)
  }

  handleScroll() {
    var scrollPos = $(document).scrollTop()
    $(".product-mobile-menu .menu-list-intvl a")
      .each(function () {
        var currLink = $(this)
        var refElement = $(currLink.attr("href").replace(/\//g, ""))
        if (refElement.position() !== "" && typeof refElement.position() !== undefined && typeof refElement.position() !== "undefined") {
          var toppos = refElement.position().top - 200
          if (toppos <= scrollPos && toppos + refElement.height() > scrollPos) {
            $(".product-mobile-menu-nav .menu-list-intvl").removeClass("active")
            currLink.parents(".menu-list-intvl").addClass("active")
            $("#mobile-menu").trigger("to.owl.carousel", [currLink.parents(".owl-item").index(), 500])
          } else {
            currLink.parents(".menu-list-intvl").removeClass("active")
          }
        }
      })
      .promise()
      .done(function () {
        if ($(".product-mobile-menu-nav .menu-list-intvl.active").length > 0) {
          $(".product-mobile-menu-nav .mobile-menu-title").html($(".product-mobile-menu-nav .menu-list-intvl.active .menu-title-txt").html())
        }
      })
  }

  handleClickOutsideViewMore(e) {
    const closestViewMore = $(e.target).closest(".view-more")
    if (!closestViewMore.length) {
      this.setState({ showMore: false })
    }
  }

  componentDidMount() {
    $(window).on("resize", this.handleResize.bind(this))
    $(document).on("click", this.handleClickOutsideViewMore.bind(this))
    this.handleResize()
  }

  componentWillUnmount() {
    $(window).off("resize", this.handleResize.bind(this))
    $(document).off("click", this.handleClickOutsideViewMore.bind(this))
  }

  goCategoryProducts(slug, type, cat_id, event) {
    event.preventDefault()
    this.setState({ showMore: false })
    $(".product-mobile-menu .menu-list-intvl").removeClass("active")
    $(".product-mobile-menu #" + type + "_" + cat_id).addClass("active")
    $("html, body").animate(
      {
        scrollTop: $("#" + slug).offset().top - 180,
      },
      1000
    )
  }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu
    var selectedNav = this.props.productState.selectedNavigation

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type == "main") {
          return (
            <li key={index + 1} className="menu-list-intvl" id={"c_" + loaddata.pro_cate_primary_id}>
              <Link to={"/#" + loaddata.pro_cate_slug} onClick={this.goCategoryProducts.bind(this, loaddata.pro_cate_slug, "c", loaddata.pro_cate_primary_id)} className="menu-title-link" title={loaddata.menu_custom_title}>
                {/*  <span> </span> */}
                {capitalize_Words(stripslashes(loaddata.menu_custom_title.toLowerCase()))}
              </Link>
            </li>
          )
        } else if (loaddata.menu_type == "sub") {
          return (
            <li key={index + 1} id={"s_" + loaddata.pro_subcate_id} className={selectedNav === loaddata.pro_subcate_slug ? "menu-list-intvl active" : "menu-list-intvl"}>
              <Link to="#" className="menu-title-link" onClick={this.goCategoryProducts.bind(this, loaddata.pro_subcate_slug, "c", loaddata.pro_subcate_id)} title={loaddata.menu_custom_title}>
                <span className="menu-title-txt">{stripslashes(loaddata.menu_custom_title.toLowerCase())}</span>
              </Link>
            </li>
          )
        }
      })
      setTimeout(function () {
        $(window).scroll(function () {
          var scrollPos = $(document).scrollTop()
          $(".product-menu li a")
            .each(function () {
              var currLink = $(this)
              var refElement = $(currLink.attr("href").replace(/\//g, ""))

              if (refElement.position() !== "" && typeof refElement.position() !== undefined && typeof refElement.position() !== "undefined") {
                var toppos = refElement.position().top - 200

                if (toppos <= scrollPos && toppos + refElement.height() > scrollPos) {
                  $(".product-menu ul li").removeClass("active")

                  currLink.parents("li").addClass("active")
                } else {
                  currLink.parents("li").removeClass("active")
                }
              }
            })
            .promise()
            .done(function () {
              setTimeout(function () {
                if ($(".menu-section-inner-scroll").length > 0) {
                  $(".menu-section-inner-scroll").mCustomScrollbar()
                }
              }, 100)

              /* if ($(".menu-list-intvl.active").length === 0) {
                $(".product-menu li:first-child").addClass("active");
              } */
            })
        })
      }, 100)

      return mainMenu
    } else {
      return
    }
  }

  mobileNavigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainNavs = navigateMenuArr.filter((n) => n.menu_type == "main")
      let moreMenu = null
      const mainMenu = mainNavs
        .filter((d, i) => i < this.state.noOfItemShow)
        .map((loaddata, index) => {
          return (
            <div key={index + 1} className="menu-list-intvl" id={"c_" + loaddata.pro_cate_primary_id}>
              <Link to={"/#" + loaddata.pro_cate_slug} onClick={this.goCategoryProducts.bind(this, loaddata.pro_cate_slug, "c", loaddata.pro_cate_primary_id)} className="menu-title-link" title={loaddata.menu_custom_title}>
                <span className="menu-title-txt">{capitalize_Words(stripslashes(loaddata.menu_custom_title.toLowerCase()))}</span>
              </Link>
            </div>
          )
        })
      setTimeout(() => {
        $(window).scroll(this.handleScroll)
      }, 100)
      /* this.setState({ loadMobileMenu: true }); */
      if (mainNavs.length > this.state.noOfItemShow) {
        moreMenu = (
          <div className="view-more">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                this.setState({ showMore: !this.state.showMore }, () => {
                  this.handleScroll()
                })
              }}
            >
              View More
              <i className="fa fa-angle-down ars2" style={{ fontSize: 20 }}></i>
            </a>
            {this.state.showMore && (
              <ul className="view-more__list">
                {mainNavs
                  .filter((n, i) => i > this.state.noOfItemShow)
                  .map((loaddata, index) => (
                    <li key={index + 1} className="menu-list-intvl" id={"c_" + loaddata.pro_cate_primary_id}>
                      <Link to={"/#" + loaddata.pro_cate_slug} onClick={this.goCategoryProducts.bind(this, loaddata.pro_cate_slug, "c", loaddata.pro_cate_primary_id)} className="menu-title-link" title={loaddata.menu_custom_title}>
                        <span className="menu-title-txt">{capitalize_Words(stripslashes(loaddata.menu_custom_title.toLowerCase()))}</span>
                      </Link>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        )
      }
      if (navigateMenuArr)
        return (
          <>
            {mainMenu}
            {moreMenu}
          </>
        )
    } else {
      return
    }
  }

  mobileNavigateMenuList2() {
    let navigateMenuArr = this.props.navigateMenu

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainNavs = navigateMenuArr.filter((n) => n.menu_type == "main")
      let moreMenu = null
      const mainMenu = mainNavs
        .map((loaddata, index) => {
          return (
            <div key={index + 1} className="menu-list-intvl" id={"c_" + loaddata.pro_cate_primary_id}>
              <Link to={"/#" + loaddata.pro_cate_slug} onClick={this.goCategoryProducts.bind(this, loaddata.pro_cate_slug, "c", loaddata.pro_cate_primary_id)} className="menu-title-link" title={loaddata.menu_custom_title}>
                <span className="menu-title-txt">{capitalize_Words(stripslashes(loaddata.menu_custom_title.toLowerCase()))}</span>
              </Link>
            </div>
          )
        })
      setTimeout(() => {
        $(window).scroll(this.handleScroll)
      }, 100)
      /* this.setState({ loadMobileMenu: true }); */
      if (mainNavs.length > this.state.noOfItemShow) {
        moreMenu = (
          <div className={`view-more ${this.state.showMore ? "active" : ""}`}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                this.setState({ showMore: !this.state.showMore }, () => {
                  this.handleScroll()
                })
              }}
            >
              <div class="menu_icon"><span class="icon-bar icon-bar1"></span><span class="icon-bar icon-bar2"></span><span class="icon-bar icon-bar3"></span></div>
            </a>
            {this.state.showMore && (
              <ul className="view-more__list">
                {mainNavs
                  .map((loaddata, index) => (
                    <li key={index + 1} className="menu-list-intvl" id={"c_" + loaddata.pro_cate_primary_id}>
                      <Link to={"/#" + loaddata.pro_cate_slug} onClick={this.goCategoryProducts.bind(this, loaddata.pro_cate_slug, "c", loaddata.pro_cate_primary_id)} className="menu-title-link" title={loaddata.menu_custom_title}>
                        <span className="menu-title-txt">{capitalize_Words(stripslashes(loaddata.menu_custom_title.toLowerCase()))}</span>
                      </Link>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        )
      }
      if (navigateMenuArr)
        return (
          <>
            <OwlCarousel id="mobile-menu" options={settingsmenu}>
              {mainMenu}
            </OwlCarousel>
            {moreMenu}
          </>
        )
    } else {
      return
    }
  }

  searchOutlet() {
    $.magnificPopup.open({
      items: {
        src: "#search-outlet-popup",
      },
      type: "inline",
    })
  }
  searchProKeyPress = (event) => {
    var productList = this.props.productState.searchProResult
    var value = event.target.value.toLowerCase()
    let matches = ""
    if (value !== "") {
      matches = productList.filter(function (item) {
        if (item.label.toLowerCase().indexOf(value) >= 0) {
          return item.label
        }
      })
    }
    this.setState({ selectedProResult: matches }, function () {
      this.display_Search_Product()
    })
  }
  display_Search_Product() {
    var displayproducts = ""
    if (this.state.selectedProResult.length > 0) {
      var mediaUrl = proFolderUrl + "main-image/"
      displayproducts = this.state.selectedProResult.map((item, index) => {
        var description = item.description !== null && item.description !== "" ? Parser(stripslashes(item.description)) : ""
        return (
          <li className={this.chkProStockCls(item.value, item.product_stock)} key={index} onClick={this.openProductDetails.bind(this, item)}>
            <div className="products-image-div">
              {item.product_thumbnail !== "" ? (
                <img
                  src={mediaUrl + item.product_thumbnail}
                  title={item.product_thumbnail_title !== "" && item.product_thumbnail_title !== null ? item.product_thumbnail_title : item.label}
                  alt={item.product_thumbnail_alt !== "" && item.product_thumbnail_alt !== null ? item.product_thumbnail_alt : item.label}
                />
              ) : (
                <img src={noimage} title={item.label} alt={item.label} />
              )}
            </div>

            <div className="product-info-div">
              <div className="product-title-maindiv">
                <div className="product-title">
                  <h3>{item.label}</h3>
                </div>
              </div>
              {description !== "" && (
                <div className="product-short-description">
                  <p>{description !== "" ? description : Parser("&nbsp;")}</p>
                </div>
              )}
              <div className="product-price">
                <h3>{showPriceValue(item.product_price)}</h3>
                {parseFloat(item.product_cost) > 0 && <h4 className="product-originale-price">{showPriceValue(item.product_cost)}</h4>}
              </div>
            </div>
          </li>
        )
      })
    }
    this.setState({ displaySearchProduct: displayproducts })
  }

  chkProStockCls(proSlug, Stock) {
    var returnText = "products-single-li no-stock-product " + proSlug
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug
    }

    return returnText
  }
  openProductDetails(item) {
    this.props.history.push("/" + restaurantLink + "/" + this.props.match.params.slugRestaurant + "/" + item.cate_slug + "/" + item.subcate_slug + "/" + item.value)
    $.magnificPopup.close()
  }

  render() {
    return (
      <div className="menu-top-section-inner">
        <div className="product-mobile-menu-nav">
          {/*  <div className="flexway">
            {" "}
            <span className="mobile-menu-title"></span>{" "}
            <strong className="mobile-menu-more">
              More <i className="fa fa-angle-down"></i>
            </strong>{" "}
          </div> */}
          {/* {this.props.displaysec === "mobile" && ( */}
          <>
            {/* <div className="mobile-menu-nav-search">
              <a href={void 0} onClick={this.searchOutlet.bind(this)}>
                <img src={searchicon} alt="Search" />
              </a>
            </div> */}

            <div className="product-mobile-menu">
              {/*  <span className="mobile-menu-title"></span> */}
              {/* <OwlCarousel options={settingsmenu}>{this.mobileNavigateMenuList()}</OwlCarousel> */}
              {this.props.mobile ? (
                <div className="mobile-nav">
                  {this.mobileNavigateMenuList2()}
                </div>
              ) : (
                <div className="desktop-nav"> 
                  {this.mobileNavigateMenuList()}
                </div>
              )}
            </div>
          </>
          {/* )} */}
        </div>
        {/* Warning Maximum Order Popup - start */}
        {/* <div id="search-outlet-popup" className="white-popup mfp-hide popup_sec warning_popup search-outlet-popup">
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="mobile-search-body">
                <div className="search-product search-product-listing">
                  <div className="spm-lhs">
                    <input type="text" className="search-rest" placeholder="Search resturant" onKeyUp={this.searchProKeyPress} />
                    <img className="search-glass" src={zoomimg} alt="" />

                    <ul className="search-result-mobile">{this.state.displaySearchProduct}</ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Warning Maximum Order Popup - end */}
      </div>
    )
  }
}

export default MenuNavigation
